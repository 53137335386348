const getMarketplace = `
	query {
		getMarketplace {
			status
			message
			data {
				id
				id_s2_group
				id_s2_users
				id_s2_countries
				response {
					type
					title
					position
					cat_id
					list {
						bot_banner
						thumb
						bot_image
						bot_short_description
						bot_id
						bot_name
						botMatrixId
						description
						is_subscribe
						bot_banner
						bot_banner_thumb
						card_style {
							bg {
								color
							}
							btn_f {
								color
							}
							border {
								color
							}
						}
					}
				}
			}
		}
	}
`;

const getEvents = `
	query {
		getEvents {
			status
			message
			data {
				response {
					date
					events {
						id
						img
						date
						name
						est_eps
						est_rev
					}
				}
			}
		}
	}
`;

const getMarketDetails = `
	query {
		getMarketDetails {
			message
			status
			data {
				response {
					post {
						comments
						date
						desc
						full_name
						id
						img
						is_down_vote
						is_down_vote_given
						is_up_vote
						down_vote
						up_vote
						is_up_vote_given
						is_bookmark
						user_name
						description
						title
						is_user_up_vote
						is_user_down_vote
						user_img
						u_img
						user_image
						user_id
						post_type
						shared_url
						attachment_data{
							file_url
							id
							id_s2_attachment_types
							thumb_url
						}
						post_poll_data{
							expiration_time
							given_answer
							id
							is_answer_given
							options{
								id
								option
								result
							}
							pollType
							poll_status
							question
							total_user_votes
						}
						mention_user_list{
							id
							user_name
						}
						mention_stock_list{
							id
							stock_name
							ticker_id
							ticker_image
							ticker_name
							ticker_symbol
						}
						news_data{
							news_content
							news_id
							news_image
							news_publish_date
							news_title
							news_url
							thumb
							ticker_details{
								id
								stock_name
								ticker_id
								ticker_image
								ticker_name
								ticker_symbol
							}
							ticker_symbol
						}
					}
					indices {
						id
						i_img
						i_name
						i_symbol
						country
						i_value
						company_name
						chart_data
						i_prev_close_value
					}
					top_loser {
						info
						list {
							id
							is_watched
							chart_data
							ticker_id
							ticker_img
							ticker_name
							ticker_prev_close_price
							ticker_price
							ticker_symbol
						}
					}
					stock_news {
						date
						id
						title
						source
						img
					}
					top_gainer {
						info
						list {
							id
							price
							is_watched
							chart_data
							ticker_id
							ticker_img
							ticker_name
							ticker_prev_close_price
							ticker_price
							ticker_symbol
						}
					}
					market_movers {
						info
						list {
							id
							ticker_id
							is_watched
							chart_data
							ticker_id
							ticker_img
							ticker_name
							ticker_prev_close_price
							ticker_price
							ticker_symbol
						}
					}
				}
			}
		}
	}
`;

const getIndicesData = `query getIndicesData($pageNumber: Int,$pageSize: Int,$search: String){
	getIndicesData(pageNumber:$pageNumber,pageSize:$pageSize,search: $search){
		data {
			chart_data
			current_value
			id
			prev_close
			s2_countries{
				name
			}
			s2_indices_s2_indicesTos2_pc_indices_id_s2_indices{
				image
				name
				symbol
			}
		}
		message
		status
	  
	}
}`;

const getdashboardLayoutType = `query {
	getdashboardLayoutType{
	  data{
		id
  layout
	  }
  message
  status
	}
  }`;

const addBotStoreCategoryType = `mutation addBotStoreCategoryType($category:String) {
	addBotStoreCategoryType(category:$category){
  message
  status
	}
  }`;

const deleteBotStoreCategoryType = `mutation deleteBotStoreCategoryType($id:Int){
	deleteBotStoreCategoryType(id:$id){
	  message
	  status
	}
  }`;

const updateBotStoreCategoryType = `mutation updateBotStoreCategoryType($category:String , $id:Int) {
	updateBotStoreCategoryType(category:$category, id:$id){
	  message
	  status
	}
  }`;

const getMarkeplaceCategory = `query getMarkeplaceCategory($pageSize: Int, $pageNumber: Int, $searchText: String, $activeInactiveUser: Boolean,$sortBy:String,$sortOrder:OrderDirection){
	getMarkeplaceCategory(pageSize: $pageSize, pageNumber: $pageNumber, searchText: $searchText, activeInactiveUser: $activeInactiveUser,sortBy:$sortBy,sortOrder:$sortOrder){
		status
		message
		data {
			category
			active
			id
			cts
		}
		totalCount
	}
}`;

const deleteMarkeplaceCategory = `query deleteMarkeplaceCategory($categoryId: Int!){
	deleteMarkeplaceCategory(categoryId: $categoryId){
		message
    	status
	}
}`;

const updateMarkeplaceCategory = `query updateMarkeplaceCategory($categoryId: Int!,$categoryName: String!, $status: Boolean!){
	updateMarkeplaceCategory(categoryId: $categoryId, categoryName: $categoryName, status: $status){
		message
    	status
	}
}`;

const addMarketplaceCategory = `mutation addMarketplaceCategory($categoryName: String!){
	addMarketplaceCategory(categoryName: $categoryName){
		data {
			id
			active
			category
			cts
			uts
		}
		message
		status
	  
	}
}`;

const botListByCategoryId = `
	query ($botCategoryId: Int, $pageSize: Int, $pageNumber: Int) {
		botListByCategoryId(botCategoryId: $botCategoryId, pageSize: $pageSize, pageNumber: $pageNumber) {
			status
			message
			data{
			id
			image
			botMatrixId
			thumb
			name
			description
			short_description
			is_subscribed
			card_style{
				bg{
				color
				}
				btn_f{
				color
				}
				b_name{
				color
				}
				btn_bg{
				color
				}
				b_short_desc{
				color
				}
			}
			}

		}
	}
`;

const getTopMoversList = `query getTopMoversList($pageNumber: Int, $pageSize: Int) {
	getTopMoversList(pageNumber: $pageNumber, pageSize: $pageSize) {
	  status
	  message
	  data {
	    cts
	    response {
		   is_watched
		   id
		   ticker_id
		   ticker_img
		   ticker_name
		   ticker_prev_close_price
		   ticker_price
		   ticker_symbol
		 }
	    }
	  }
   }`;

const getTrendingStockAndTopGainerAndLoser = `query getTrendingStockAndTopGainerAndLoser($pageSize: Int, $pageNumber: Int, $type: String!){
	getTrendingStockAndTopGainerAndLoser(pageSize: $pageSize, pageNumber: $pageNumber, type: $type){
		data {
			response{
				attachment_data{
					file_url
					id
					id_s2_attachment_types
					thumb_url
				}
				c_price
				chart_data
				comments
				company_name
				content
				country
				cts
				date
				desc
				description
				down_vote
				event_date
				event_img
				event_name
				exchange_symbol
				f_name
				full_name
				i_img
				i_name
				i_prev_close_value
				i_symbol
				img
				is_bookmark
				is_down_vote
				is_down_vote_given
				is_follow
				is_up_vote
				is_up_vote_given
				is_user_down_vote
				is_user_up_vote
				is_vote_down
				is_watched
				n_desc
				n_img
				name
				i_value
				ticker_id
				ticker_img
				ticker_name
				ticker_symbol
				ticker_price
				ticker_prev_close_price
				id
				exchange_symbol
				i_name
				
			}
		}
		message
		status
	  
	}
}`;

const marketData = {
	getMarketplace,
	getEvents,
	getMarketDetails,
	getdashboardLayoutType,
	addBotStoreCategoryType,
	deleteBotStoreCategoryType,
	updateBotStoreCategoryType,
	getMarkeplaceCategory,
	deleteMarkeplaceCategory,
	updateMarkeplaceCategory,
	botListByCategoryId,
	getTopMoversList,
	addMarketplaceCategory,
	getIndicesData,
	getTrendingStockAndTopGainerAndLoser
};

export default marketData;
