import { lazy } from 'react';

const LandingConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/',
			exact: true,
			component: lazy(() => import('./Landing'))
		},
		{
			path: '/developers/docs',
			component: lazy(() => import('./Documentaion'))
		}
	]
};

export default LandingConfig;
