import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import userService from 'app/services/userService';
import withReducer from 'app/store/withReducer';
import Avatar from 'react-avatar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import FuseLoading from '@fuse/core/FuseLoading';
import { motion } from 'framer-motion';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Query from 'app/main/query/user';
import history from '@history';
import Icon from '@material-ui/core/Icon';
import _ from '@lodash';
import moment from 'moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from 'react-hook-form';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {
	resetcommon,
	closeCommentDialog,
	getPostCommentByPostId,
	openCommentDialog,
	getPostDataByPostId,
	addPostVote,
	addUserBookmarkPost,
	openLikesListDialog,
	addPostCommentAndSubComment,
	deletePostCommentAndSubComment,
	editPostCommentAndSubComment
} from './store/commonSlice';
import LikesListDialog from './LikesListDialog';

import reducer from './store';

function CommentDialog(props) {
	const dispatch = useDispatch();

	const getPostDetail = useSelector(({ commonReducer }) =>
		commonReducer?.common?.getPostDataByPostIdData?.data?.getPostDataByPostId?.data?.length > 0
			? commonReducer.common.getPostDataByPostIdData.data.getPostDataByPostId.data
			: []
	);

	const getComment = useSelector(({ commonReducer }) =>
		commonReducer?.common?.getPostCommentByPostIdData?.data?.getPostCommentByPostId?.data?.length > 0
			? commonReducer.common.getPostCommentByPostIdData.data.getPostCommentByPostId.data
			: []
	);

	const likesListDialog = useSelector(({ commonReducer }) => commonReducer?.common?.likesListDialog);

	const appDialog = useSelector(({ commonReducer }) => commonReducer?.common?.commentDialog);

	const bookmarkStatus = useSelector(({ commonReducer }) => {
		return commonReducer?.common?.addUserBookmarkPostData?.data?.addUserBookmarkPost
			? commonReducer.common.addUserBookmarkPostData.data.addUserBookmarkPost
			: {};
	});

	const deleteCommentStatus = useSelector(({ commonReducer }) => {
		return commonReducer?.common?.deletePostCommentAndSubCommentData?.data?.deletePostCommentAndSubComment
			? commonReducer.common.deletePostCommentAndSubCommentData.data.deletePostCommentAndSubComment
			: {};
	});

	const listInnerRef = useRef();
	const loginUserData = userService.getUserData();
	const [loading, setLoading] = useState(true);
	const [isFetching, setIsFetching] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openCommentActionDialog, setOpenCommentActionDialog] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	const [commentsLists, setCommentsLists] = useState([]);
	const [posts, setPosts] = useState(getPostDetail);
	const [postId, setPostId] = useState();
	const [commentId, setCommentId] = useState();
	const [postCommentValue, setPostCommentValue] = useState('');
	const [postEditCommentValue, setPostEditCommentValue] = useState('');
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		let unmounted = false;

		const payload = {
			query: Query.getPostDataByPostId,
			variables: { postId: props.id }
		};
		dispatch(getPostDataByPostId(payload));

		if (!unmounted) {
			setTimeout(() => {
				setLoading(false);
			});
		}
		return () => {
			unmounted = true;
			dispatch(resetcommon());
		};
	}, [dispatch, props.id]);

	useEffect(() => {
		let unmounted = false;

		const payload = {
			query: Query.getPostCommentByPostId,
			variables: { pageNumber: 1, pageSize: 10, postId: props.id }
		};
		dispatch(getPostCommentByPostId(payload));

		setIsFetching(false);

		if (!unmounted) {
			setTimeout(() => {
				setLoading(false);
			});
		}
		return () => {
			unmounted = true;
			dispatch(resetcommon());
		};
	}, [dispatch, props.id]);

	useEffect(() => {
		if (getComment && getComment.length) {
			if (currPage + 1) {
				if (currPage === 1) {
					setCommentsLists([...getComment]);
				} else if (getComment && getComment.length) {
					setCommentsLists([...commentsLists, ...getComment]);
					setIsFetching(false);
				} else {
					setIsFetching(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getComment]);

	const onScroll = () => {
		setIsFetching(true);
		if (listInnerRef.current && getComment.length > 0) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				setCurrPage(currPage + 1);
				setIsFetching(false);
				const payloadPost = {
					query: Query.getPostCommentByPostId,
					variables: { pageNumber: currPage + 1, pageSize: 10, postId: props.id }
				};
				dispatch(getPostCommentByPostId(payloadPost));
			}
			setIsFetching(false);
		}
	};

	useEffect(() => {
		if (getPostDetail && getPostDetail.length) {
			setPosts(getPostDetail);
		} else {
			setPosts();
		}
	}, [getPostDetail]);

	useEffect(() => {
		if (bookmarkStatus && bookmarkStatus.status === 200) {
			enqueueSnackbar(bookmarkStatus.message, {
				variant: 'success',
				autoHideDuration: 3000
			});
		}
		if (bookmarkStatus && bookmarkStatus.status === 400) {
			enqueueSnackbar(bookmarkStatus.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
		}
	}, [bookmarkStatus, enqueueSnackbar]);

	function LinearProgressWithLabel() {
		return <LinearProgress variant="determinate" {...props} className="custom_progressbar rounded-lg" />;
	}

	function redirectTradingRoutes(id) {
		return history.push({
			pathname: `/application-user/portfolio/tradingview/${id}`
		});
	}

	function redirectProfileRoutes(id) {
		dispatch(closeCommentDialog());
		return history.push({
			pathname: `/user/${id}`
		});
	}

	if (loading) {
		return <FuseLoading />;
	}

	function closeComposeDialog() {
		dispatch(closeCommentDialog());
	}

	function renderDescription(description, mentionuserlist, mentionstocklist) {
		const descriptionArr = description ? description.split(' ') : [];
		return (
			<Typography className="md:text-14 block leading-5 mb-16" component="span">
				{descriptionArr &&
					descriptionArr.slice(0).map((i, ind) => {
						if (i[0] === '@') {
							if (mentionuserlist) {
								let mentionedUser = [];

								const id = i[0] === '@' && i.replace('@stockly.', '').replace('.', '');
								mentionedUser =
									mentionuserlist && mentionuserlist.find(ii => ii.id === parseInt(id, 10) && ii);
								if (mentionedUser !== undefined) {
									return (
										<Typography
											key={ind}
											onClick={() => redirectProfileRoutes(mentionedUser?.id)}
											className="text-yellow-800 font-semibold inline-block cursor-pointer"
										>
											{`${i[0]}${mentionedUser?.user_name}`} &nbsp;{' '}
										</Typography>
									);
								}
								return description;
							}
						} else if (i[0] === '$') {
							if (mentionstocklist) {
								let mentionStock = [];

								const id = i[0] === '$' && i.replace('$stockly.', '').replace('.', '');
								mentionStock =
									mentionstocklist && mentionstocklist.find(j => j?.id === parseInt(id, 10) && j);
								if (mentionStock !== undefined) {
									return (
										<Typography
											key={ind}
											onClick={() => redirectTradingRoutes(mentionStock?.ticker_id)}
											className="text-yellow-800 font-semibold inline-block cursor-pointer"
										>
											{`${i[0]}${mentionStock?.ticker_symbol}`} &nbsp;
										</Typography>
									);
								}
							}
							return description;
						} else {
							return `${i} `;
						}
						return true;
					})}
			</Typography>
		);
	}

	function handlePostVote(downVote, upVote, postIds) {
		const postCurrentId = parseInt(postIds, 10);
		const payloadData = {
			query: Query.addPostVote,
			variables: { isDownVote: downVote, isUpVote: !upVote, postId: postCurrentId }
		};
		const deepClone = JSON.parse(JSON.stringify(posts));
		deepClone.map((element, i) => {
			if (element.response.id === postIds) {
				const newData = { ...JSON.parse(JSON.stringify(element)) };
				if (upVote) {
					newData.response.is_user_up_vote = false;
					newData.response.up_vote -= 1;
				} else {
					newData.response.is_user_up_vote = true;
					newData.response.up_vote += 1;
				}

				deepClone[i] = newData;
			}
			return false;
		});

		setPosts(deepClone);

		dispatch(addPostVote(payloadData));
	}

	function handleBookmark(bookmark, bookmarkPostId) {
		const payloadData = {
			query: Query.addUserBookmarkPost,
			variables: { is_bookmark: !bookmark, postId: bookmarkPostId }
		};
		dispatch(addUserBookmarkPost(payloadData));
		const deepClone = JSON.parse(JSON.stringify(posts));
		deepClone.map((element, i) => {
			if (element.response.id === bookmarkPostId) {
				const newData = { ...JSON.parse(JSON.stringify(element)) };
				newData.response.is_bookmark = !bookmark;
				deepClone[i] = newData;
			}
			return true;
		});
		setPosts(deepClone);
	}

	function handleCommentValue(event) {
		event.preventDefault();
		setPostCommentValue(event.target.value);
	}

	function handleEditCommentValue(event) {
		event.preventDefault();
		setPostEditCommentValue(event.target.value);
	}

	function handleEditComment() {
		if (postEditCommentValue) {
			const payloadData = {
				query: Query.editPostCommentAndSubComment,
				variables: { comment: postEditCommentValue, commentId, postId: props.id }
			};
			dispatch(editPostCommentAndSubComment(payloadData));
			setPostEditCommentValue('');
			commentsLists.map((element, i) => {
				if (element.response.id === commentId) {
					const newData = { ...JSON.parse(JSON.stringify(element)) };
					newData.response.comment = postEditCommentValue;
					commentsLists[i] = newData;
				}
				return true;
			});

			setCommentsLists(commentsLists);
			setOpenDialog(false);
			setOpenEditDialog(false);
			setOpenCommentActionDialog(false);
		}
	}

	async function handleComment() {
		if (postCommentValue) {
			const payloadData = {
				query: Query.addPostCommentAndSubComment,
				variables: { comment: postCommentValue, postId: props.id }
			};
			await dispatch(addPostCommentAndSubComment(payloadData)).then(response => {
				if (
					response &&
					response.payload &&
					response.payload.data &&
					response.payload.data.addPostCommentAndSubComment &&
					response.payload.data.addPostCommentAndSubComment.data &&
					response.payload.data.addPostCommentAndSubComment.data.length
				) {
					setCommentId(response.payload.data.addPostCommentAndSubComment.data[0].response.id);
					commentsLists.push({
						response: {
							firstname: loginUserData.firstname,
							full_name: `${loginUserData.firstname} ${loginUserData.lastname}`,
							lastname: loginUserData.lastname,
							username: loginUserData.username,
							id: response.payload.data.addPostCommentAndSubComment.data[0].response.id,
							user_id: loginUserData.id,
							comment: postCommentValue,
							comment_status: true,
							profilephoto: loginUserData.profilephoto
						}
					});
					setCommentsLists(commentsLists);
				}
			});
			setPostCommentValue('');
		}
	}

	function deleteComment(curCommentId) {
		const payloadData = {
			query: Query.deletePostCommentAndSubComment,
			variables: { commentId: curCommentId, subComment: false }
		};
		dispatch(deletePostCommentAndSubComment(payloadData));

		setOpenDialog(false);
		setOpenCommentActionDialog(false);

		setCommentsLists(current =>
			current.filter(commentRemove => {
				return commentRemove.response.id !== curCommentId;
			})
		);
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24 comment-box'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				maxWidth="xs"
			>
				<span
					onClick={() => {
						dispatch(closeCommentDialog());
					}}
					aria-hidden="true"
					className="text-right block p-12 pb-6 absolute top-0 right-0 cursor-pointer"
				>
					<Icon>close</Icon>
				</span>

				<DialogContent
					classes={{ root: 'p-0' }}
					onScroll={onScroll}
					ref={listInnerRef}
					style={{ height: '70vh', overflowY: 'auto', paddingBottom: '100px' }}
				>
					<div>
						{posts &&
							posts.length > 0 &&
							posts.map((data, index) => (
								<div className="mb-24 bg-grey-50" key={index}>
									<div className="flex items-center mx-0 ng-star-inserted justify-between border-b border-grey-200 p-9 absolute top-36 bg-grey-50 z-20 left-0 right-0">
										<div className="flex">
											<Avatar
												name={
													data?.response?.full_name
														? data?.response?.full_name
														: data?.response?.user_name
												}
												className="mr-9 cursor-pointer capitalize"
												onClick={() => redirectProfileRoutes(data?.response?.user_id)}
												size="36px"
												alt="profile_image"
												maxInitials={2}
												round
												src={data?.response?.user_image}
											/>
											<Typography
												onClick={() => redirectProfileRoutes(data?.response?.user_id)}
												className="cursor-pointer"
											>
												<span className="block leading-tight">
													<span className="font-semibold md:text-14 leading-none capitalize">
														{data?.response?.full_name}
													</span>
												</span>
												<span className="block leading-tight">
													<span className="text-grey-500">
														{data?.response?.user_name
															? `@${data?.response?.user_name}`
															: ''}
													</span>
												</span>
											</Typography>
										</div>
									</div>
									<div className="p-12">
										{data?.response?.title && (
											<>
												{renderDescription(
													data.response.description,
													data.response.mention_user_list,
													data.response.mention_stock_list
												)}
											</>
										)}

										{data?.response?.news_data.news_content && (
											<div className="border border-grey-300 p-16 mt-24 rounded-md">
												{data.response.news_data.ticker_details && (
													<Swiper
														breakpoints={{
															319: {
																slidesPerView: 1
															},
															640: {
																slidesPerView: 2
															},
															768: {
																slidesPerView: 3
															},
															992: {
																slidesPerView: 4
															}
														}}
														slidesPerView={4}
														spaceBetween={10}
														pagination={{
															clickable: true
														}}
														modules={[Pagination]}
														className="mySwiper"
													>
														{data &&
															data.response.news_data.ticker_details &&
															data.response.news_data.ticker_details.length > 0 &&
															data.response.news_data.ticker_details.map(
																(newsData, newsIndex) => (
																	<SwiperSlide key={newsIndex}>
																		<div className="block cursor-pointer">
																			<div className="flex">
																				<img
																					onClick={() =>
																						redirectTradingRoutes(
																							newsData?.ticker_id
																						)
																					}
																					aria-hidden="true"
																					src={
																						newsData?.ticker_image
																							? newsData.ticker_image
																							: 'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png'
																					}
																					onError={e => {
																						e.currentTarget.src =
																							'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png';
																					}}
																					alt="profile_image"
																					className="w-36 h-36 rounded-full mr-12"
																				/>
																				<div
																					onClick={() =>
																						redirectTradingRoutes(
																							newsData?.ticker_id
																						)
																					}
																					aria-hidden="true"
																				>
																					<div className="mb-3">
																						<span className="font-semibold md:text-14 leading-none">
																							{newsData?.ticker_symbol}
																						</span>
																					</div>
																					<div>
																						<span className="text-grey-500 whitespace-nowrap w-96 inline-block text-ellipsis overflow-hidden">
																							{newsData?.ticker_name}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</SwiperSlide>
																)
															)}
													</Swiper>
												)}
												<h3 className="font-semibold text-14 mb-6 mt-10">
													{data?.response?.news_data?.news_title}
												</h3>
												<p className="text-grey-500 leading-5 mb-16 break-word">
													{data?.response?.news_data?.news_content}
												</p>
												{data?.response?.news_data?.news_image && (
													<img
														src={
															data?.response?.news_data?.news_image
																? data.response.news_data.news_image
																: 'assets/images/avatars/default_user.png'
														}
														alt="profile_image"
														className="w-6/12 rounded-lg"
													/>
												)}
											</div>
										)}
										{data?.response?.post_type === 'image' && (
											<Swiper
												breakpoints={{
													319: {
														slidesPerView: 2
													},
													599: {
														slidesPerView: 3
													},
													767: {
														slidesPerView: 4
													},
													992: {
														slidesPerView: 5
													},
													1200: {
														slidesPerView: 2
													}
												}}
												slidesPerView={2}
												spaceBetween={10}
												pagination={{
													clickable: true
												}}
												modules={[Pagination]}
												className="mySwiper"
											>
												{data &&
													data.response.attachment_data &&
													data.response.attachment_data.length > 0 &&
													data.response.attachment_data.map(
														(attachmentData, attachmentindex) => (
															<SwiperSlide key={attachmentindex}>
																<img
																	className="rounded-lg"
																	src={
																		attachmentData?.file_url
																			? attachmentData.file_url
																			: 'assets/images/avatars/default_user.png'
																	}
																	alt="Post_img"
																/>
															</SwiperSlide>
														)
													)}
											</Swiper>
										)}
										{data?.response?.post_type === 'poll' && (
											<>
												{data &&
													data.response.post_poll_data &&
													data.response.post_poll_data.length > 0 &&
													data.response.post_poll_data.map((pollData, i) => (
														<div
															className="border border-grey-200 mt-24 p-16 rounded-sm max-w-sm"
															key={i}
														>
															<div className="font-semibold bg-grey-300 px-12 py-6 rounded-sm">
																{pollData?.question}
															</div>
															{pollData &&
																pollData.options &&
																pollData.options.length > 0 &&
																pollData.options.map((pollOptionData, optioni) => (
																	<div key={optioni}>
																		<div className="mt-24 flex justify-between pb-6">
																			<span>{pollOptionData?.option}</span>
																			<span>
																				{pollData?.total_user_votes > 0
																					? parseFloat(
																							(
																								((pollOptionData?.result
																									? pollOptionData?.result
																									: 0) /
																									(pollData?.total_user_votes
																										? pollData?.total_user_votes
																										: 0)) *
																								100
																							).toFixed(2)
																					  )
																					: '0'}
																				%
																			</span>
																		</div>
																		<LinearProgressWithLabel
																			className="rounded-lg h-8"
																			value={
																				pollData?.total_user_votes > 0
																					? parseFloat(
																							(
																								((pollOptionData?.result
																									? pollOptionData?.result
																									: 0) /
																									(pollData?.total_user_votes
																										? pollData?.total_user_votes
																										: 0)) *
																								100
																							).toFixed(2)
																					  )
																					: '0'
																			}
																		/>
																	</div>
																))}
															<div className="mt-16">
																<span className="font-semibold">
																	{pollData?.total_user_votes}
																</span>{' '}
																Votes
															</div>
														</div>
													))}
											</>
										)}
										{loginUserData?.s2_user_type?.name !== 'admin' && (
											<div className="card-body p-0 mt-12">
												<span
													className="cursor-pointer p-8 pl-0 inline-block align-middle"
													aria-hidden="true"
													onClick={e =>
														handlePostVote(
															false,
															data.response.is_user_up_vote,
															data.response.id
														)
													}
												>
													{data.response.is_user_up_vote ? (
														<img
															className="align-middle w-20 h-20"
															src="assets/images/icon/like.png"
															alt="Post_img"
														/>
													) : (
														<img
															className="align-middle w-20 h-20"
															src="assets/images/icon/no_like.png"
															alt="Post_img"
														/>
													)}
												</span>
												<span
													className="inline-block p-8 inline-block align-middle cursor-pointer "
													onClick={() => {
														dispatch(openCommentDialog());
													}}
													aria-hidden="true"
												>
													<img
														className="align-middle w-20 h-20 inline-block"
														src="assets/images/icon/chat.png"
														alt="comment"
													/>
												</span>
												<span className="p-8 inline-block align-middle">
													<img
														className="align-middle w-20 h-20 inline-block"
														src="assets/images/icon/send.png"
														alt="share"
													/>
												</span>
												<span
													className="cursor-pointer p-8 pl-0 float-right inline-block align-middle"
													aria-hidden="true"
													onClick={e =>
														handleBookmark(data.response.is_bookmark, data.response.id)
													}
												>
													{data.response.is_bookmark ? (
														<img
															className="align-middle w-20 h-20"
															src="assets/images/icon/bookmark_save.png"
															alt="bookmark_img"
														/>
													) : (
														<img
															className="align-middle w-20 h-20"
															src="assets/images/icon/bookmark.png"
															alt="bookmark_img"
														/>
													)}
												</span>
											</div>
										)}
										<div>
											{data?.response?.up_vote > 0 && (
												<span
													className="cursor-pointer text-black font-semibold block mb-8"
													onClick={() => {
														dispatch(openLikesListDialog());
														setPostId(data?.response?.id);
													}}
													aria-hidden="true"
												>
													{data?.response?.up_vote} Likes
												</span>
											)}
											{data?.response?.comments > 0 && (
												<span
													className="cursor-pointer text-grey block mb-8"
													aria-hidden="true"
													onClick={() => {
														dispatch(openCommentDialog());
														setPostId(data?.response?.id);
													}}
												>
													{data?.response?.comments} Comments
												</span>
											)}
											<span className="text-grey-500 block text-12">
												{moment(data?.response?.date).fromNow()}
											</span>
										</div>
									</div>
								</div>
							))}
					</div>
					{commentsLists &&
						commentsLists.length > 0 &&
						commentsLists.map((commnetList, index) => (
							<div className="items-center mx-0 ng-star-inserted border-b p-9" key={index}>
								<div className="flex mb-10">
									<Avatar
										name={
											commnetList?.response?.full_name
												? commnetList?.response?.full_name
												: commnetList?.response?.username
										}
										className="mr-9 cursor-pointer capitalize"
										onClick={() => redirectProfileRoutes(commnetList?.response?.user_id)}
										size="36px"
										alt="profile_image"
										maxInitials={2}
										round
										src={commnetList?.response?.profilephoto}
									/>
									<Typography
										onClick={() => redirectProfileRoutes(commnetList?.response?.user_id)}
										className="cursor-pointer"
									>
										<span className="block leading-tight">
											<span className="font-semibold md:text-14 leading-none capitalize">
												{commnetList?.response?.full_name}
											</span>
										</span>
										<span className="block leading-tight">
											<span className="text-grey-500">
												{commnetList?.response?.username
													? `@${commnetList?.response?.username}`
													: ''}
											</span>
										</span>
									</Typography>
								</div>
								<div className="mb-10">
									<Typography className="md:text-14 block leading-5 whitespace-nowrap overflow-hidden text-ellipsis mb-10">
										{commnetList?.response?.comment}
									</Typography>
								</div>
								<div>
									<span
										className="cursor-pointer text-grey block mb-8"
										aria-hidden="true"
										onClick={() => {
											dispatch(openCommentDialog());
										}}
									>
										<img
											className="align-middle w-20 h-20 inline-block"
											src="assets/images/icon/chat.png"
											alt="comment"
										/>{' '}
										{commnetList?.response?.total_sub_comments}
									</span>

									<span className="text-grey-500 block text-12 flex justify-between items-center">
										{moment(commnetList?.response?.time).fromNow()}
										{loginUserData.id === commnetList?.response?.user_id && (
											<div
												onClick={() => {
													setOpenCommentActionDialog(true);
													setCommentId(commnetList?.response?.id);
													setPostEditCommentValue(commnetList?.response?.comment);
												}}
												aria-hidden="true"
												className="cursor-pointer"
											>
												<Icon color="action">more_horiz</Icon>
											</div>
										)}
									</span>
								</div>
							</div>
						))}
					{loginUserData?.s2_user_type?.name !== 'admin' && (
						<div className="flex flex-col md:overflow-hidden absolute bottom-12 left-12 right-6">
							<Paper
								component={motion.div}
								initial={{ y: -20, opacity: 0 }}
								animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
								className="flex items-center w-full rounded-lg px-8 py-4 shadow border"
							>
								<Input
									placeholder="Add a comment..."
									className="flex flex-1 mx-8"
									disableUnderline
									name="comment"
									fullWidth
									inputProps={{
										'aria-label': 'comment'
									}}
									onChange={handleCommentValue}
									onKeyUp={e => e.key === 'Enter' && handleComment()}
									value={postCommentValue}
								/>

								<Icon
									color="action"
									onClick={() => {
										handleComment();
									}}
									className={`${
										postCommentValue
											? 'cursor-pointer text-indigo'
											: 'cursor-not-allowed text-grey-400'
									}`}
									disabled={!postCommentValue}
								>
									send
								</Icon>
							</Paper>
						</div>
					)}
				</DialogContent>
			</Dialog>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				open={openCommentActionDialog}
				disableBackdropClick
				style={{ width: '300px', margin: '0 auto' }}
				onClose={closeComposeDialog}
				fullWidth
				maxWidth="xs"
			>
				<DialogContent classes={{ root: 'p-0 pt-0' }} style={{ overflowY: 'auto', paddingTop: '0' }}>
					<div className="items-center ng-star-inserted">
						<div
							className="block p-16 text-black justify-between cursor-pointer text-center"
							aria-hidden="true"
							onClick={() => {
								setOpenEditDialog(true);
							}}
						>
							<span className="normal-case">Edit Comment</span>
						</div>
						<div
							className="block border-t border-grey-200 p-16 text-black justify-between cursor-pointer text-center text-red-500"
							aria-hidden="true"
							onClick={() => {
								setOpenDialog(true);
							}}
						>
							<span className="normal-case">Delete Comment</span>
						</div>
						<div
							className="block border-t border-grey-200 p-16 text-black justify-between cursor-pointer text-center"
							aria-hidden="true"
							onClick={() => {
								setOpenCommentActionDialog(false);
							}}
						>
							<span className="normal-case">Cancel</span>
						</div>
					</div>
				</DialogContent>
			</Dialog>
			{openDialog && (
				<Dialog
					open={openDialog}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') {
							setOpenDialog(false);
						}
					}}
					aria-labelledby="followers"
					aria-describedby="followers-description"
				>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							className="text-15 text-black text-center"
							style={{ maxWidth: '300px' }}
						>
							Are you sure to delete Comment ?
						</DialogContentText>
					</DialogContent>
					<DialogActions className="text-center flex pb-20 px-20">
						<Button
							onClick={() => {
								setOpenDialog(false);
							}}
							className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
							style={{ border: '1px solid indigo' }}
							color="primary"
						>
							No
						</Button>
						<Button
							onClick={() => {
								deleteComment(commentId);
							}}
							className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
							color="primary"
							autoFocus
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{openEditDialog && (
				<Dialog
					open={openEditDialog}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') {
							setOpenEditDialog(false);
						}
					}}
					aria-labelledby="followers"
					aria-describedby="followers-description"
				>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							className="text-15 text-black text-center"
							style={{ maxWidth: '300px' }}
						>
							<div className="flex flex-col md:overflow-hidden">
								<Paper
									component={motion.div}
									initial={{ y: -20, opacity: 0 }}
									animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
									className="flex items-center w-full rounded-lg px-8 py-4 shadow border"
								>
									<Input
										placeholder="Add a comment..."
										className="flex flex-1 mx-8"
										disableUnderline
										name="comment"
										fullWidth
										inputProps={{
											'aria-label': 'comment'
										}}
										onChange={handleEditCommentValue}
										onKeyUp={e => e.key === 'Enter' && handleEditComment()}
										value={postEditCommentValue}
									/>
								</Paper>
							</div>
						</DialogContentText>
					</DialogContent>
					<DialogActions className="text-center flex pb-20 px-20">
						<Button
							onClick={() => {
								setOpenEditDialog(false);
							}}
							className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
							style={{ border: '1px solid indigo' }}
							color="primary"
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								handleEditComment();
							}}
							className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
							color="primary"
							disabled={!postEditCommentValue}
							autoFocus
						>
							Update
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{likesListDialog?.props?.open ? <LikesListDialog id={postId} /> : ''}
		</>
	);
}

export default withReducer('commonReducer', reducer)(CommentDialog);
