import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import userService from 'app/services/userService';
import history from '@history';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import teamQuery from 'app/main/query/team';
import BotQuery from 'app/main/query/bot';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_OTHER_ENDPOINT
});

export const getBots = createAsyncThunk('mybots/getBots', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getBotStatus = createAsyncThunk('mybots/getBotStatus', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const seeMoreTeamBot = createAsyncThunk('mybots/seeMoreTeamBot', async (data, { getState, dispatch }) => {
	try {
		const response = await axios.get(
			`/api/getDeveloperTeamsBot?teamId=${data.teamId}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
			{ ...data }
		);
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getBotType = createAsyncThunk('mybots/getBotType', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const checkVerifyEmail = createAsyncThunk('mybots/checkVerifyEmail', async (data, { getState, dispatch }) => {
	try {
		const userData = userService.getUserData();
		if (userData && userData.email_details && !userData.email_details.isEmailVerified) {
			dispatch(
				showMessage({
					message: 'Email has not been verified yet, email verification is required to  create  a bot.',
					variant: 'error'
				})
			);
			history.push('/my-profile');
			return false;
		}
		dispatch(openNewMyBotsDialog());
		const number = parseInt(userData.id, 10);
		const payload = {
			query: BotQuery.getTeamDetailsByTeamTypeId,
			variables: { userId: number, createdByMe: true }
		};
		dispatch(getTeamsData(payload));

		const payloadBotData = {
			query: BotQuery.getAllBotsByBotCategory,
			variables: { pageNumber: 1, pageSize: 1000, searchText: '' }
		};
		dispatch(getAllBotsByBotCategory(payloadBotData));

		return userData;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const uploadBannerImage = createAsyncThunk('mybots/uploadBannerImage', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	const formT = new FormData();
	formT.append('operations', JSON.stringify(data.payloadvalue));
	if (data.filedata) {
		formT.append('map', '{"nFile":["variables.bannerImage"]}');
		formT.append('nFile', data.filedata);
	} else {
		formT.append('map', '{}');
	}
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, formT);
		const result = await response.data;

		const number = parseInt(data.payloadvalue.variables.botId, 10);
		const payload = {
			query: BotQuery.getBotDetailsByBotId,
			variables: { botId: number }
		};
		dispatch(getBotById(payload));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const uploadFeaturedImage = createAsyncThunk(
	'mybots/uploadFeaturedImage',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		const formT = new FormData();
		formT.append('operations', JSON.stringify(data.payloadvalue));
		if (data.filedata) {
			formT.append('map', '{"nFile":["variables.featuredImage"]}');
			formT.append('nFile', data.filedata);
		} else {
			formT.append('map', '{}');
		}
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, formT);
			const result = await response.data;

			const number = parseInt(data.payloadvalue.variables.botId, 10);
			const payload = {
				query: BotQuery.getBotDetailsByBotId,
				variables: { botId: number }
			};
			dispatch(getBotById(payload));
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const createBots = createAsyncThunk('mybots/createBots', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	const formT = new FormData();
	formT.append('operations', JSON.stringify(data.payloadvalue));
	if (data.filedata) {
		formT.append('map', '{"0":["variables.image"],"1":["variables.featuredImage"]}');
		formT.append(0, data.filedata);
		formT.append(1, data.filedatafeature);
	} else {
		formT.append('map', '{}');
	}
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, formT);
		const result = await response.data;
		history.push('/my-bots');
		dispatch(closeNewMyBotsDialog());

		const payload = {
			query: BotQuery.getBotByUserId,
			variables: {
				searchText: '',
				pageNumber: 1,
				pageSize: 12
			}
		};

		if (localStorage.getItem('botType') !== 'all') {
			payload.variables.ownedByMe = localStorage.getItem('botType') === 'personal';
		}
		dispatch(getBots(payload));
		return result.data.addBot;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const updateBots = createAsyncThunk('mybots/updateBots', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	const formT = new FormData();
	formT.append('operations', JSON.stringify(data.payloadvalue));
	if (data.filedata) {
		formT.append('map', '{"0":["variables.botImage"]}');
		formT.append(0, data.filedata);
	} else {
		formT.append('map', '{}');
	}
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, formT);
		const result = await response.data;
		const number = parseInt(data.payloadvalue.variables.botId, 10);
		const payload = {
			query: BotQuery.getBotDetailsByBotId,
			variables: { botId: number }
		};
		dispatch(getBotById(payload));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const removeBots = createAsyncThunk('mybots/removeBots', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});
export const deleteBot = createAsyncThunk('mybots/deleteBot', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const requestForVerification = createAsyncThunk(
	'mybots/requestForVerification',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			const number = parseInt(data.variables.botId, 10);
			const payload = {
				query: BotQuery.getBotDetailsByBotId,
				variables: { botId: number }
			};
			dispatch(getBotById(payload));
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const requestForPublish = createAsyncThunk('mybots/requestForPublish', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		const number = parseInt(data.variables.botId, 10);
		const payload = {
			query: BotQuery.getBotDetailsByBotId,
			variables: { botId: number }
		};
		dispatch(getBotById(payload));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const removeBanner = createAsyncThunk('mybots/removeBanner', async (data, { getState, dispatch }) => {
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		const number = parseInt(data.variables.botId, 10);
		const payload = {
			query: BotQuery.getBotDetailsByBotId,
			variables: { botId: number }
		};
		dispatch(getBotById(payload));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const removeBotFeaturedImage = createAsyncThunk(
	'mybots/removeBotFeaturedImage',
	async (data, { getState, dispatch }) => {
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			const number = parseInt(data.variables.botId, 10);
			const payload = {
				query: BotQuery.getBotDetailsByBotId,
				variables: { botId: number }
			};
			dispatch(getBotById(payload));
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getBotById = createAsyncThunk('mybots/getBotById', async (botId, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	const userData = userService.getUserData();
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...botId });
		const result = await response.data;
		const payload = {
			query: BotQuery.botStatus
		};
		dispatch(getBotStatus(payload));
		const number = parseInt(userData.id, 10);
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getTeamsData = createAsyncThunk('mybots/getTeamsData', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getAllBotsByBotCategory = createAsyncThunk(
	'mybots/getAllBotsByBotCategory',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getTeamDetailsByTeamTypeId = createAsyncThunk(
	'mybots/getTeamDetailsByTeamTypeId',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getBotStatusByAdmin = createAsyncThunk(
	'mybots/getBotStatusByAdmin',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const reGenerateBotToken = createAsyncThunk(
	'mybots/reGenerateBotToken',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const checkNameHandlerExist = createAsyncThunk(
	'mybots/checkNameHandlerExist',
	async (searchText, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...searchText });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getAvailableCountry = createAsyncThunk(
	'mybots/getAvailableCountry',
	async (searchText, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...searchText });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const forgotPassword = createAsyncThunk('mybots/forgotPassword', async (data, { getState, dispatch }) => {
	try {
		const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const verifyOTP = createAsyncThunk('mybots/verifyOTP', async (data, { getState, dispatch }) => {
	try {
		const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const botResetPassword = createAsyncThunk('mybots/botResetPassword', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});
export const checkUsername = createAsyncThunk('mybots/checkUsername', async (searchText, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...searchText });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getPermissionList = createAsyncThunk(
	'mybots/getPermissionList',
	async (searchText, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...searchText });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);
const myBotsSlice = createSlice({
	name: 'mybots',
	initialState: {
		myBotsDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		teamDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	},
	reducers: {
		openNewMyBotsDialog: (state, action) => {
			state.myBotsDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: null
			};
		},
		closeNewMyBotsDialog: (state, action) => {
			state.myBotsDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: null
			};
		},
		openTeamDialog: (state, action) => {
			state.teamDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: null
			};
		},
		closeTeamDialog: (state, action) => {
			state.teamDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: null
			};
		},
		resetBot: (state, action) => {
			state.createBotData = {};
			state.deleteBotData = {};
			state.updateBotData = {};
			state.checkNameHandlerExistData = {};
			state.reGenerateBotTokenData = {};
			state.seeMoreTeamBotData = {};
			state.uploadBannerImageData = {};
			state.getTeamDetailsByTeamTypeIdData = {};
			state.getBotStatusByAdminData = {};
			state.forgotPasswordData = {};
			state.verifyOTPData = {};
			state.botResetPasswordData = {};
			state.requestForVerificationData = {};
			state.getBots = {};
			state.requestForPublish = {};
			state.getPermissionListData = {};
		}
	},
	extraReducers: {
		[getBots.fulfilled]: (state, action) => ({
			...state,
			botsData: action.payload
		}),
		[seeMoreTeamBot.fulfilled]: (state, action) => ({
			...state,
			seeMoreTeamBotData: action.payload
		}),
		[getBotType.fulfilled]: (state, action) => ({
			...state,
			botTypeData: action.payload
		}),
		[getTeamsData.fulfilled]: (state, action) => ({
			...state,
			teamData: action.payload
		}),
		[reGenerateBotToken.fulfilled]: (state, action) => ({
			...state,
			reGenerateBotTokenData: action.payload
		}),
		[getBotStatus.fulfilled]: (state, action) => ({
			...state,
			botStatusData: action.payload
		}),
		[createBots.fulfilled]: (state, action) => ({
			...state,
			createBotData: action.payload
		}),
		[uploadBannerImage.fulfilled]: (state, action) => ({
			...state,
			uploadBannerImageData: action.payload
		}),
		[uploadFeaturedImage.fulfilled]: (state, action) => ({
			...state,
			uploadFeaturedImageData: action.payload
		}),
		[updateBots.fulfilled]: (state, action) => ({
			...state,
			updateBotData: action.payload
		}),
		[getBotById.fulfilled]: (state, action) => ({
			...state,
			getBotByIdData: action.payload
		}),
		[removeBots.fulfilled]: (state, action) => ({
			...state,
			removeBotData: action.payload
		}),
		[deleteBot.fulfilled]: (state, action) => ({
			...state,
			deleteBotData: action.payload
		}),
		[checkNameHandlerExist.fulfilled]: (state, action) => ({
			...state,
			checkNameHandlerExistData: action.payload
		}),
		[getAllBotsByBotCategory.fulfilled]: (state, action) => ({
			...state,
			botStoreCategoryTypeData: action.payload
		}),
		[getAvailableCountry.fulfilled]: (state, action) => ({
			...state,
			getAvailableCountryData: action.payload
		}),
		[getTeamDetailsByTeamTypeId.fulfilled]: (state, action) => ({
			...state,
			getTeamDetailsByTeamTypeIdData: action.payload
		}),
		[getBotStatusByAdmin.fulfilled]: (state, action) => ({
			...state,
			getBotStatusByAdminData: action.payload
		}),
		[checkUsername.fulfilled]: (state, action) => ({
			...state,
			checkUsernameData: action.payload
		}),
		[requestForVerification.fulfilled]: (state, action) => ({
			...state,
			requestForVerificationData: action.payload
		}),
		[requestForPublish.fulfilled]: (state, action) => ({
			...state,
			requestForPublishData: action.payload
		}),
		[getPermissionList.fulfilled]: (state, action) => ({
			...state,
			getPermissionListData: action.payload
		}),
		[botResetPassword.fulfilled]: (state, action) => ({
			...state,
			botResetPasswordData: action.payload
		}),
		[forgotPassword.fulfilled]: (state, action) => ({
			...state,
			forgotPasswordData: action.payload
		}),
		[verifyOTP.fulfilled]: (state, action) => ({
			...state,
			verifyOTPData: action.payload
		})
	}
});

export const { openNewMyBotsDialog, closeNewMyBotsDialog, resetBot, openTeamDialog, closeTeamDialog } =
	myBotsSlice.actions;

export default myBotsSlice.reducer;
