import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const BotsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/bots/bots',
			component: lazy(() => import('./Bots'))
		},
		{
			path: '/admin/bots/bot-permission',
			component: lazy(() => import('./BotsPermission'))
		},
		{
			path: '/admin/bots',
			component: () => <Redirect to="/admin/bots/bots" />
		}
	]
};

export default BotsConfig;
