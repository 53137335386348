import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import userService from 'app/services/userService';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import { setUserData } from 'app/auth/store/userSlice';
import axios from 'axios';
import Query from 'app/main/query/user';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_OTHER_ENDPOINT
});

export const getProfile = createAsyncThunk('profile/getProfile', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		if (result && result.data) {
			dispatch(setUserData(result.data.getProfile.data));
		}
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const updateProfile = createAsyncThunk('profile/updateProfile', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	const formT = new FormData();
	formT.append('operations', JSON.stringify(data.payloadvalue));
	if (data.filedata) {
		formT.append('map', '{"nFile":["variables.file"]}');
		formT.append('nFile', data.filedata);
	} else {
		formT.append('map', '{}');
	}
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, formT);
		const result = await response.data;
		const payload = {
			query: Query.getProfile
		};
		dispatch(getProfile(payload));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const updateProfileImage = createAsyncThunk('profile/updateProfile', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		const payload = {
			query: Query.getProfile
		};
		setTimeout(() => {
			dispatch(getProfile(payload));
		}, 1000);

		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const uploadBannerImage = createAsyncThunk('mybots/uploadBannerImage', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `${userService.getAccessToken() || null}`;
	const axiosConfig = {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	};
	try {
		const response = await instance.post('/api/uploadDevUserBanner', data, axiosConfig);
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const verifyUserEmail = createAsyncThunk('profile/verifyUserEmail', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getDevicesLocation = createAsyncThunk(
	'profile/getDevicesLocationData',
	async (data, { getState, dispatch }) => {
		try {
			const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const checkUsername = createAsyncThunk('profile/checkUsername', async (searchText, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...searchText });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

const profileSlice = createSlice({
	name: 'profile',
	initialState: {},
	reducers: {
		resetProfile: (state, action) => {
			state.verifyUserEmailData = {};
			state.updateProfileData = {};
		}
	},
	extraReducers: {
		[getProfile.fulfilled]: (state, action) => ({
			...state,
			profileData: action.payload
		}),
		[updateProfile.fulfilled]: (state, action) => ({
			...state,
			updateProfileData: action.payload
		}),
		[verifyUserEmail.fulfilled]: (state, action) => ({
			...state,
			verifyUserEmailData: action.payload
		}),
		[uploadBannerImage.fulfilled]: (state, action) => ({
			...state,
			uploadBannerImageData: action.payload
		}),
		[getDevicesLocation.fulfilled]: (state, action) => ({
			...state,
			getDevicesLocationData: action.payload
		}),
		[checkUsername.fulfilled]: (state, action) => ({
			...state,
			checkUsernameData: action.payload
		})
	}
});

export const { resetProfile } = profileSlice.actions;

export default profileSlice.reducer;
