import Documentation from './Documentation';

const DocumentationConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	auth: 'bot_developer',
	routes: [
		{
			path: '/documentation',
			component: Documentation
		}
	]
};

export default DocumentationConfig;
