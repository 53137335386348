import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import userService from 'app/services/userService';
import Toolbar from '@material-ui/core/Toolbar';
import FuseLoading from '@fuse/core/FuseLoading';
import { motion } from 'framer-motion';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Query from 'app/main/query/user';
import Icon from '@material-ui/core/Icon';
import _ from '@lodash';
import history from '@history';
import Avatar from 'react-avatar';
import {
	closeBlockAccountsDialog,
	getblockUsersList,
	resetuser,
	unBlockUsersById,
	blockUserId
} from 'app/auth/store/userSlice';

function BlockAccountsDialog() {
	const dispatch = useDispatch();

	const blockAccountsData = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.getblockUsersListData?.data?.getblockUsersList?.data?.length > 0
			? userMenuReducer.user.getblockUsersListData.data.getblockUsersList.data
			: []
	);

	const appDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.blockAccountsDialog);

	const userData = userService.getUserData();
	const listInnerRef = useRef();
	const [loading, setLoading] = useState(true);
	const [currPage, setCurrPage] = useState(1); // storing current page number
	const [blockList, setBlockList] = useState(blockAccountsData); // storing list
	const [isFetching, setIsFetching] = useState(false);
	const [isNoneData, setIsNoneData] = useState(true);
	const [blockLoading, setBlockLoading] = useState(false);
	const [block, setBlock] = useState(true);

	useEffect(() => {
		const unmounted = false;
		if (currPage) {
			setIsFetching(true);
			const payload = {
				query: Query.getblockUsersList,
				variables: { pageNumber: currPage, pageSize: 8 }
			};
			dispatch(getblockUsersList(payload));

			if (!unmounted) {
				setLoading(false);
			}
		}
		return () => {};
	}, [dispatch, currPage]);

	useEffect(() => {
		if (blockAccountsData && blockAccountsData.length) {
			setBlockList([...blockList, ...blockAccountsData]);
			setIsFetching(false);
		} else {
			setTimeout(() => {
				setIsFetching(false);
			}, 1000);
			setIsNoneData(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blockAccountsData]);

	const onScroll = () => {
		setIsFetching(true);
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				setCurrPage(currPage + 1);
			}
			setIsFetching(false);
		}
	};

	if (loading) {
		return <FuseLoading />;
	}

	function closeComposeDialog() {
		dispatch(closeBlockAccountsDialog());
	}

	function redirectProfileRoutes(id) {
		dispatch(closeBlockAccountsDialog());
		window.open(`/user/${id}`, '_blank');
	}

	function handleUnblock(userId, matrixId, blockTitle) {
		setBlockLoading(userId);
		const userid = parseInt(userId, 10);
		const payloadData = {
			query: Query.unBlockUsersById,
			variables: { blockUserId: userid, matrixUserId: matrixId }
		};
		dispatch(unBlockUsersById(payloadData));

		blockList.map((element, i) => {
			setBlockList(ele => ele.filter(elements => elements.id !== userId));
			return true;
		});
		return block;
	}

	return (
		<Dialog
			classes={{
				paper: 'm-24'
			}}
			{...appDialog.props}
			disableBackdropClick
			onClose={closeComposeDialog}
			fullWidth
			maxWidth="xs"
		>
			<AppBar position="static" elevation={0}>
				<Toolbar className="flex w-full justify-between">
					<Typography variant="subtitle1" color="inherit">
						Blocked Accounts
					</Typography>
					<Button
						className="float-right bg-transparent rounded p-0 w-auto min-w-0"
						variant="contained"
						color="primary"
						type="button"
						onClick={() => {
							dispatch(closeBlockAccountsDialog());
						}}
					>
						<Icon>close</Icon>
					</Button>
				</Toolbar>
			</AppBar>

			<DialogContent
				classes={{ root: 'p-16' }}
				className=""
				onScroll={onScroll}
				ref={listInnerRef}
				style={{ height: '50vh', overflowY: 'auto' }}
			>
				{blockList &&
					blockList?.length > 0 &&
					blockList.map((blockuser, index) => (
						<div
							className="flex items-center mt-6 mx-0 ng-star-inserted mb-16  justify-between"
							key={index}
						>
							<div className="flex items-center">
								<Avatar
									name={blockuser?.full_name}
									onClick={() => redirectProfileRoutes(blockuser?.id)}
									className="mr-12 cursor-pointer"
									size="45px"
									alt="profile_image"
									round
									src={blockuser?.thumb}
								/>
								<div>
									<div className="mb-3">
										<Typography
											onClick={() => redirectProfileRoutes(blockuser?.id)}
											className="font-semibold md:text-14 leading-none mr-20  cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis"
											style={{ width: '200px' }}
										>
											{blockuser?.full_name}
										</Typography>
									</div>
									<div>
										<span className="text-grey-500">
											{blockuser?.username ? `@${blockuser?.username}` : ''}
										</span>
									</div>
								</div>
							</div>

							<button
								className={`whitespace-nowrap rounded border-1 border-indigo-500 py-8 text-center  font-semibold w-96 ${
									blockuser?.is_follow ? 'bg-white-500 text-indigo' : 'bg-indigo-500 text-white'
								}`}
								onClick={() => {
									handleUnblock(blockuser?.id, blockuser?.matrix_user_id, block);
								}}
								color="primary"
								type="submit"
								aria-label="Following"
							>
								{block ? 'Unblock' : 'Block'}
							</button>
						</div>
					))}
				{blockAccountsData && blockAccountsData.length === 0 && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 0.1 } }}
						className="flex flex-1 items-center justify-center h-full"
					>
						<Typography color="textSecondary" variant="h5" className="text-16 pt-5">
							There are no any Block user!
						</Typography>
					</motion.div>
				)}
			</DialogContent>
		</Dialog>
	);
}

export default BlockAccountsDialog;
