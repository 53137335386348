const locale = {
	DASHBOARD: 'Dashboard',
	APPLICATIONS: 'Applications',
	MYBOTS: 'My Bots',
	BOTSANALYTICS: 'Bots Analytics',
	TEAMS: 'Teams',
	AGREEMENTS: 'Agreements',
	DOCUMENTATION: 'Documentation',
	DEVUSERS: 'Dev Users',
	ADMINDASHBOARD: 'Dashboard',
	BOTS: 'Bots',
	BOTPERMISSION: 'Bot Permission',
	POST: 'Post',
	POSTCATEGORIES: 'Post Report Categories',
	POSTSUBCATEGORIES: 'Post Sub Categories',
	USERPOSTREPORT: 'User Post Report',
	SETTINGS: 'Settings',
	MARKETPLACE: 'Marketplace',
	BOTCATEGORIES: 'Bot Categories',
	BOTSUMMARY: 'Bot Summary',
	MARKETPLACECATEGORIES: 'Marketplace Categories',
	BUGREPORT: 'Bug Report',
	CONTACTUS: 'Contact Us',
	ATTACHMENTTYPE: 'Attachment Types',
	BROKERAGE: 'Brokerage',
	FEEDBACK: 'Feedback',
	ACTIVITIES_ADMIN: 'Activities',
	ACTIVITIES_SCOPE: 'Activities Scope',
	ACTIVITIES_TYPE: 'Activities Type',
	NOTIFICATIONTYPE: 'Notification Type',
	ALERTTYPE: 'Alert Type',
	APPUSERS: 'Users',
	POLICY: 'Policy',
	POLICY_TYPE: 'Policy Type',
	PRIVACY_POLICY: 'Privacy Policy',
	PORTFOLIO: 'Portfolio',
	WATCHLIST: 'Watch List',
	PORTFOLIOLIST: 'Portfolio List',
	MARKET: 'Market',
	LIVEPOST: 'Post',
	LATESTNEWS: 'Latest News',
	EVENTS: 'Events',
	ACTIVITIES: 'Activities',
	ALERTS: 'Alerts'
};

export default locale;
