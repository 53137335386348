import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withReducer from 'app/store/withReducer';
import FuseLoading from '@fuse/core/FuseLoading';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Query from 'app/main/query/user';
import Icon from '@material-ui/core/Icon';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from '@lodash';
import {
	resetcommon,
	getPostReportCategoryRecord,
	addPostUserReportTopicRecord,
	closeReportListDialog
} from './store/commonSlice';
import reducer from './store';

function ReportListDialog(props) {
	const dispatch = useDispatch();

	const getReportList = useSelector(({ commonReducer }) =>
		commonReducer?.common?.getPostReportCategoryRecordData?.data?.getPostReportCategoryRecord?.data?.length > 0
			? commonReducer.common.getPostReportCategoryRecordData.data.getPostReportCategoryRecord.data
			: []
	);

	const appDialog = useSelector(({ commonReducer }) => commonReducer?.common?.reportListDialog);

	const [loading, setLoading] = useState(true);
	const [reportLists, setReportLists] = useState(getReportList);
	const [openDialog, setOpenDialog] = useState(false);
	const [reportCategoryId, setReportCategoryId] = useState();

	useEffect(() => {
		let unmounted = false;
		const payload = {
			query: Query.getPostReportCategoryRecord,
			variables: { pageSize: 20, pageNumber: 1 }
		};
		dispatch(getPostReportCategoryRecord(payload));
		setLoading(false);
		return () => {
			unmounted = true;
			dispatch(resetcommon());
		};
	}, [dispatch, props.id]);

	useEffect(() => {
		if (getReportList && getReportList.length) {
			setReportLists(getReportList);
		} else {
			setReportLists();
		}
	}, [getReportList]);

	if (loading) {
		return <FuseLoading />;
	}

	function reportPost(categoryId) {
		const payloadData = {
			query: Query.addPostUserReportTopicRecord,
			variables: { categoryId, postId: props.id }
		};
		dispatch(addPostUserReportTopicRecord(payloadData));

		setOpenDialog(false);
		closeComposeDialog();
	}

	function closeComposeDialog() {
		dispatch(closeReportListDialog());
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title" className="pb-0">
					<div className="flex justify-between">
						Report
						<span
							onClick={() => {
								dispatch(closeReportListDialog());
							}}
							aria-hidden="true"
							className="text-right block cursor-pointer"
						>
							<Icon>close</Icon>
						</span>
					</div>
					<div className="border-t border-grey-200 pt-16 mt-10 mb-0 text-14 text-black">
						If someone in immediate danger, get help before reporting to Stockly. Don't wait
					</div>
				</DialogTitle>

				<DialogContent classes={{ root: 'px-24' }} style={{ height: '50vh', overflowY: 'auto' }}>
					{reportLists &&
						reportLists.length > 0 &&
						reportLists.map((report, index) => (
							<div className="items-center ng-star-inserted" key={index}>
								<div
									className="block border-t border-grey-200 py-12 text-black flex justify-between cursor-pointer"
									aria-hidden="true"
									onClick={() => {
										setOpenDialog(true);
										setReportCategoryId(report?.id);
									}}
								>
									<span className="normal-case">{report?.category}</span>
									<Icon color="action" className="text-grey-400">
										navigate_next
									</Icon>
								</div>
							</div>
						))}
				</DialogContent>
			</Dialog>
			{openDialog && (
				<Dialog
					open={openDialog}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') {
							setOpenDialog(false);
						}
					}}
					aria-labelledby="followers"
					aria-describedby="followers-description"
				>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							className="text-15 text-black text-center"
							style={{ maxWidth: '300px' }}
						>
							Your report is Anonymous, Do you really want to report this post ?
						</DialogContentText>
					</DialogContent>
					<DialogActions className="text-center flex pb-20 px-20">
						<Button
							onClick={() => {
								setOpenDialog(false);
							}}
							className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
							style={{ border: '1px solid indigo' }}
							color="primary"
						>
							No
						</Button>
						<Button
							onClick={() => {
								reportPost(reportCategoryId);
							}}
							className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
							color="primary"
							autoFocus
						>
							Report
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}

export default withReducer('commonReducer', reducer)(ReportListDialog);
