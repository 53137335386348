import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const BrokerageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/brokerage',
			component: lazy(() => import('./Brokerage'))
		},
		{
			path: '/admin/brokerage',
			component: () => <Redirect to="/admin/brokerage" />
		}
	]
};

export default BrokerageConfig;
