import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import FuseLoading from '@fuse/core/FuseLoading';
import Toolbar from '@material-ui/core/Toolbar';
import { motion } from 'framer-motion';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import userService from 'app/services/userService';
import marketData from 'app/main/query/market';
import Icon from '@material-ui/core/Icon';
import _ from '@lodash';
import { closeMarketMoversDialog, getTopMoversList, resetDashboard } from './store/applicationDashboardSlice';

function MarketMoversDialog() {
	const dispatch = useDispatch();

	const marketMoversDetails = useSelector(({ applicationDashboardReducer }) =>
		applicationDashboardReducer?.applicationDashboard?.getTopMoversListData?.data?.getTopMoversList?.data?.length >
		0
			? applicationDashboardReducer.applicationDashboard.getTopMoversListData.data.getTopMoversList.data
			: []
	);

	const appDialog = useSelector(
		({ applicationDashboardReducer }) => applicationDashboardReducer?.applicationDashboard?.marketMoversDialog
	);

	const listInnerRef = useRef();
	const [loading, setLoading] = useState(true);
	const [currPage, setCurrPage] = useState(1); // storing current page number
	const [marketMoversList, setMarketMoversList] = useState(marketMoversDetails); // storing list
	const [isFetching, setIsFetching] = useState(false);
	const userData = userService.getUserData();
	const [isNoneData, setIsNoneData] = useState(true);

	useEffect(() => {
		const unmounted = false;
		if (currPage) {
			setIsFetching(true);
			const payload = {
				query: marketData.getTopMoversList,
				variables: { pageNumber: currPage, pageSize: 10 }
			};

			dispatch(getTopMoversList(payload));

			if (!unmounted) {
				setLoading(false);
			}
		}
		return () => {
			dispatch(resetDashboard());
		};
	}, [dispatch, currPage]);

	useEffect(() => {
		if (marketMoversDetails && marketMoversDetails.length) {
			setMarketMoversList([...marketMoversList, ...marketMoversDetails]);
			setIsFetching(false);
		} else {
			setTimeout(() => {
				setIsFetching(false);
			}, 1000);
			setIsNoneData(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [marketMoversDetails]);

	const onScroll = () => {
		setIsFetching(true);
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				setCurrPage(currPage + 1);
			}
			setIsFetching(false);
		}
	};

	function closeComposeDialog() {
		dispatch(closeMarketMoversDialog());
	}

	function calculateStock(currentPrice, previousPrice, currencyValue) {
		let def = 0;
		let Average = 0;
		let isLoss = true;
		const currentPriceValue = currentPrice ? Number(currentPrice).toFixed(0) : '0.00';
		const prevPrice = previousPrice ? Number(previousPrice).toFixed(0) : '0.00';
		const currencySymbol = currencyValue === 'INR' ? '₹' : '$';

		if (currentPrice !== 0) {
			def = Number(currentPrice) - Number(previousPrice);
			Average =
				Number(currentPrice) === 0 ? Number(currentPrice).toFixed(2) : ((def * 100) / currentPrice).toFixed(2);

			isLoss = Average < 0;
		}

		const payload = {
			deference: Math.abs(def).toFixed(2),
			percentage: Number(Average).toFixed(2),
			isProfit: !isLoss,
			currentPriceValue,
			prevPrice,
			currency: currencySymbol,
			sign: isLoss ? '-' : '+',
			img: isLoss ? 'arrow_drop_down' : 'arrow_drop_up'
		};
		return (
			<div
				className={`inline text-12 ${
					payload.sign === '-' ? 'text-red-500' : 'text-green-500'
				} text-white truncate`}
			>
				{/* {payload.sign} */}
				{payload.currency}
				{payload.deference}
				<Icon className="align-middle">{payload.img}</Icon>
			</div>
		);
	}

	if (loading) {
		return <FuseLoading />;
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog?.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				maxWidth="xs"
			>
				<AppBar position="static" elevation={0}>
					<Toolbar className="flex w-full justify-between">
						<Typography variant="subtitle1" color="inherit">
							Market Movers
						</Typography>
						<Button
							className="float-right bg-transparent rounded p-0 w-auto min-w-0"
							variant="contained"
							color="primary"
							type="button"
							onClick={() => {
								dispatch(closeMarketMoversDialog());
							}}
						>
							<Icon>close</Icon>
						</Button>
					</Toolbar>
				</AppBar>

				<DialogContent
					classes={{ root: 'p-16' }}
					className="overflow-x-hidden"
					onScroll={onScroll}
					ref={listInnerRef}
					style={{ height: '100vh', overflowY: 'auto' }}
				>
					{marketMoversList && marketMoversList?.length > 0 && (
						<>
							{marketMoversList.map((topLoser, index) => (
								<motion.div
									className="widget w-full flex items-center justify-between bg-white rounded-lg p-8 lg:p-6 bg-grey-100 h-72 mb-16"
									key={index}
								>
									<div className="flex items-center justify-between">
										<img
											className="block w-52 h-52 p-5 rounded-full border-grey-300 border-1 bg-white justify-center"
											src={
												topLoser?.response?.ticker_img
													? topLoser.response.ticker_img
													: 'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png'
											}
											alt={topLoser?.response?.name ? topLoser.response.name : ''}
											onError={e => {
												e.currentTarget.src =
													'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png';
											}}
										/>

										<Typography variant="subtitle1" className="ml-12">
											<span
												title={`${topLoser?.response?.ticker_name}`}
												className="block MuiTypography-root-190 font-semibold text-12 w-220 text-black md:tracking-wide MuiTypography-subtitle1-201 whitespace-nowrap overflow-hidden text-ellipsis"
											>
												{topLoser?.response?.ticker_name ? topLoser.response.ticker_name : ''}
											</span>
											<span className="block MuiTypography-root-190 text-12 text-grey md:tracking-wide MuiTypography-subtitle1-201">
												{topLoser?.response?.ticker_id ? topLoser.response.ticker_id : ''}
											</span>
										</Typography>
									</div>

									<div className="items-center justify-between">
										<span className="block MuiTypography-root-190 text-black font-semibold text-14 md:tracking-wide MuiTypography-subtitle1-201">
											{calculateStock(
												topLoser?.response.ticker_price,
												topLoser?.response.ticker_prev_close_price
											)
												? calculateStock(
														topLoser?.response.ticker_price,
														topLoser?.response.ticker_prev_close_price,
														userData?.s2_countries?.currency
												  )
												: '0'}
										</span>
									</div>
								</motion.div>
							))}
						</>
					)}
					{isNoneData && marketMoversList && marketMoversList.length === 0 && (
						<>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { delay: 0.1 } }}
								className="flex flex-1 items-center justify-center h-full"
							>
								<Typography color="textSecondary" variant="h5" className="text-16 pt-5">
									There are no any Top Loser!
								</Typography>
							</motion.div>
						</>
					)}
					{isFetching && (
						<div className="loader mt-20 ml-6 block text-center mx-auto">
							<CircularProgress className="mt-30 ml-30" size={30} disableShrink />
						</div>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}

export default MarketMoversDialog;
