import AdminDashboardConfig from './admin-dashboard/AdminDashboardConfig';
import DevUsersConfig from './dev-users/DevUsersConfig';
import BotsConfig from './bots/BotsConfig';
import PostConfig from './post/PostConfig';
import SettingsConfig from './settings/SettingsConfig';
import ChangePasswordConfig from './change-password/ChangePasswordConfig';
import DevUserDetailConfig from './devUserDetail/DevUserDetailConfig';
import BotDetailConfig from './botDetail/BotDetailConfig';
import MarketPlaceConfig from './marketPlace/MarketPlaceConfig';
import BrokerageConfig from './brokerage/BrokerageConfig';
import BugReportConfig from './bug-report/BugReportConfig';
import ContactUsConfig from './contact-us/ContactUsConfig';
import FeedbackConfig from './feedback/FeedbackConfig';
import ActivityConfig from './activity/ActivityConfig';
import NotificationTypeConfig from './notification-type/NotificationTypeConfig';
import AttachmentTypeConfig from './attachment-type/AttachmentTypeConfig';
import AlertTypeConfig from './alert-type/AlertTypeConfig';
import AppUsersConfig from './app-users/AppUsersConfig';
import AppTransactionBalanceConfig from './app-transaction-balance/AppTransactionBalanceConfig';
import PolicyConfig from './policy/PolicyConfig';

const adminConfigs = [
	AdminDashboardConfig,
	DevUsersConfig,
	BotsConfig,
	PostConfig,
	SettingsConfig,
	ChangePasswordConfig,
	DevUserDetailConfig,
	BotDetailConfig,
	MarketPlaceConfig,
	BrokerageConfig,
	BugReportConfig,
	ContactUsConfig,
	FeedbackConfig,
	ActivityConfig,
	NotificationTypeConfig,
	AttachmentTypeConfig,
	AlertTypeConfig,
	AppUsersConfig,
	AppTransactionBalanceConfig,
	PolicyConfig
];

export default adminConfigs;
