import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import LoginConfig from 'app/main/auth/login/LoginConfig';
import RegisterConfig from 'app/main/auth/register/RegisterConfig';
import LogoutConfig from 'app/main/auth/logout/LogoutConfig';
import ForgotPasswordPageConfig from 'app/main/auth/forgot-password/ForgotPasswordPageConfig';
import ResetPasswordPageConfig from 'app/main/auth/reset-password/ResetPasswordPageConfig';
import VerifyEmailPageConfig from 'app/main/auth/verify-email/VerifyEmailPageConfig';
import Error404PageConfig from 'app/main/errors/404/Error404PageConfig';
import pageConfigs from 'app/main/pages/pageConfigs';
import adminConfigs from 'app/main/admin/adminConfigs';
import appDashboardConfigs from 'app/main/application-user/appDashboardConfigs';
import LandingConfig from 'app/main/landing/LandingConfig';

const routeConfigs = [
	...pageConfigs,
	...adminConfigs,
	...appDashboardConfigs,
	LandingConfig,
	LoginConfig,
	RegisterConfig,
	LogoutConfig,
	ForgotPasswordPageConfig,
	ResetPasswordPageConfig,
	VerifyEmailPageConfig,
	Error404PageConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	// {
	// 	path: '/',
	// 	exact: true,
	// 	component: () => <Redirect to="/home" />
	// },
	{
		component: () => <Redirect to="/errors/error-404" />
	}
];

export default routes;
