import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import Dashboard from './Dashboard';

i18next.addResourceBundle('en', 'dashboard', en);
i18next.addResourceBundle('tr', 'dashboard', tr);

const DashboardConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	auth: 'bot_developer',
	routes: [
		{
			path: '/dashboard',
			component: Dashboard
		}
	]
};

export default DashboardConfig;
