import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const ActivityConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/activity/activity-scope',
			component: lazy(() => import('./ActivityScope'))
		},
		{
			path: '/admin/activity/activity-type',
			component: lazy(() => import('./ActivityType'))
		},
		{
			path: '/admin/activity',
			component: () => <Redirect to="/admin/activity/activity-scope" />
		}
	]
};

export default ActivityConfig;
