import MyBotsConfig from './mybots/MyBotsConfig';
import DashboardConfig from './dashboard/DashboardConfig';
import TeamsConfig from './teams/TeamsConfig';
import BotsAnalyticsConfig from './analytics/BotsAnalyticsConfig';
import MyProfileConfig from './profile/MyProfileConfig';
import AgreementsConfig from './agreements/AgreementsConfig';
import DocumentationConfig from './documentation/DocumentationConfig';

const pagesConfigs = [
	DashboardConfig,
	MyBotsConfig,
	TeamsConfig,
	BotsAnalyticsConfig,
	MyProfileConfig,
	AgreementsConfig,
	DocumentationConfig
];

export default pagesConfigs;
