import Avatar from 'react-avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import history from '@history';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	logoutUser,
	getSetUserSetting,
	enableDisableNotification,
	enableDisableShowPriceChangeInPercentage,
	getAccountTypeAndUpdateType,
	getTeamInvites,
	updateInviteRequestStatus,
	openBlockAccountsDialog,
	resetuser,
	getUserDetailById,
	createUniqueApiKey,
	openDeleteAccountDialog,
	openAboutDialog,
	openHelpDialog
} from 'app/auth/store/userSlice';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Query from 'app/main/query/user';
import withReducer from 'app/store/withReducer';
import reducer from 'app/auth/store';
import { useSnackbar } from 'notistack';
import BlockAccountsDialog from './BlockAccountsDialog';
import DeleteAccountDialog from './DeleteAccountDialog';
import HelpDialog from './HelpDialog';
import AboutDialog from './AboutDialog';

function UserMenu(props) {
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const portfolioStatus = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.getSetUserSettingData?.data?.getSetUserSetting
			? userMenuReducer.user.getSetUserSettingData.data.getSetUserSetting.publicPortfolioStatus
			: ''
	);
	const notificationStatus = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.enableDisableNotificationData?.data?.enableDisableNotification
			? userMenuReducer.user.enableDisableNotificationData.data.enableDisableNotification.enableDisableStatus
			: ''
	);

	const accountStatus = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.getAccountTypeAndUpdateTypeData?.data?.getAccountTypeAndUpdateType
			? userMenuReducer.user.getAccountTypeAndUpdateTypeData.data.getAccountTypeAndUpdateType.private_account
			: ''
	);

	const priceStatus = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.enableDisableShowPriceChangeInPercentageData?.data
			?.enableDisableShowPriceChangeInPercentage
			? userMenuReducer.user.enableDisableShowPriceChangeInPercentageData.data
					.enableDisableShowPriceChangeInPercentage.show_price_change_in_percentage
			: ''
	);

	const teamInvitesList = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.getTeamInvitesData?.data?.getTeamInvites?.data?.length > 0
			? userMenuReducer.user.getTeamInvitesData.data.getTeamInvites.data
			: []
	);

	const requestStatus = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.updateInviteRequestStatusData?.data?.updateInviteRequestStatus
			? userMenuReducer.user.updateInviteRequestStatusData.data.updateInviteRequestStatus
			: {}
	);

	const apiKeyStatus = useSelector(
		({ userMenuReducer }) => userMenuReducer?.user?.getUserDetailByIdData?.data?.getUserDetailById || ''
	);

	const apiKeyGenStatus = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.createUniqueApiKeyData?.data?.createUniqueApiKey
			? userMenuReducer.user.createUniqueApiKeyData.data.createUniqueApiKey
			: {}
	);

	const blockAccountsDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.blockAccountsDialog);

	const deleteAccountDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.deleteAccountDialog);

	const helpDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.helpDialog);

	const aboutDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.aboutDialog);

	const [userMenu, setUserMenu] = useState(null);
	const [userNotification, setUserNotification] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [publicPortfolio, setPublicPortfolio] = useState(portfolioStatus);
	const [notification, setNotification] = useState(notificationStatus);
	const [privateAccount, setPrivateAccount] = useState(accountStatus);
	const [price, setPrice] = useState(priceStatus);
	const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
	const [openTokenDialog, setOpenTokenDialog] = useState(false);
	const [openAccountConfirmDialog, setOpenAccountConfirmDialog] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [copyTokenSuccess, setCopyTokenSuccess] = useState('Copy');
	const [loading, setLoading] = useState(true);
	const [approvalToken, setApprovalToken] = useState(apiKeyStatus?.data?.response?.api_key);
	const [refreshIcon, setrefreshIcon] = useState(false);
	const tokenRef = useRef(null);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};
	const userNotificationClick = event => {
		setUserNotification(event.currentTarget);
		const payload = {
			query: Query.getTeamInvites
		};
		dispatch(getTeamInvites(payload));
	};

	const userNotificationClose = () => {
		setUserNotification(null);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	useEffect(() => {
		if (openSettingsDialog === true) {
			setPublicPortfolio(portfolioStatus);
			const payload = {
				query: Query.getSetUserSetting,
				variables: {
					publicPortfolioStatus: ''
				}
			};
			dispatch(getSetUserSetting(payload));
		}
	}, [dispatch, openSettingsDialog, portfolioStatus]);

	useEffect(() => {
		if (openSettingsDialog === true) {
			setNotification(notificationStatus);
			const payload = {
				query: Query.enableDisableNotification
			};
			dispatch(enableDisableNotification(payload));
		}
	}, [dispatch, notificationStatus, openSettingsDialog]);

	useEffect(() => {
		if (openSettingsDialog === true) {
			setPrivateAccount(accountStatus);
			const payload = {
				query: Query.getAccountTypeAndUpdateType
			};
			dispatch(getAccountTypeAndUpdateType(payload));
		}
	}, [dispatch, openSettingsDialog, accountStatus]);

	const userType = user?.s2_user_type?.name;

	useEffect(() => {
		if (userType === 'bot_developer') {
			const payload = {
				query: Query.getTeamInvites
			};
			dispatch(getTeamInvites(payload));
		}
		return () => {
			dispatch(resetuser());
		};
	}, [dispatch, userType]);

	useEffect(() => {
		if (userType === 'bot_developer') {
			if (apiKeyStatus?.data?.response?.api_key) {
				setApprovalToken(apiKeyStatus?.data?.response?.api_key);
			}
		}
	}, [apiKeyStatus?.data?.response?.api_key, dispatch, userType]);

	useEffect(() => {
		if (apiKeyStatus) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [apiKeyStatus]);

	useEffect(() => {
		setPrice(priceStatus);
		if (openSettingsDialog === true) {
			const payload = {
				query: Query.enableDisableShowPriceChangeInPercentage
				// variables: {
				// 	status: price
				// }
			};
			dispatch(enableDisableShowPriceChangeInPercentage(payload));
		}
	}, [dispatch, openSettingsDialog, priceStatus]);

	useEffect(() => {
		if (openTokenDialog === true) {
			const payload = {
				query: Query.getUserDetailById,
				variables: {
					id: user.id
				}
			};
			dispatch(getUserDetailById(payload));
		}
	}, [dispatch, openTokenDialog, user.id]);

	useEffect(() => {
		if (userType === 'bot_developer') {
			if (requestStatus && requestStatus.status === 200) {
				enqueueSnackbar(requestStatus.message, {
					variant: 'success',
					autoHideDuration: 3000
				});
			}
			if (requestStatus && requestStatus.status === 400) {
				enqueueSnackbar(requestStatus.message, {
					variant: 'error',
					autoHideDuration: 3000
				});
			}
		}
	}, [requestStatus, enqueueSnackbar, userType]);

	useEffect(() => {
		if (userType === 'bot_developer') {
			if (apiKeyGenStatus && apiKeyGenStatus.status === 200) {
				enqueueSnackbar(apiKeyGenStatus.message, {
					variant: 'success',
					autoHideDuration: 3000
				});
				setApprovalToken(apiKeyGenStatus?.data?.api_key);
				setrefreshIcon(false);
			}
			if (apiKeyGenStatus && apiKeyGenStatus.status === 400) {
				setOpenTokenDialog(false);
				enqueueSnackbar(apiKeyGenStatus.message, {
					variant: 'error',
					autoHideDuration: 3000
				});
				setrefreshIcon(false);
			}
			if (apiKeyGenStatus && apiKeyGenStatus.status === 401) {
				setOpenTokenDialog(false);
				enqueueSnackbar(apiKeyGenStatus.message, {
					variant: 'error',
					autoHideDuration: 3000
				});
				setrefreshIcon(false);
			}
		}
	}, [apiKeyGenStatus, enqueueSnackbar, userType]);

	function redirectLink(userId) {
		return history.push({
			pathname: `/change-password/${userId}`
		});
	}

	function handleLogout() {
		dispatch(logoutUser());
	}

	function handleToggleChange(toggle) {
		if (toggle === 'public') {
			const payload = {
				query: Query.getSetUserSetting,
				variables: {
					publicPortfolioStatus: 'group'
				}
			};
			dispatch(getSetUserSetting(payload));
			setPublicPortfolio('group');
		}
		if (toggle === 'group') {
			const payload = {
				query: Query.getSetUserSetting,
				variables: {
					publicPortfolioStatus: 'public'
				}
			};
			dispatch(getSetUserSetting(payload));
			setPublicPortfolio('public');
		}
	}

	function handleNotificationToggleChange(toggle) {
		if (toggle === 'enabled') {
			const payload = {
				query: Query.enableDisableNotification,
				variables: {
					status: 'disabled'
				}
			};
			dispatch(enableDisableNotification(payload));
			setNotification('disabled');
		}
		if (toggle === 'disabled') {
			const payload = {
				query: Query.enableDisableNotification,
				variables: {
					status: 'enabled'
				}
			};
			dispatch(enableDisableNotification(payload));
			setNotification('enabled');
		}
	}

	function handlePriceToggleChange(toggle) {
		setPrice(!toggle);
		const payload = {
			query: Query.enableDisableShowPriceChangeInPercentage,
			variables: {
				status: !toggle
			}
		};
		dispatch(enableDisableShowPriceChangeInPercentage(payload));
	}

	function handleAccountPrivacy(toggle) {
		setPrivateAccount(!toggle);
		const payload = {
			query: Query.getAccountTypeAndUpdateType,
			variables: {
				status: !toggle
			}
		};
		dispatch(getAccountTypeAndUpdateType(payload));
		setOpenAccountConfirmDialog(false);
	}

	function handleAcceptDeleteRequest(acceptStatus, accpetedTeamId) {
		const teamId = parseInt(accpetedTeamId, 10);

		const payloadData = {
			query: Query.updateInviteRequestStatus,
			variables: { isAccept: acceptStatus, teamId }
		};
		dispatch(updateInviteRequestStatus(payloadData));
		setTimeout(() => {
			const payload = {
				query: Query.getTeamInvites
			};
			dispatch(getTeamInvites(payload));
		}, 800);
	}

	function handleClick(teamId) {
		return history.push({
			pathname: `/team-detail/${teamId}`
		});
	}

	function handleApiKey() {
		setrefreshIcon(true);
		const payloadData = {
			query: Query.createUniqueApiKey
		};
		setTimeout(() => {
			dispatch(createUniqueApiKey(payloadData));
		}, 500);
		const payload = {
			query: Query.getUserDetailById,
			variables: {
				id: user.id
			}
		};
		setTimeout(() => {
			dispatch(getUserDetailById(payload));
		}, 1000);
		if (apiKeyGenStatus.status === 401) {
			setOpenDialog(true);
			setrefreshIcon(false);
		}
	}

	function copyToClipboardToken(e) {
		tokenRef.current.select();
		document.execCommand('copy');
		e.target.focus();
		setCopyTokenSuccess('Copied!');
	}

	return (
		<>
			{user && user.s2_user_type && user.s2_user_type.name === 'bot_developer' && (
				<div className="flex items-center">
					<div className="inline-block relative cursor-pointer">
						<Icon className="text-32 text-amber-500" onClick={userNotificationClick}>
							notifications
						</Icon>
						<span className="inline-block bg-red-600 text-white w-14 h-14 rounded-full text-center text-10 pt-1 absolute right-0 top-0">
							{teamInvitesList?.length}
						</span>
					</div>
					<Popover
						open={Boolean(userNotification)}
						anchorEl={userNotification}
						onClose={userNotificationClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
						classes={{
							paper: 'py-8 invite-team-list left-0'
						}}
					>
						<>
							{teamInvitesList &&
								teamInvitesList.length > 0 &&
								teamInvitesList.map((teamList, i) => (
									<MenuItem role="button" className="mb-16" key={i}>
										<div className="flex items-center mx-0 ng-star-inserted justify-between">
											<div className="flex items-center">
												<div className="mr-12 cursor-pointer">
													<img
														className="w-32 h-24 block mx-auto"
														src={
															teamList?.s2_team?.image
																? teamList.s2_team.image
																: '/assets/images/avatars/team.png'
														}
														alt="Team"
													/>
												</div>
												<div>
													<div>
														<Typography
															className="font-semibold md:text-12 leading-none mr-20 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis pb-5 capitalize"
															title={teamList?.s2_team?.name}
															onClick={() => {
																handleClick(teamList?.s2_team.id);
																userNotificationClose();
															}}
															style={{ width: '90px' }}
														>
															{teamList?.s2_team?.name}
														</Typography>
													</div>
													<div>
														<span
															className="text-grey-500 block whitespace-nowrap overflow-hidden text-ellipsis text-12"
															title={teamList?.s2_team?.short_description}
															style={{ width: '90px' }}
														>
															{teamList?.s2_team?.short_description
																? teamList.s2_team.short_description
																: '-'}
														</span>
													</div>
												</div>
											</div>
											<button
												className="whitespace-nowrap rounded border-1 border-indigo-500 py-6 px-8 text-center font-semibold w-60  text-12 bg-indigo-500 text-white"
												color="primary"
												type="submit"
												aria-label="Accept"
												onClick={() => {
													handleAcceptDeleteRequest(true, teamList?.s2_team?.id);
												}}
											>
												Accept
											</button>
											<button
												className="whitespace-nowrap rounded border-1 ml-8 border-indigo-500 py-6 px-8 text-center font-semibold w-60 text-12 bg-white-500 text-indigo"
												color="primary"
												type="submit"
												aria-label="Cancel"
												onClick={() => {
													handleAcceptDeleteRequest(false, teamList?.s2_team?.id);
												}}
											>
												Decline
											</button>
										</div>
									</MenuItem>
								))}
							{teamInvitesList && teamInvitesList.length === 0 && (
								<Typography className="px-12">There are no any invite Team Here</Typography>
							)}
						</>
					</Popover>
				</div>
			)}
			<Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
				<Avatar
					name={user.firstname ? `${user.firstname} ${user.lastname}` : user.username}
					className="md:mx-4"
					alt="user photo"
					src={user.profilephoto}
					maxInitials={2}
					size="40px"
					round
				/>

				<div className="hidden md:flex flex-col mx-4 -mt-6 items-start text-left">
					<Typography component="span" className="font-semibold">
						<div className="block capitalize">
							{user?.firstname} {user?.lastname}
						</div>
						<div className="block text-12 leading-none mr-20 text-grey">
							{user.username ? `@${user.username}` : ''}
						</div>
					</Typography>
					{/* <Typography className="text-11 font-medium capitalize" color="textSecondary">
						{user.type}
					</Typography> */}
				</div>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				<>
					<MenuItem
						component={Link}
						to={userType === 'app_user' ? `/user/${user?.id}` : '/my-profile'}
						onClick={userMenuClose}
						role="button"
					>
						<ListItemIcon className="min-w-40">
							<Icon>account_circle</Icon>
						</ListItemIcon>
						<ListItemText primary="My Profile" />
					</MenuItem>
					<MenuItem
						onClick={() => {
							redirectLink(1);
							userMenuClose();
						}}
						role="button"
					>
						<ListItemIcon className="min-w-40">
							<Icon>lock_reset</Icon>
						</ListItemIcon>
						<ListItemText primary="Reset Password" />
					</MenuItem>
					{userType === 'bot_developer' && (
						<MenuItem
							onClick={() => {
								setOpenTokenDialog(true);
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>vpn_key</Icon>
							</ListItemIcon>
							<ListItemText primary="API Key" />
						</MenuItem>
					)}
					{userType === 'app_user' && (
						<MenuItem
							onClick={() => {
								setOpenSettingsDialog(true);
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>settings</Icon>
							</ListItemIcon>
							<ListItemText primary="Settings" />
						</MenuItem>
					)}
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							userMenuClose();
						}}
					>
						<ListItemIcon className="min-w-40">
							<Icon>exit_to_app</Icon>
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</MenuItem>
				</>
			</Popover>
			{userType === 'app_user' && (
				<>
					<Dialog
						open={openSettingsDialog}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setOpenSettingsDialog(false);
							}
						}}
						aria-labelledby="logout-app"
						aria-describedby="logout-app-description"
					>
						<AppBar position="static" elevation={0}>
							<Toolbar className="flex w-full justify-between">
								<Typography variant="subtitle1" color="inherit">
									Settings
								</Typography>
								<Button
									className="float-right bg-transparent rounded p-0 w-auto min-w-0"
									variant="contained"
									color="primary"
									type="button"
									onClick={() => {
										setOpenSettingsDialog(false);
									}}
								>
									<Icon>close</Icon>
								</Button>
							</Toolbar>
						</AppBar>

						<DialogContentText id="alert-dialog-description" className="p-24">
							<Typography
								className="items-center flex justify-between bg-grey-100 rounded-8 px-12 mb-12 border"
								component="span"
							>
								<span className="font-semibold text-sm text-black md:tracking-wide">
									Public Portfolio
								</span>

								<Switch
									checked={publicPortfolio === 'public'}
									onClick={() => handleToggleChange(publicPortfolio)}
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									className="pr-6 w-52"
								/>
							</Typography>
							<Typography
								className="items-center flex justify-between bg-grey-100 rounded-8 px-12 mb-12 border"
								component="span"
							>
								<span className="font-semibold text-sm text-black md:tracking-wide">Notification</span>

								<Switch
									checked={notification === 'enabled'}
									onClick={() => handleNotificationToggleChange(notification)}
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									className="pr-6 w-52"
								/>
							</Typography>
							<Typography
								className="items-center flex justify-between bg-grey-100 rounded-8 px-12 mb-12 border"
								component="span"
							>
								<span className="font-semibold text-sm text-black md:tracking-wide">
									Show Price Change in percentage
								</span>

								<Switch
									checked={price === true}
									onClick={() => handlePriceToggleChange(price)}
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									className="pr-6 w-52"
								/>
							</Typography>
							<Typography
								className="items-center flex justify-between bg-grey-100 rounded-8 px-12 mb-12 border"
								component="span"
							>
								<span className="font-semibold text-sm text-black md:tracking-wide">
									Private Account
								</span>

								<Switch
									checked={privateAccount === true}
									onClick={() => {
										setOpenAccountConfirmDialog(true);
									}}
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									className="pr-6 w-52"
								/>
							</Typography>
							<Typography
								className="items-center flex bg-grey-100 rounded-8 px-12 mb-12 border"
								style={{ height: '38px' }}
								component="span"
							>
								<Icon>policy</Icon>
								<span className="font-semibold text-sm text-black md:tracking-wide ml-6">
									<Link
										to="/auth/register/tabs/Policy"
										target="_blank"
										role="button"
										className="inline-block text-sky-400 cursor-pointer !bg-white"
									>
										Legal
									</Link>
								</span>
							</Typography>
							<Typography
								className="items-center flex bg-grey-100 rounded-8 px-12 mb-12 border cursor-pointer"
								onClick={() => {
									dispatch(openBlockAccountsDialog());
								}}
								style={{ height: '38px' }}
								component="span"
							>
								<Icon>block</Icon>
								<span className="font-semibold text-sm text-black md:tracking-wide ml-6">
									Blocked Accounts
								</span>
							</Typography>
							<Typography
								className="items-center flex bg-grey-100 rounded-8 px-12 mb-16 border cursor-pointer"
								onClick={() => {
									dispatch(openDeleteAccountDialog());
								}}
								style={{ height: '38px' }}
								component="span"
							>
								<Icon>delete</Icon>
								<span className="font-semibold text-sm text-black md:tracking-wide ml-6">
									Delete Account
								</span>
							</Typography>
							<hr />
							<Typography className="items-center flex" component="span">
								<span className="font-semibold text-sm text-black md:tracking-wide pt-10 pb-10">
									More info and support
								</span>
							</Typography>
							<Typography
								className="items-center flex bg-grey-100 rounded-8 px-12 mb-12 border cursor-pointer"
								onClick={() => {
									dispatch(openAboutDialog());
								}}
								style={{ height: '38px' }}
								component="span"
							>
								<Icon>info</Icon>
								<span className="font-semibold text-sm text-black md:tracking-wide ml-6">About</span>
							</Typography>
							<Typography
								className="items-center flex bg-grey-100 rounded-8 px-12 mb-12 border cursor-pointer"
								onClick={() => {
									dispatch(openHelpDialog());
								}}
								style={{ height: '38px' }}
								component="span"
							>
								<Icon>support</Icon>
								<span className="font-semibold text-sm text-black md:tracking-wide ml-6">Help</span>
							</Typography>
						</DialogContentText>
					</Dialog>
					<Dialog
						open={openAccountConfirmDialog}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setOpenAccountConfirmDialog(false);
							}
						}}
						aria-labelledby="Account-confimation"
						aria-describedby="Account-confimation-description"
					>
						<DialogTitle id="alert-dialog-title">
							{privateAccount ? 'Switch to Public account ?' : 'Switch to Private account ?'}
						</DialogTitle>
						<DialogContent>
							<DialogContentText
								id="alert-dialog-description"
								className="text-13 text-black text-left"
								style={{ maxWidth: '350px' }}
							>
								{privateAccount ? (
									<div className="public">
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">image</Icon>Anyone will be able to see
											your photos and videos
										</div>
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">chat_bubble</Icon>This Won't change who
											can tag, @mention or message you
										</div>
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">person_add</Icon>All pending follow
											requests will be approved unless you delete them.
										</div>
									</div>
								) : (
									<div className="private">
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">image</Icon>Only your followers will be
											able to see your photos and videos.
										</div>
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">chat_bubble</Icon>This won't change who
											can tag, @mention or message you.
										</div>
									</div>
								)}
							</DialogContentText>
						</DialogContent>
						<DialogActions className="text-center flex pb-20 px-20">
							<Button
								onClick={() => {
									setOpenAccountConfirmDialog(false);
								}}
								className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
								style={{ border: '1px solid indigo' }}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									handleAccountPrivacy(privateAccount);
								}}
								className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
								color="primary"
								autoFocus
							>
								{privateAccount ? 'Switch to public' : 'Switch to private'}
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
			{userType === 'bot_developer' && (
				<>
					<Dialog
						open={openTokenDialog}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setOpenTokenDialog(false);
							}
						}}
						aria-labelledby="logout-app"
						aria-describedby="logout-app-description"
					>
						<AppBar position="static" elevation={0}>
							<Toolbar className="flex w-full justify-between">
								<Typography variant="subtitle1" color="inherit">
									API Key
								</Typography>
								<Button
									className="float-right bg-transparent rounded p-0 w-auto min-w-0"
									variant="contained"
									color="primary"
									type="button"
									onClick={() => {
										setOpenTokenDialog(false);
									}}
								>
									<Icon>close</Icon>
								</Button>
							</Toolbar>
						</AppBar>

						<DialogContentText
							id="alert-dialog-description"
							className="p-24 mb-0"
							style={{ minWidth: '400px' }}
						>
							{loading ? (
								<div className="text-center">
									<CircularProgress size={24} className="mx-auto" />
								</div>
							) : (
								<>
									{apiKeyStatus?.data?.response?.api_key ? (
										<>
											<span className="block font-semibold">Token:</span>
											<div className="flex items-center relative">
												<Typography
													className="w-full flex items-center justify-center border rounded-md px-8 py-4 mt-8 mb-16 cursor-pointer botdetailtoken"
													onClick={copyToClipboardToken}
												>
													<InputBase
														inputRef={tokenRef}
														value={approvalToken || ''}
														fullWidth
													/>
													<Button
														className="whitespace-nowrap rounded bg-indigo-500"
														variant="contained"
														color="primary"
														aria-label="Copy"
													>
														<Icon className="text-16 mr-5" title="copy UserId">
															content_copy
														</Icon>{' '}
														{copyTokenSuccess}
													</Button>
												</Typography>
												<Icon
													className={`text-16 ml-5 -mt-5 absolute -right-20 cursor-pointer text-indigo-500 font-bold ${
														refreshIcon ? `animate-spin` : ''
													}`}
													title="Refresh Token"
													onClick={() => {
														handleApiKey();
													}}
												>
													refresh
												</Icon>
											</div>
										</>
									) : (
										<>
											<Button
												className="block bg-indigo-500 rounded w-full"
												variant="contained"
												color="primary"
												type="button"
												disabled={isButtonDisabled}
												onClick={() => {
													setIsButtonDisabled(true);
													handleApiKey();
												}}
											>
												Generate Key
											</Button>
										</>
									)}
								</>
							)}
						</DialogContentText>
					</Dialog>
					<Dialog
						open={openAccountConfirmDialog}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setOpenAccountConfirmDialog(false);
							}
						}}
						aria-labelledby="Account-confimation"
						aria-describedby="Account-confimation-description"
					>
						<DialogTitle id="alert-dialog-title">
							{privateAccount ? 'Switch to Public account ?' : 'Switch to Private account ?'}
						</DialogTitle>
						<DialogContent>
							<DialogContentText
								id="alert-dialog-description"
								className="text-13 text-black text-left"
								style={{ maxWidth: '350px' }}
							>
								{privateAccount ? (
									<div className="public">
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">image</Icon>Anyone will be able to see
											your photos and videos
										</div>
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">chat_bubble</Icon>This Won't change who
											can tag, @mention or message you
										</div>
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">person_add</Icon>All pending follow
											requests will be approved unless you delete them.
										</div>
									</div>
								) : (
									<div className="private">
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">image</Icon>Only your followers will be
											able to see your photos and videos.
										</div>
										<div className="mb-10 flex items-center">
											<Icon className="align-middle mr-10">chat_bubble</Icon>This won't change who
											can tag, @mention or message you.
										</div>
									</div>
								)}
							</DialogContentText>
						</DialogContent>
						<DialogActions className="text-center flex pb-20 px-20">
							<Button
								onClick={() => {
									setOpenAccountConfirmDialog(false);
								}}
								className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
								style={{ border: '1px solid indigo' }}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									handleAccountPrivacy(privateAccount);
								}}
								className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
								color="primary"
								autoFocus
							>
								{privateAccount ? 'Switch to public' : 'Switch to private'}
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
			<Dialog
				open={openDialog}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						setOpenDialog(false);
					}
				}}
				aria-labelledby="logout-app"
				aria-describedby="logout-app-description"
			>
				<DialogTitle id="alert-dialog-title">Logout</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to Logout?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenDialog(false);
						}}
						color="primary"
					>
						No
					</Button>
					<Button onClick={handleLogout} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			{blockAccountsDialog?.props?.open ? <BlockAccountsDialog /> : ''}
			{deleteAccountDialog?.props?.open ? <DeleteAccountDialog /> : ''}
			{helpDialog?.props?.open ? <HelpDialog /> : ''}
			{aboutDialog?.props?.open ? <AboutDialog /> : ''}
		</>
	);
}

export default withReducer('userMenuReducer', reducer)(UserMenu);
