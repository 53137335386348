import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const portfolioConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/application-user/portfolio/watch-list',
			component: lazy(() => import('./watchlist'))
		},
		{
			path: '/application-user/portfolio/portfolio-list',
			component: lazy(() => import('./portfoliolist'))
		},
		{
			path: '/application-user/portfolio/alerts',
			component: lazy(() => import('./alerts'))
		},
		{
			path: '/application-user/portfolio/tradingview/:id',
			component: lazy(() => import('./Tradingview'))
		},
		{
			path: '/application-user/portfolio',
			component: () => <Redirect to="/application-user/portfolio/watch-list" />
		}
	]
};

export default portfolioConfig;
