import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import userService from 'app/services/userService';
import withReducer from 'app/store/withReducer';
import Avatar from 'react-avatar';
import Toolbar from '@material-ui/core/Toolbar';
import FuseLoading from '@fuse/core/FuseLoading';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import Query from 'app/main/query/user';
import newsQuery from 'app/main/query/news';
import history from '@history';
import ReactApexChart from 'react-apexcharts';
import Icon from '@material-ui/core/Icon';
import DialogContentText from '@material-ui/core/DialogContentText';
import _ from '@lodash';
import {
	resetcommon,
	getUserUpvoteDownvoteList,
	closeLikesListDialog,
	userFollowUnfollow,
	getNewsUpvoteDownvoteList
} from './store/commonSlice';
import reducer from './store';

function LikesListDialog(props) {
	const dispatch = useDispatch();

	const getLikeuser = useSelector(({ commonReducer }) =>
		props.name === 'news'
			? commonReducer?.common?.getNewsUpvoteDownvoteListData?.data?.getNewsUpvoteDownvoteList?.data || ''
			: commonReducer?.common?.getUserUpvoteDownvoteListData?.data?.getUserUpvoteDownvoteList?.data || ''
	);

	const appDialog = useSelector(({ commonReducer }) => commonReducer?.common?.likesListDialog);

	const listInnerRef = useRef();
	const loginUserData = userService.getUserData();
	const [loading, setLoading] = useState(true);
	const [followLoading, setFollowLoading] = useState(false);
	const [currPage, setCurrPage] = useState(1); // storing current page number
	const [likeUsersLists, setLikeUsersLists] = useState(getLikeuser); // storing list
	const [isFetching, setIsFetching] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [openDialog, setOpenDialog] = useState(false);
	const [followingUserId, setFollowingUserId] = useState([]);
	const [followUseringStatus, setFollowingUserStatus] = useState(false);
	const [followingUserName, setFollowingUserName] = useState(false);

	useEffect(() => {
		setIsFetching(true);
		if (props.name === 'news') {
			const payloadPost = {
				query: newsQuery.getNewsUpvoteDownvoteList,
				variables: { newsId: props.id, vote: 'upvote' }
			};
			dispatch(getNewsUpvoteDownvoteList(payloadPost));
		} else {
			const payloadPost = {
				query: Query.getUserUpvoteDownvoteList,
				variables: { postId: props.id, vote: 'upvote' }
			};
			dispatch(getUserUpvoteDownvoteList(payloadPost));
		}

		setIsFetching(false);
		setLoading(false);
		return () => {
			dispatch(resetcommon());
		};
	}, [dispatch, props.id, props.name]);

	useEffect(() => {
		if (getLikeuser && getLikeuser.length) {
			setLikeUsersLists(getLikeuser);
		} else {
			setLikeUsersLists();
		}
	}, [getLikeuser]);

	// useEffect(() => {
	// 	if (currPage + 1) {
	// 		if (currPage === 1) {
	// 			setFollowingLists([...getLikeuser]);
	// 		} else if (getLikeuser && getLikeuser.length) {
	// 			setFollowingLists([...followingLists, ...getLikeuser]);
	// 			setIsFetching(false);
	// 		} else {
	// 			setIsFetching(false);
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [getLikeuser]);

	// const onScroll = () => {
	// 	setIsFetching(true);
	// 	if (listInnerRef.current && getLikeuser.length > 0) {
	// 		const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
	// 		if (scrollTop + clientHeight === scrollHeight) {
	// 			setCurrPage(currPage + 1);
	// 			setIsFetching(false);
	// 			const payloadPost = {
	// 				query: Query.getUserUpvoteDownvoteList,
	// 				variables: { pageNumber: currPage + 1, pageSize: 10, search: '', userId }
	// 			};
	// 			dispatch(getUserUpvoteDownvoteList(payloadPost));
	// 		}
	// 		setIsFetching(false);
	// 	}
	// };

	function redirectProfileRoutes(id) {
		dispatch(closeLikesListDialog());
		return history.push({
			pathname: `/user/${id}`
		});
	}

	if (loading) {
		return <FuseLoading />;
	}

	function handleFollowUnfollow(followStatus, userIds) {
		setFollowLoading(userIds);
		const userid = parseInt(userIds, 10);
		const payloadData = {
			query: Query.userFollowUnfollow,
			variables: { isFollow: !followStatus, userId: [userid] }
		};
		dispatch(userFollowUnfollow(payloadData));
		const deepClone = JSON.parse(JSON.stringify(likeUsersLists));
		deepClone.map((element, i) => {
			if (element.user_id === userIds) {
				const newData = { ...JSON.parse(JSON.stringify(element)) };
				newData.is_follow = !followStatus;
				deepClone[i] = newData;
			}
			return true;
		});
		setLikeUsersLists(deepClone);
		setOpenDialog(false);
	}

	function closeComposeDialog() {
		dispatch(closeLikesListDialog());
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				maxWidth="xs"
			>
				<AppBar position="static" elevation={0}>
					<Toolbar className="flex w-full justify-between">
						<Typography variant="subtitle1" color="inherit">
							Likes
						</Typography>
						<Button
							className="float-right bg-transparent rounded p-0 w-auto min-w-0"
							variant="contained"
							color="primary"
							type="button"
							onClick={() => {
								dispatch(closeLikesListDialog());
							}}
						>
							<Icon>close</Icon>
						</Button>
					</Toolbar>
				</AppBar>

				<DialogContent
					classes={{ root: 'p-16' }}
					className=""
					// onScroll={onScroll}
					ref={listInnerRef}
					style={{ height: '35vh', overflowY: 'auto' }}
				>
					{likeUsersLists &&
						likeUsersLists.length > 0 &&
						likeUsersLists.map((likeUser, index) => (
							<div
								className="flex items-center mt-6 mx-0 ng-star-inserted mb-16  justify-between"
								key={index}
							>
								<div className="flex items-center">
									<Avatar
										name={`${likeUser?.full_name}`}
										onClick={() => redirectProfileRoutes(likeUser?.user_id)}
										className="mr-12 cursor-pointer capitalize"
										size="45px"
										alt="profile_image"
										round
										src={likeUser?.thumb}
									/>
									<div>
										<div className="mb-3">
											<Typography
												onClick={() => redirectProfileRoutes(likeUser?.user_id)}
												className="font-semibold md:text-14 leading-none mr-20  cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis capitalize"
												style={{ width: '200px' }}
											>
												{likeUser?.full_name}
											</Typography>
										</div>
										<div>
											<span className="text-grey-500">
												{likeUser?.username ? `@${likeUser?.username}` : ''}
											</span>
										</div>
									</div>
								</div>
								{loginUserData?.s2_user_type?.name !== 'admin' && (
									<>
										{parseInt(likeUser.user_id, 10) !== parseInt(loginUserData.id, 10) && (
											<button
												className={`whitespace-nowrap rounded border-1 border-indigo-500 py-8 text-center  font-semibold w-96 ${
													likeUser?.is_follow
														? 'bg-white-500 text-indigo'
														: 'bg-indigo-500 text-white'
												}`}
												color="primary"
												type="submit"
												onClick={
													likeUser.is_follow
														? () => {
																setOpenDialog(true);
																setFollowingUserId(likeUser.user_id);
																setFollowingUserStatus(likeUser.is_follow);
																setFollowingUserName(likeUser?.username);
														  }
														: () => {
																handleFollowUnfollow(
																	likeUser.is_follow,
																	likeUser.user_id
																);
														  }
												}
												aria-label="Following"
											>
												{likeUser?.is_requested
													? 'Requested'
													: likeUser?.is_follow
													? 'Following'
													: 'Follow'}
											</button>
										)}
									</>
								)}
							</div>
						))}
					{isFetching && (
						<div className="loader mt-20 ml-6 block text-center mx-auto">
							<CircularProgress className="mt-30 ml-30" size={30} disableShrink />
						</div>
					)}
				</DialogContent>
			</Dialog>
			{openDialog && (
				<Dialog
					open={openDialog}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') {
							setOpenDialog(false);
						}
					}}
					aria-labelledby="followers"
					aria-describedby="followers-description"
				>
					{/* <DialogTitle id="alert-dialog-title">Follow</DialogTitle> */}
					<DialogContent>
						<DialogContentText id="alert-dialog-description" className="text-15 text-black text-center">
							Are you sure you want to Unfollow
							<span className="block">
								<strong className="text-indigo-500">@{followingUserName}</strong> ?
							</span>
						</DialogContentText>
					</DialogContent>
					<DialogActions className="text-center flex pb-20 px-20">
						<Button
							onClick={() => {
								setOpenDialog(false);
							}}
							className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
							style={{ border: '1px solid indigo' }}
							color="primary"
						>
							No
						</Button>
						<Button
							onClick={() => {
								handleFollowUnfollow(followUseringStatus, followingUserId);
							}}
							className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
							color="primary"
							autoFocus
						>
							Unfollow
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}

export default withReducer('commonReducer', reducer)(LikesListDialog);
