const getTeamDetailsWithBotList = `
query {
	getTeamDetailsWithBotList(teamId: 4) {
	  message
	  status
	  data {
		id
		response {
		  allBotDetails {
			name
			banner_url
			id
			short_description
			thumb
		  }
		  feturedBotDetails {
			name
			banner_url
			id
			short_description
			thumb
		  }
		  teamDetails {
			banner_image
			id
			name
			thumb
			name
			description
		  }
		}
	  }
	}
  }
  `;

const addTeam = `
mutation addTeam($image: Upload,$teamName:String!, $description:String, $shortDescription:String, $memberId: [Int!]!){
	addTeam(image: $image, teamName:$teamName, description:$description, shortDescription:$shortDescription, memberId: $memberId)
	{
	 message
	  status
	}
  }
`;

const updateTeam = `
  mutation updateTeam($image: Upload,$bannerImage:Upload,$description:String!,
	$shortDes:String!
	$teamName: String!,$id:Int!) {
	updateTeam(
	  description:$description
	  shortDes: $shortDes
	  teamName: $teamName
	  id: $id
	  image:$image,
	  bannerImage:$bannerImage
	)
	
	{
	message
	status
  }
  }
  
  `;

const getTeamDetailsById = `query getTeamDetailsById($teamId:Int!){
	getTeamDetailsById(teamId:$teamId){
    message
	status
     data{
		id
		name
		thumb
		image
		banner_image
		short_description
		id_s2_team_types
		description
		invites
		s2_users{
		  id
		  profilephoto
		  thumb
		  firstname
		  lastname
		  emailaddress
		  id_s2_user_type
		  username
		}
    }
  }}
  `;
const deleteTeam = `
 mutation deleteTeam($teamId:Int!) {
	deleteTeam(teamId:$teamId){
	  message
	  status
	}
	}
  `;
const getTeamMembers = `query getTeamMembers($teamId:Int!){
	getTeamMembers(teamId:$teamId){
	message
  	status
  	data{
		s2_users{
			id
			firstname
			lastname
			emailaddress
		}
		request_status
		s2_team_roles{
			role
		}
  	}
	}
  }`;

const removeTeamMember = `mutation removeTeamMember($teamId:Int!,$userId:Int!){
	removeTeamMember(teamId:$teamId,userId:$userId)
	{
	  message
	  status
	}
  }
  `;

const teamQuery = {
	updateTeam,
	getTeamDetailsWithBotList,
	addTeam,
	getTeamDetailsById,
	deleteTeam,
	getTeamMembers,
	removeTeamMember
};

export default teamQuery;
