import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const UserProfileConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/user/:id',
			component: lazy(() => import('./UserProfile'))
		},
		{
			path: '/profile/:name',
			component: lazy(() => import('./UserProfile'))
		}
	]
};

export default UserProfileConfig;
