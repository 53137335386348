import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'mybots', en);
i18next.addResourceBundle('tr', 'mybots', tr);

const MyBotsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	auth: 'bot_developer',
	routes: [
		{
			path: '/my-bots/:teamId',
			component: lazy(() => import('./MyTeamBot'))
		},
		{
			path: '/my-bots-detail/:botId',
			component: lazy(() => import('./MyBotsDetail'))
		},
		{
			path: '/my-bots',
			component: lazy(() => import('./MyBots'))
		},
		{
			path: '/my-bots',
			component: () => <Redirect to="/my-bots" />
		}
	]
};

export default MyBotsConfig;
