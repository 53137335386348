import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Query from 'app/main/query/user';
import Icon from '@material-ui/core/Icon';
import _ from '@lodash';
import { closeDeleteAccountDialog, accountDeactivateAndDeleteById, logoutUser } from 'app/auth/store/userSlice';
import { useSnackbar } from 'notistack';

function BlockAccountsDialog() {
	const dispatch = useDispatch();

	const appDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.deleteAccountDialog);

	const [openAccountConfirmDialog, setOpenAccountConfirmDialog] = useState(false);
	const [deleteAccoutType, setDeleteAccoutType] = useState('');
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	function handleAccountPrivacy(toggle) {
		const payload = {
			query: Query.accountDeactivateAndDeleteById,
			variables: {
				type: deleteAccoutType
			}
		};
		dispatch(accountDeactivateAndDeleteById(payload));
		setOpenAccountConfirmDialog(false);
		enqueueSnackbar('Account deactivated successfully', {
			variant: 'success',
			autoHideDuration: 3000
		});
		setTimeout(() => {
			dispatch(logoutUser());
		}, 800);
	}

	function closeComposeDialog() {
		dispatch(closeDeleteAccountDialog());
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				style={{ maxWidth: '360px', margin: '0 auto' }}
				maxWidth="xs"
			>
				<AppBar position="static" elevation={0}>
					<Toolbar className="flex w-full justify-between">
						<Typography variant="subtitle1" color="inherit">
							Delete Account
						</Typography>
						<Button
							className="float-right bg-transparent rounded p-0 w-auto min-w-0"
							variant="contained"
							color="primary"
							type="button"
							onClick={() => {
								dispatch(closeDeleteAccountDialog());
							}}
						>
							<Icon>close</Icon>
						</Button>
					</Toolbar>
				</AppBar>
				<DialogContent classes={{ root: 'p-16' }} className="" style={{ height: '25vh', overflowY: 'auto' }}>
					<div className="mt-6 mx-0 ng-star-inserted justify-between">
						<div
							className="items-center flex bg-white rounded-8 px-0 mb-12 border-b border-grey-200 cursor-pointer justify-between "
							style={{ height: '38px' }}
							onClick={() => {
								setOpenAccountConfirmDialog(true);
								setDeleteAccoutType('temporary_deleted');
							}}
							aria-hidden="true"
						>
							<span className="font-semibold text-sm text-black md:tracking-wide ml-6">
								Temporarily Deactivate
							</span>
							<Icon color="action" className="text-grey-400">
								navigate_next
							</Icon>
						</div>
						<div
							className="items-center flex bg-white rounded-8 px-0 mb-12 border-b border-grey-200 cursor-pointer justify-between"
							onClick={() => {
								setOpenAccountConfirmDialog(true);
								setDeleteAccoutType('deactivate');
							}}
							aria-hidden="true"
							style={{ height: '38px' }}
						>
							<span className="font-semibold text-sm text-black md:tracking-wide ml-6">
								Delete Account
							</span>
							<Icon color="action" className="text-grey-400">
								navigate_next
							</Icon>
						</div>
					</div>
				</DialogContent>
			</Dialog>
			<Dialog
				open={openAccountConfirmDialog}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						setOpenAccountConfirmDialog(false);
					}
				}}
				aria-labelledby="Account-confimation"
				aria-describedby="Account-confimation-description"
			>
				<DialogTitle id="alert-dialog-title">
					{deleteAccoutType === 'temporary_deleted'
						? 'Do you want Temporarily Deactivate ?'
						: 'Permanently Delete'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						id="alert-dialog-description"
						className="text-13 text-black text-left"
						style={{ maxWidth: '300px' }}
					>
						{deleteAccoutType === 'temporary_deleted' ? (
							<div className="public">
								<div className="mb-10 flex items-center">
									If you temporarily deactivate your account, your profile, photos, comments and likes
									will be hidden until you reactivate it by logging back in.
								</div>
							</div>
						) : (
							<div className="private">
								<div className="mb-10 flex items-center">
									When you delete your account, your Profile, photos, videos, comments, likes and
									followers will be permanently removed. If you'd just like to take a break, you can
									go for Temporarily Deactivated instead.
								</div>
								<div className="mb-10 flex items-center">
									After 30 days of your account deletion request, your account and all your
									information will be permanently deleted, and you won't be able to retrieve your
									information. You can cancel your request of deleting your account if you login your
									account within these 30 days .
								</div>
							</div>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions className="text-center flex pb-20 px-20">
					<Button
						onClick={() => {
							setOpenAccountConfirmDialog(false);
						}}
						className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
						style={{ border: '1px solid indigo' }}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							handleAccountPrivacy(setDeleteAccoutType);
						}}
						className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
						color="primary"
						autoFocus
					>
						{deleteAccoutType === 'temporary_deleted' ? 'Deactivate' : 'Delete'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default BlockAccountsDialog;
