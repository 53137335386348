import { lazy } from 'react';
import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'bots-analytics', en);
i18next.addResourceBundle('tr', 'bots-analytics', tr);

const MyBotsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	auth: 'bot_developer',
	routes: [
		{
			path: '/bots-analytics/:botAnalyticsId',
			component: lazy(() => import('./BotsAnalyticsDetail'))
		},
		{
			path: '/bots-analytics',
			component: lazy(() => import('./BotsAnalytics'))
		},
		{
			path: '/bots-analytics',
			component: () => <Redirect to="/bots-analytics" />
		}
	]
};

export default MyBotsConfig;
