import { lazy } from 'react';

const BugReportConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/bug-report',
			component: lazy(() => import('./BugReport'))
		}
	]
};

export default BugReportConfig;
