import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const PolicyConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/policy/policy-type',
			component: lazy(() => import('./PolicyType'))
		},
		{
			path: '/admin/policy/privacy-policy',
			component: lazy(() => import('./PrivacyPolicy'))
		},
		{
			path: '/admin/policy',
			component: () => <Redirect to="/admin/policy/policy-type" />
		}
	]
};

export default PolicyConfig;
