import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import newsQuery from 'app/main/query/news';
import reducer from './store';
import { getNews, resetDashboard } from './store/dashboardSlice';
import { checkVerifyEmail } from '../mybots/store/myBotsSlice';
import { openNewTeamsDialog } from '../teams/store/teamsSlice';
import TeamsDialog from '../teams/TeamsDialog';
import MyBotsDialog from '../mybots/MyBotsDialog';

const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));

function DashboardPage() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const appDialogTeam = useSelector(({ teamsReducer }) => teamsReducer.myteams.teamsDialog);
	const appDialogBot = useSelector(({ myBotsReducer }) => myBotsReducer?.mybots?.myBotsDialog);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		// dispatch(getNotification());
		const payload = {
			query: newsQuery.getAllNewsDetails
		};
		dispatch(getNews(payload));

		return () => {
			dispatch(resetDashboard());
		};
	}, [dispatch]);

	return (
		<FusePageSimple
			content={
				<div className="p-24">
					<div className="md:p-24 dashbord-box">
						<div className="grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-24 w-full min-w-0">
							<motion.div
								initial={{ opacity: 0, scale: 0.6 }}
								animate={{ opacity: 1, scale: 1 }}
								className="w-full max-w-320 shadow bg-white rounded-lg p-12 gap-24"
							>
								<div className="items-center justify-center">
									<Link to="/my-bots" className="flex items-center">
										<img
											className="max-w-52 max-h-52"
											src="assets/images/social/bots.png"
											alt="logo"
										/>
										<Typography
											variant="subtitle1"
											className="ml-16 font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
										>
											My Bots
										</Typography>
									</Link>
								</div>
							</motion.div>
							<motion.div
								initial={{ opacity: 0, scale: 0.6 }}
								animate={{ opacity: 1, scale: 1 }}
								className="w-full max-w-320 shadow bg-white rounded-lg p-12"
							>
								<div className="items-center justify-center">
									<Link to="/teams" className="flex items-center">
										<img className="max-h-52" src="assets/images/social/teams.png" alt="logo" />
										<Typography
											variant="subtitle1"
											className="ml-16 font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
										>
											Teams
										</Typography>
									</Link>
								</div>
							</motion.div>

							<motion.div
								initial={{ opacity: 0, scale: 0.6 }}
								animate={{ opacity: 1, scale: 1 }}
								className="w-full max-w-320 shadow bg-white rounded-lg p-12"
							>
								<div className="items-center justify-center">
									<Link to="/agreements" className="flex items-center">
										<img
											className="max-h-52"
											src="assets/images/social/agreements.png"
											alt="logo"
										/>
										<Typography
											variant="subtitle1"
											className="ml-16 font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
										>
											Agreements
										</Typography>
									</Link>
								</div>
							</motion.div>
							<motion.div
								initial={{ opacity: 0, scale: 0.6 }}
								animate={{ opacity: 1, scale: 1 }}
								className="w-full max-w-320 shadow bg-white rounded-lg p-12 gap-24"
							>
								<div className="items-center justify-center">
									<Link
										to="/developers/docs"
										className="flex items-center cursor-pointer"
										target="_blank"
										role="button"
									>
										<img
											className="max-w-52 max-h-52"
											src="assets/images/social/analytics.png"
											alt="logo"
										/>
										<Typography
											variant="subtitle1"
											className="ml-16 font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
										>
											Documentation
										</Typography>
									</Link>
								</div>
							</motion.div>
						</div>
						<div className="bg-white shadow rounded-8 mt-48">
							<div className="px-28 py-6 relative overflow-hidden bot-doc-part flex items-center justify-between">
								<div className="relative z-40 w-1/2">
									<Typography
										component={motion.span}
										initial={{ x: -20 }}
										animate={{ x: 0, transition: { delay: 0.2 } }}
										delay={300}
										className="text-16 text-grey-700 md:text-24 font-semibold "
									>
										Develop a bot from scratch: a complete step-by-step Guide for building your own
										bot
									</Typography>
									<Typography
										component={motion.span}
										initial={{ x: -20 }}
										animate={{ x: 0, transition: { delay: 0.2 } }}
										delay={300}
										className="text-14 text-grey-700 block mt-16 mb-16 leading-relaxed"
									>
										The step-by-step guide in this documentation is designed to take developers
										through the process of bot development, with each step building on the previous
										one. The guide is supported with code examples, demos, and best practices to
										ensure developers have a clear understanding of each step.
									</Typography>
									<motion.div
										initial={{ opacity: 0, x: 20 }}
										animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
									>
										<Button
											className="whitespace-nowrap rounded-8 bg-amber-500 h-36 hover:bg-black hover:text-white"
											variant="contained"
											onClick={() => window.open('/developers/docs', '_blank')}
										>
											<span className="text-14">Get Started</span>
										</Button>
									</motion.div>
								</div>
								<div className="relative z-40 w-1/2">
									<img
										src="assets/images/bot/bot_documentations.png"
										className="w-auto h-auto float-right max-h-fit"
										alt="bot_icon"
									/>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-40 mt-48">
							<div className="bg-white shadow rounded-8">
								<div className="p-28 relative overflow-hidden">
									<div className="relative z-40 ">
										<Typography
											component={motion.span}
											initial={{ x: -20 }}
											animate={{ x: 0, transition: { delay: 0.2 } }}
											delay={300}
											className="text-16 text-grey-700 md:text-24 font-semibold"
										>
											Create a Bot
										</Typography>
										<Typography
											component={motion.span}
											initial={{ x: -20 }}
											animate={{ x: 0, transition: { delay: 0.2 } }}
											delay={300}
											className="text-12 text-grey-700 block max-w-xs mt-16 mb-16"
										>
											Create your bot in just few steps, and publish it in the app.
										</Typography>
										<motion.div
											initial={{ opacity: 0, x: 20 }}
											animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
										>
											<Button
												className="whitespace-nowrap rounded-8 bg-amber-500 h-36 hover:bg-black hover:text-white"
												variant="contained"
												onClick={() => {
													dispatch(checkVerifyEmail());
												}}
											>
												<span className="text-14">Create Bot</span>
											</Button>
										</motion.div>
									</div>
									<span className="bot-dashbord absolute right-48 top-0">
										<img
											src="assets/images/icon/bot_image.png"
											className="relative z-30 mt-32"
											style={{ width: '130px', height: '130px' }}
											alt="bot_icon"
										/>
									</span>
								</div>
							</div>
							<div className="bg-white shadow rounded-8">
								<div className="p-28 relative overflow-hidden">
									<div className="relative z-40 ">
										<Typography
											component={motion.span}
											initial={{ x: -20 }}
											animate={{ x: 0, transition: { delay: 0.2 } }}
											delay={300}
											className="text-16 text-grey-700 md:text-24 font-semibold"
										>
											Create a Team
										</Typography>
										<Typography
											component={motion.span}
											initial={{ x: -20 }}
											animate={{ x: 0, transition: { delay: 0.2 } }}
											delay={300}
											className="text-12 text-grey-700 block max-w-xs mt-16 mb-16"
										>
											Create your team and build the best bots with your team members.
										</Typography>
										<motion.div
											initial={{ opacity: 0, x: 20 }}
											animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
										>
											<Button
												className="whitespace-nowrap rounded-8 bg-amber-500 h-36 hover:bg-black hover:text-white"
												variant="contained"
												onClick={() => {
													dispatch(openNewTeamsDialog());
												}}
											>
												<span className="text-14">Create Team</span>
											</Button>
										</motion.div>
									</div>
									<span className="bot-dashbord absolute right-48 top-0">
										<img
											src="assets/images/icon/team_image.png"
											className="relative z-30 mt-32"
											style={{ width: '130px', height: '130px' }}
											alt="bot_icon"
										/>
									</span>
								</div>
							</div>
						</div>
					</div>
					{appDialogTeam?.props?.open && <TeamsDialog prop="dashboard" />}
					{appDialogBot?.props?.open && <MyBotsDialog prop="dashboard" />}
				</div>
			}
		/>
	);
}

export default withReducer('dashboardReducer', reducer)(DashboardPage);
