import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import userService from 'app/services/userService';
import { Component } from 'react';
import history from '@history';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import { useLocation } from 'react-router-dom';
import { setUserData, logoutUser } from 'app/auth/store/userSlice';
import Query from 'app/main/query/user';

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		return Promise.all([
			// Comment the lines which you do not use
			this.jwtCheck()
		]).then(() => {
			this.setState({ waitAuthCheck: false });
		});
	}

	jwtCheck = () =>
		new Promise(resolve => {
			userService.on('onAutoLogin', () => {
				const accessToken = window.localStorage.getItem('jwt_access_token');
				const payload = {
					query: Query.verifyToken,
					variables: { token: accessToken }
				};
				// this.props.showMessage({ message: 'Logging in with JWT' });

				/**
				 * Sign in and retrieve user data from Api
				 */
				userService
					.signInWithToken()
					.then(user => {
						this.props.setUserData(user);
						resolve();
						if (
							user &&
							user.s2_user_type &&
							user.s2_user_type.name === 'bot_developer' &&
							((history && history.location.pathname === '/') || history.location.pathname === '/login')
						) {
							history.push('/dashboard');
						} else if (
							user &&
							user.s2_user_type &&
							user.s2_user_type.name === 'admin' &&
							((history && history.location.pathname === '/') || history.location.pathname === '/login')
						) {
							history.push('/admin/admin-dashboard');
						} else if (
							user &&
							user.s2_user_type &&
							user.s2_user_type.name === 'app_user' &&
							((history && history.location.pathname === '/') || history.location.pathname === '/login')
						) {
							history.push('/application-user/dashboard');
						}
					})
					.catch(error => {
						this.props.showMessage({ message: error.message, variant: 'error' });
						resolve();
					});
			});

			userService.on('onAutoLogout', message => {
				if (message) {
					history.push('/login');
					this.props.showMessage({ message, variant: 'error' });
				}
				this.props.logout();
				resolve();
			});

			userService.on('onNoAccessToken', () => {
				if (
					history &&
					(history.location.pathname.match('/') ||
						history.location.pathname.match('/home') ||
						history.location.pathname.match('/developers/docs') ||
						history.location.pathname.match('/verifyEmail') ||
						history.location.pathname.match('/reset-password') ||
						history.location.pathname.match('/register'))
				) {
					resolve();
				} else {
					history.push('/login');
					resolve();
				}
			});

			userService.init();

			return Promise.resolve();
		});

	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			showMessage,
			hideMessage
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
