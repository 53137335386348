import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const PostConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/post/post-categories',
			component: lazy(() => import('./PostCategories'))
		},
		{
			path: '/admin/post/post-subcategories',
			component: lazy(() => import('./PostSubCategories'))
		},
		{
			path: '/admin/post/user-post-report',
			component: lazy(() => import('./UserPostReport'))
		},
		{
			path: '/admin/post',
			component: () => <Redirect to="/admin/post/post-categories" />
		}
	]
};

export default PostConfig;
