import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import history from '@history';
import clsx from 'clsx';
import userService from '../../services/userService';

const loginUserData = userService.getUserData();

const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .react-badge, & .logo-text': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}
	},
	reactBadge: {
		backgroundColor: '#121212',
		color: '#61DAFB'
	}
}));

function redirectLink() {
	if (loginUserData?.s2_user_type?.name === 'admin') {
		history.push('/admin/admin-dashboard');
	} else if (loginUserData?.s2_user_type?.name === 'app_user') {
		history.push('/application-user/dashboard');
	} else {
		history.push('/dashboard');
	}
}

function Logo() {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'flex items-center')}>
			{/* <img className="logo-icon w-24 h-24" src="assets/images/logos/fuse.svg" alt="logo" /> */}
			<Typography
				className="text-24 leading-none mx-12 font-medium logo-text cursor-pointer"
				color="inherit"
				onClick={() => {
					redirectLink();
				}}
			>
				Stockly
			</Typography>
			<div className={clsx(classes.reactBadge, 'react-badge flex items-center rounded')}>
				<Typography
					onClick={() => {
						redirectLink();
					}}
				>
					<img
						className="react-logo cursor-pointer"
						src="/assets/images/logos/logo.png"
						alt="stockly"
						width="32"
					/>
				</Typography>
				{/* <span className="react-text text-12 mx-4">React</span> */}
			</div>
		</div>
	);
}

export default Logo;
