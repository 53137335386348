const getAvailableCountry = ` 
	query {
		getAvailableCountry {
			message
			status
			data {
				name
				code
				currency
				region
				subregion
				calling_code
				supported
				id
			}
		}
	}
`;

const countryQuery = { getAvailableCountry };

export default countryQuery;
