import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import userService from 'app/services/userService';
import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_OTHER_ENDPOINT
});

export const getNotification = createAsyncThunk('dashboard/getNotification', async (data, { getState, dispatch }) => {
	// instance.defaults.headers.common.Authorization = `${userService.getAccessToken() || null}`;
	try {
		const response = await axios.get('/api/getInfoNotification', { ...data });
		const result = await response.data.data.getAllNewsDetails;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getNews = createAsyncThunk('dashboard/getNews', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: {},
	reducers: {
		resetDashboard: (state, action) => {
			state.notificationData = {};
			state.newsData = {};
		}
	},
	extraReducers: {
		[getNotification.fulfilled]: (state, action) => ({
			...state,
			notificationData: action.payload
		}),
		[getNews.fulfilled]: (state, action) => ({
			...state,
			newsData: action.payload
		})
	}
});

export const { resetDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
