import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const NotificationTypeConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/notification-type',
			component: lazy(() => import('./NotificationType'))
		},
		{
			path: '/admin/notification-type',
			component: () => <Redirect to="/admin/notification-type" />
		}
	]
};

export default NotificationTypeConfig;
