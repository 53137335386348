import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'teams', en);
i18next.addResourceBundle('tr', 'teams', tr);

const TeamsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	auth: 'bot_developer',
	routes: [
		{
			path: '/team-detail/:teamId',
			component: lazy(() => import('./TeamsDetail'))
		},
		{
			path: '/teams',
			component: lazy(() => import('./Teams'))
		},
		{
			path: '/teams',
			component: () => <Redirect to="/teams" />
		}
	]
};

export default TeamsConfig;
