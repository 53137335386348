import Resizer from 'react-image-file-resizer';

export const botDefaultImage = 'https://stockly-public.s3.amazonaws.com/default-image/default-bot.png';
export const profileDefaultImage = '/assets/images/avatars/profile.jpg';
export const teamDefaultImage = '/assets/images/avatars/team.png';
export const botPermissionDefaultImage = '/assets/images/avatars/botPermissionDefault.png';

export const capitalize = str => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const resizeImage = file => {
	let quality = 100;
	// 4MB image file
	if (file.size > 4000000) {
		quality = 90;
	}
	// 8MB image file
	if (file.size > 8000000) {
		quality = 85;
	}
	return new Promise(resolve => {
		Resizer.imageFileResizer(
			file,
			100,
			100,
			'png',
			quality,
			0,
			uri => {
				resolve(uri);
			},
			'file'
		);
	});
};

export const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
	const bottomBorder = (scrollHeight - clientHeight) / 2;
	return bottomBorder < scrollTop;
};
