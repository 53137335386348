import { lazy } from 'react';

const FeedbackConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/feedback/feedback-detail/:feedbackId',
			component: lazy(() => import('./FeedbackDetails'))
		},
		{
			path: '/admin/feedback',
			component: lazy(() => import('./Feedback'))
		}
	]
};

export default FeedbackConfig;
