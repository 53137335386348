const getBotDetailsById = `
	query ($botId: Int!) {
		getBotDetailsById(botId: $botId) {
			status
			message
			data {
				id
				response {
					user_id
					name
					thumb
					image
					bot_image
					id
					cts
					bot_type
					bot_name
					bot_price
					bot_categories
					video_link
					description
					short_description
					team_name
					first_published_date
					current_version
					last_update_bot
					privacy_url
					bot_token
					terms_url
					id_s2_team_types
					s2_bot_status{
						status
						id
					}
					id_s2_countries
					id_s2_bot_status
					copy_right_name
					license_agreement_url
					team_id
					creator_name
					is_personal_team
					is_subscribed
					bot_featured_images
					banner_url
					website_link
					is_bot_user_rating
					past_subscribed
					sharable_link
					id_s2_team
					handler
					copy_right_name
					language
				}
			}
		}
	}
`;

const getBotRatingOverView = `
	query ($botId: Int!) {
		getBotRatingOverView(data: { botId: $botId }) {
			id
			response {
				rating_total_count
				five_star_percentage
				one_star_percentage
				rating_total_user_count
				two_star_percentage
				three_star_percentage
				four_star_percentage
				avg_out_five
			}
		}
	}
`;

const getBotsWithStatus = `
	query ($take: Int!, $skip: Int!) {
		getBotsWithStatus(data: { take: $take, skip: $skip }) {
			id
			cts
			response {
				bot_id
				dev_user_id
				handler
				bot_name
				bot_thumb
				user_subscription_status
			}
		}
	}
`;

const subscribeBot = `
	query ($id: Int!) {
		subscribeBot(id: $id) {
			message
		}
	}
`;

const unSubscribeByBotId = `
	query ($id: Int!) {
		unSubscribeByBotId(id: $id) {
			message
		}
	}
`;

const addOrEditBotReview = `
	query ($id: Int!, $review: String!, $stars: Int!, $reviewId: Int) {
		addOrEditBotReview(id: $id, review: $review, stars: $stars, reviewId: $reviewId) {
			message
		}
	}
`;

const getBotRatingReview = `query getBotRatingReview($botId: Int, $pageNumber: Int, $pageSize: Int,$searchText : String,$sortBy: String,$sortOrder: OrderDirection) {
	getBotRatingReview(botId: $botId, pageNumber: $pageNumber, pageSize: $pageSize , searchText:$searchText, sortBy:$sortBy,sortOrder:$sortOrder) {
	  status
	  message
	  total_count
	  response {
		user_id
		profilephoto
		fullname
		id
		review
		stars
	  }
	  loginUserReview {
		user_id
		profilephoto
		fullname
		id
		review
		stars
	  }
	}
  }
  `;

const getBotDeveloperUsingBotId = `
	query ($botId: Int!) {
		getBotDeveloperUsingBotId(data: { botId: $botId }) {
			website
			s2_team {
				s2_users {
					id
					firstname
					lastname
				}
			}
		}
	}
`;
const getBotsByTeamId = `

query getBotsByTeamId($pageNumber:Int, $pageSize: Int, $teamId:Int!, $sortBy: String, $sortOrder: OrderDirection) {
	getBotsByTeamId(pageNumber:$pageNumber, pageSize: $pageSize, teamId:$teamId, sortBy:$sortBy,sortOrder:$sortOrder) {
		status
		message
		total_count
		data {
				id
				response {
					subscription
					bot_id
					bot_name
					bot_image
					bot_thumb_image
					bot_short_description
					description
					total_bot_count
					is_subscribed
				}
			}
		}
	}
`;

const getBotDetailsByUserId = `
	query ($botId: Int!) {
		getBotDetailsByUserId(botId: $botId) {
			status
			message
			data {
				id
				response {
					name
					thumb
					image
					id
					bot_type
					bot_price
					bot_categories
					video_link
					description
					short_description
					team_name
					first_published_date
					current_version
					last_update_bot
					privacy_url
					terms_url
					copy_right_name
					license_agreement_url
					team_id
					creator_name
					is_personal_team
					is_subscribed
					bot_featured_images
					banner_url
					website_link
					is_bot_user_rating
					past_subscribed
					sharable_link
					id_s2_team
					handler
					copy_right_name
					language
				}
			}
		}
	}
`;
const getBotByUserId = `query ($botCategoryId: Int, $searchText:String,$pageNumber:Int,$pageSize:Int, $ownedByMe:Boolean,$teamId: Int){
	getBotByUserId(botCategoryId:$botCategoryId, searchText:$searchText,pageNumber:$pageNumber,pageSize:$pageSize,ownedByMe:$ownedByMe,teamId: $teamId){
     message
     status
     total_count
		data{
		id
		name
		id_s2_team_types
			s2_bot_status {
				id
				status
			}
			id
			s2_bot_banner_images {
				id
				image_url
			}
			s2_team{
				name	
			}
		     s2_bot_type {
				type
			}		  
			copyright_url
			bot_image
			description
			bot_token
			handler
			image
			name
			short_description
			thumb
			website
		}
		
	}
}  
  `;

const checkNameHandlerExist = `query checkNameHandlerExist($botName:String, $searchHandler:String) {
	checkNameHandlerExist(botName:$botName, searchHandler:$searchHandler) {
		type
		isAvailable
		message
		status
	}
}
`;

const addBot = `mutation addBot($botUserName: String, $image: Upload!, $featuredImage: [Upload!]!, $handler:String!, $name:String!, $password: String, $description: String!, $idS2BotType: Int!, $idS2BotStatus: Int!, $teamId: Int, $botCategoryId: Int, $shortDescription: String!, $addPersonalTeamName: String,$countryCode: [Int!]!, $permissionId: [Int!]!){
	addBot(botUserName:$botUserName, image:$image,featuredImage:$featuredImage, handler:$handler, name:$name, password: $password, description: $description, idS2BotType: $idS2BotType, idS2BotStatus: $idS2BotStatus, teamId: $teamId, botCategoryId: $botCategoryId, shortDescription: $shortDescription, addPersonalTeamName: $addPersonalTeamName, countryCode:$countryCode, permissionId:$permissionId){
	message
	status
	data {
		bot_matrix_detail {
		  access_token
		  user_id
		  home_server_url
		}
		bot_space_room_id
	  }
	}
}`;

const getBotDetailsByBotId = `query getBotDetailsById($botId: Int!) {
	getBotDetailsById(botId: $botId) {
	  status
	  message
	  data {
		s2_bot{
			s2_users{
				username
			}
			s2_bot_permission_mapping{
				id_s2_permission
			}
			id_s2_bot_categories
		}
		response {  
		  bot_rejected_reason
		  bot_categories
		  user_id
		  copyright_url
		  description
		  bot_token
		  bot_price
		  handler
		  bot_image
		  bot_user_name
		  featuredImages {
			id
			image
		  }
		  bannerImages {
			id
			image_url
		  }
		  bot_name
		  short_description
		  bot_thumb
		  website
		  team_id
		  team_name
		  terms_url
		  privacy_url
		  license_agreement_url
		  s2_bot_status {
			id
			status
		  }
		  s2_bot_permission_mapping {
			id_s2_permission
			s2_bot_permission {
			  name
			  description
			  image
			}
		   }
	
		  id_s2_team_types
		  id_s2_bot_status
		  id_s2_countries

		  id_s2_bot_type
		  bot_type          
		}
		s2_bot{
			id_s2_countries
		}
	  }
	}
  }`;

const getBotSubscribedUser = `query getBotSubscribedUser($botId:Int!,$search:String!,$pageNumber:Int!,$pageSize:Int!)
  {
	getBotSubscribedUser(botId:$botId,search:$search,pageNumber:$pageNumber,pageSize:$pageSize)
	{
		total_count
	  data{
		id
		 s2_users
		{
		  id
		  full_name
		  username
		  thumb
		  cts
		}
		
	  }
	  message
	  status
	}
  }
  `;
const getBotCommand = `query getBotCommand($botId:Int!,$searchText:String!,$pageNumber:Int!,$pageSize:Int!, $sortBy: String, $sortOrder: OrderDirection){
	getBotCommand(botId:$botId,searchText:$searchText,pageNumber:$pageNumber,pageSize:$pageSize,sortBy:$sortBy,sortOrder:$sortOrder){ 
		total_count
	  data{
		id
		id_s2_bot
		rank
		command
		description
	  }
	}
  }
  `;

const checkBotCommand = `query checkBotCommand($botId:Int!,$commandText:String!){
	checkBotCommand(botId:$botId,commandText:$commandText)
	{
	  data{
		id_s2_bot
		command
		description
	  }
	  message
	  status
	}
  }`;
const updateBotCommand = `
  query updateBotCommand($botId:Int!,$id:Int!,$command:String!,$shortDescription:String!){
	updateBotCommand(botId: $botId ,id:$id, command:$command,shortDescription:$shortDescription){
	  message
  status
	}
  }`;

const removeBotCommand = `query removeBotCommand($botId:Int!,$id:Int!){
	removeBotCommand(botId: $botId ,id:$id){
	  message
  status
	}
  }`;

const addBotCammand = `mutation addBotCommand($botId:Int!, $rank: Int, $command:String!, $description:String!){
	addBotCommand(botId:$botId, rank: $rank, command:$command, description: $description){
	  status
	  message
	 
	}
  }
  `;

const removeBot = `mutation removeBot($botId:Int!){
	removeBot(botId:$botId){
	  message
  status
	}
  }
  `;

const getBotAnalysisChart = `query getBotAnalysisChart($botId:Int!,$commandText:String!){
	getBotAnalysisChart(botId:$botId,commandText:$commandText){
	  message
  status
	  data{
		chart_ts
  cts
  value
  s2_chart_duration_type{
	type
  }
	  }
	}
  }`;

const botStatus = `
  query {
	getBotStatus{
	message
  status
	  data{
		id
  status
	  }
	}
  }
  
  `;

const getBotStatusByAdmin = `query{
	checkBotStatusByAdmin
	{
	  data{
		id
		status
	  }
	  message
	  status
	}
  }`;

const botType = `
  query {
	getBotType{
	message
  status
	  data{
		id
  type
	  }
	}
  }`;
const changeBotTeam = `
  mutation changeBotTeam($botId: Int!,$teamId: Int! ) {
	changeBotTeam(botId: $botId,teamId: $teamId ){
		message
		status
	}
  }`;

const updateBot = `mutation updateBot(
	$botId:Int,
	$botCategoryId: Int,
	$botImage:Upload,
	$name: String!,
	$handler: String!,
	$image: String,
	$privacyUrl: String,
	$termsUrl: String,
	$copyrightUrl: String,
	$licenseAgreementUrl: String,
	$bannerUrl: String,
	$website: String,
	$shortDescription: String!,
	$description: String!,
	$idS2BotType: Int!,
	$idS2BotStatus: Int,
	$price: Int,
	$teamId: Int,
	$addPersonalTeamName: String,
	$countryCode: [Int!]!,
	$permissionId: [Int!]!
  ) {
	updateBot(
	  botId:$botId,
	  botCategoryId: $botCategoryId,
	  botImage: $botImage,
	  name: $name,
	  handler: $handler,
	  image: $image,
	  teamId: $teamId,
	  privacyUrl: $privacyUrl,
	  termsUrl: $termsUrl,
	  copyrightUrl: $copyrightUrl,
	  licenseAgreementUrl: $licenseAgreementUrl,
	  bannerUrl: $bannerUrl,
	  website: $website,
	  shortDescription: $shortDescription,
	  description: $description,
	  idS2BotType: $idS2BotType,
	  price: $price,
	  idS2BotStatus: $idS2BotStatus,
	  addPersonalTeamName: $addPersonalTeamName,
	  countryCode: $countryCode,
	  permissionId: $permissionId
	) {
	  message
	  status
	}
  }`;
const getBotsUserId = `query getBotsUserId($userId:Int,$name:String!,$pageNumber:Int!,$pageSize:Int!, $sortBy: String, $sortOrder: OrderDirection){
	getBotsUserId(userId:$userId , name:$name , pageNumber:$pageNumber,pageSize:$pageSize,sortBy:$sortBy,sortOrder:$sortOrder){
	  message
	  status
	  totalCount
	  data{
		total_bot_count
		id
	  name
  image
		copyright_url
  description
  handler
  thumb
  s2_bot_status{
	id
	status
  }
s2_bot_type{
type
}
	  }
	}
  }`;

const getBotDetailByStatus = `query getBotDetailByStatus($pageNumber:Int!,$pageSize:Int!,$botStatus:Int!,$name:String, $sortBy:String,$sortOrder:OrderDirection){
	getBotDetailByStatus(pageNumber:$pageNumber,pageSize:$pageSize,botStatus:$botStatus,name:$name,sortBy:$sortBy,sortOrder:$sortOrder){
	totalCount
		data {
			id
			cts
			name
			bot_image
			image
			s2_bot_status {
			  id
			  status
			}
			s2_bot_type {
			  type
			}
			s2_team {
			  name
			}
			reviewed
			is_bot_user_rating
		  }
		  message
		  status
	  
	}
  }`;

const changeReviewStatusByBotId = `mutation changeReviewStatusByBotId($botId:Int!){
	changeReviewStatusByBotId(botId:$botId){
		cts
		uts
  		message
  		status
	}
  }`;

const enableDisableBot = `query enableDisableBot($botStatusId:Int!, $botId:Int!){
	enableDisableBot(botStatusId:$botStatusId, botId:$botId){
  message
  status
	}
  }`;

const getAllBotReviews = `query getAllBotReviews($pageNumber: Int, $pageSize: Int,$searchText: String,$sortBy: String,$sortOrder: String){
	getAllBotReviews(pageNumber:$pageNumber,pageSize:$pageSize,searchText:$searchText,sortBy:$sortBy,sortOrder:$sortOrder){
		status
		message
		total_count
	data{
		  response {
		  user_id
		  profilephoto
		  fullname
		  uts
		  id
		  review
		  stars
		}
	  }
	}
	}`;

const getAllBotRatingOverView = `query getAllBotRatingOverView(
		$pageNumber: Int
		$pageSize: Int
		$search: String
		$sortBy: String
		$sortOrder: OrderDirection
	  ) {
		getAllBotRatingOverView(
		  pageNumber: $pageNumber
		  pageSize: $pageSize
		  search: $search
		  sortBy: $sortBy
		  sortOrder: $sortOrder
		) {
		  total_count
		  data {
			description
			name
			thumb
			id_s2_bot
			response {
			  stars
			  id
			  review
			  uts
			}
		  }
		}
	  }
	  `;

const getAllBotsByBotCategory = `query getAllBotsByBotCategory($pageNumber:Int, $pageSize:Int,$searchText: String,$sortBy:String,$sortOrder:OrderDirection){
		getAllBotsByBotCategory(pageNumber:$pageNumber ,pageSize:$pageSize,searchText:$searchText,sortBy:$sortBy,sortOrder:$sortOrder){
		 total_count
		 data{
			 id
		   category
	   _count{
		 s2_bot_categories_mappings
	   }	   
		   
		 }
		 message
		 status
	   }
	   }`;

const getAllBotsByCategoryId = `query getAllBotsByCategoryId($categoryId:Int!,$pageNumber:Int!,$pageSize:Int!,$searchText: String!,$sortBy:String,$sortOrder:OrderDirection){
		getAllBotsByCategoryId(categoryId:$categoryId,pageNumber:$pageNumber,pageSize:$pageSize,searchText:$searchText,sortBy:$sortBy,sortOrder:$sortOrder){
		  status
	  message
		  total_count
		  data{
			category
			id
			copyright_url
			description
			bot_token
			handler
			image
			name
			short_description
			thumb
			website
			cts
		  }
		}
	  }`;
const deleteBotByCategoryId = `mutation deleteBotByCategoryId($categoryId:Int!,$botId:Int!){
		deleteBotByCategoryId(categoryId:$categoryId,botId:$botId){
		  message
	  status
		}
}`;

const deleteBot = `mutation deleteBot($botId:Int!){
	deleteBot(botId:$botId){
	  message
  status
	}
}`;

const reGenerateBotToken = `mutation reGenerateBotToken($botId:Int!){
	reGenerateBotToken(botId:$botId){
		  message
	  status
		}
	  }`;

const getBotStoreCategoryType = `query getBotStoreCategoryType($pageNumber:Int ,$pageSize:Int, $searchText:String,$sortBy:String,$sortOrder:OrderDirection){
		getBotStoreCategoryType(pageNumber:$pageNumber ,pageSize:$pageSize,searchText:$searchText,sortBy:$sortBy,sortOrder:$sortOrder){
		total_count
		  data{
			cts
			  category
			  _count{
				s2_bot_categories_mappings
			  }
			id
		  }
		  message
		  status
		}
	  }`;
const uploadBannerImage = `mutation uploadBannerImage($botId: Int!, $bannerImage: Upload!) {
	uploadBannerImage(botId: $botId, bannerImage: $bannerImage) {
	  message
	  status
	}
  }`;
const removeBannerImage = ` mutation removeBannerImage($bannerId: Int, $botId: Int!) {
	removeBannerImage(bannerId: $bannerId, botId: $botId) {
	  status
	  message
	}
}`;
const uploadFeatureImage = `mutation uploadFeatureImage($botId: Int!, $featuredImage: Upload!) {
	uploadFeatureImage(botId: $botId, featuredImage: $featuredImage) {
	  message
	  status
	}
  }`;
const removeFeaturedImage = `mutation removeFeaturedImage($featuredImageId: Int, $botId: Int!) {
	removeFeaturedImage(featuredImageId: $featuredImageId, botId: $botId) {
	  status
	  message
	}
  }`;

const getTeamDetailsByTeamTypeId = `query getTeamDetailsByTeamTypeId($searchText: String, $userId: Int, $createdByMe: Boolean, $pageNumber: Int, $pageSize: Int) {
	getTeamDetailsByTeamTypeId(searchText: $searchText, userId: $userId, createdByMe: $createdByMe, pageNumber: $pageNumber, pageSize: $pageSize) {
	  status
	  message
	  totalCount
	  data{
	   s2_team{
	   id
        id_s2_team_types
        id_s2_team_status
        id_s2_users
        name
        image
        thumb
        short_description
        description
        banner_image
	   s2_users{
		firstname
		full_name
		lastname
	   }
		}
	  }
	}
}`;

const getBotTokenAndUserIdAndSpaceInfo = `query getBotTokenAndUserIdAndSpaceInfo($botId: Int!) {
	getBotTokenAndUserIdAndSpaceInfo(botId: $botId) {
		status
		message
		data{
		  client_id
		  bot_space_room_id
		  matrix_id
		  access_token
		  home_server_url
		}
	 
	}
}`;

const botLogin = `mutation botLogin($data: String!) {
	botLogin(data: $data) {
		status
		message
		data{
			access_token
		}	    
	}
}`;

const getBotNewAccessTokenByRefreshToken = `query getBotNewAccessTokenByRefreshToken($matrix_id: String!) {
	getBotNewAccessTokenByRefreshToken(matrix_id: $matrix_id) {
		status
		message
		data{
			access_token
		}	    
	}
}`;

const botResetPassword = `query botResetPassword($data: String!) {
	botResetPassword(data: $data) {
		status
		message	    
	}
}`;

const requestForVerification = `mutation requestForVerification($botId: Int!, $isCancel: Boolean!) {
	requestForVerification(botId: $botId, isCancel: $isCancel) {
		status
		message	    
	}
}`;

const requestForPublish = `
mutation publishBot($botId: Int!){
	publishBot(botId: $botId){
	  status
	  message
	}
  }`;

const acceptOrRejectReuqest = `mutation acceptOrRejectReuqest($botId: Int!, $isAccept: Boolean!, $reason: String) {
	acceptOrRejectReuqest(botId: $botId, isAccept: $isAccept, reason: $reason) {
		status
		message	    
	}
}`;

const getPermissionList = `query getPermissionList($activeInactiveUser: Boolean, $pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $sortOrder: OrderDirection) {
	getPermissionList(activeInactiveUser: $activeInactiveUser, pageNumber: $pageNumber, pageSize: $pageSize,searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder ) {
		status
		message	
		totalCount    
		data {
			id
			cts
			name
			status
			description
			image
		}	    
	}
}`;

const addPermissionList = `mutation addPermissionList($name: String!, $image: Upload!, $description: String!) {
	addPermissionList(name: $name, image: $image, description: $description) {
		status
		message	    
	}
}`;

const updatePermission = `mutation updatePermission($id: Int!, $image: Upload, $name: String!, $description: String) {
	updatePermission(id: $id, image: $image, name: $name, description: $description) {
		status
		message	    
	}
}`;

const activeDeactivePermission = `mutation activeDeactivePermission($id: Int!, $status: Boolean!) {
	activeDeactivePermission(id: $id, status: $status) {
		status
		message	    
	}
}`;

const inviteForBetaTesting = `mutation inviteForBetaTesting($botId: Int!, $userId: Int!) {
	inviteForBetaTesting(botId: $botId, userId: $userId) {
		status
		message	    
	}
}`;

const getBetaTestingBotDeveloperInvitedList = `mutation getBetaTestingBotDeveloperInvitedList($botId: Int,$invitedUserId: Int, $pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $sortOrder: OrderDirection) {
	getBetaTestingBotDeveloperInvitedList(botId: $botId, invitedUserId: $invitedUserId, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder) {
		status
		message
		totalCount
		data{
			profilephoto
			thumb
			api_key
			bot_name
			user_id
			id
			cts
			firstname
			full_name
			invitationAccept
			invitedBotId
			invitedCts
			isAcceptPolicy
			lastname
			username
		}    
	}
}`;

const removeBetaTestingInvitation = `mutation removeBetaTestingInvitation($botId: Int,$invitedUserId: Int) {
	removeBetaTestingInvitation(botId: $botId, invitedUserId: $invitedUserId) {
		status
		message    
	}
}`;

const BotQuery = {
	getBotDetailsById,
	getBotRatingOverView,
	getBotsWithStatus,
	subscribeBot,
	unSubscribeByBotId,
	addOrEditBotReview,
	getBotRatingReview,
	getBotDeveloperUsingBotId,
	getBotsByTeamId,
	getBotDetailsByUserId,
	getBotByUserId,
	checkNameHandlerExist,
	addBot,
	getBotDetailsByBotId,
	getBotSubscribedUser,
	getBotCommand,
	checkBotCommand,
	updateBotCommand,
	removeBotCommand,
	addBotCammand,
	removeBot,
	getBotsUserId,
	getBotDetailByStatus,
	getBotAnalysisChart,
	enableDisableBot,
	botStatus,
	botType,
	updateBot,
	changeBotTeam,
	getAllBotReviews,
	getAllBotRatingOverView,
	getAllBotsByBotCategory,
	getAllBotsByCategoryId,
	deleteBotByCategoryId,
	deleteBot,
	getBotStatusByAdmin,
	reGenerateBotToken,
	getBotStoreCategoryType,
	changeReviewStatusByBotId,
	uploadBannerImage,
	removeBannerImage,
	uploadFeatureImage,
	removeFeaturedImage,
	getTeamDetailsByTeamTypeId,
	getBotTokenAndUserIdAndSpaceInfo,
	botLogin,
	getBotNewAccessTokenByRefreshToken,
	botResetPassword,
	requestForVerification,
	requestForPublish,
	acceptOrRejectReuqest,
	getPermissionList,
	addPermissionList,
	updatePermission,
	activeDeactivePermission,
	inviteForBetaTesting,
	getBetaTestingBotDeveloperInvitedList,
	removeBetaTestingInvitation
};

export default BotQuery;
