import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const MarketPlaceConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/admin/market-place/bot-summary/:categoryTypeId?/add/:categoryName?',
			component: lazy(() => import('./AddBotCategories'))
		},
		{
			path: '/admin/market-place/bot-summary/:categoryTypeId',
			component: lazy(() => import('./BotSummary'))
		},
		{
			path: '/admin/market-place/bot-categories',
			component: lazy(() => import('./BotCategories'))
		},
		{
			path: '/admin/market-place/marketplace-categories',
			component: lazy(() => import('./MarketplaceCategories'))
		},
		{
			path: '/admin/market-place',
			component: () => <Redirect to="/admin/market-place/bot-categories" />
		}
	]
};

export default MarketPlaceConfig;
