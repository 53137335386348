import i18next from 'i18next';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);

const navigationConfig = [
	{
		id: 'applications',
		title: 'Applications',
		translate: 'APPLICATIONS',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				translate: 'DASHBOARD',
				type: 'item',
				auth: 'bot_developer',
				icon: 'dashboard',
				url: '/dashboard'
			},

			{
				id: 'teams',
				title: 'Teams',
				translate: 'TEAMS',
				type: 'item',
				auth: 'bot_developer',
				icon: 'group',
				url: '/teams'
			},
			{
				id: 'mybots',
				title: 'My Bots',
				translate: 'MYBOTS',
				type: 'item',
				auth: 'bot_developer',
				icon: 'speaker_notes',
				url: '/my-bots'
			},
			// {
			// 	id: 'botsAnalytics',
			// 	title: 'Bots Analytics',
			// 	translate: 'BOTSANALYTICS',
			// 	type: 'item',
			// 	auth: 'bot_developer',
			// 	icon: 'analytics',
			// 	url: '/bots-analytics'
			// },
			{
				id: 'documentation',
				title: 'Documentation',
				translate: 'DOCUMENTATION',
				type: 'link',
				auth: 'bot_developer',
				icon: 'description',
				url: '/developers/docs',
				target: '_blank'
			},
			{
				id: 'agreements',
				title: 'Agreements',
				translate: 'AGREEMENTS',
				type: 'item',
				auth: 'bot_developer',
				icon: 'gavel',
				url: '/agreements'
			},
			{
				id: 'admin-dashboard',
				title: 'Admin Dashboard',
				translate: 'ADMINDASHBOARD',
				type: 'item',
				auth: 'admin',
				icon: 'dashboard',
				url: '/admin/admin-dashboard'
			},
			{
				id: 'dev-users',
				title: 'Dev Users',
				translate: 'DEVUSERS',
				type: 'item',
				auth: 'admin',
				icon: 'person',
				url: '/admin/dev-users'
			},
			{
				id: 'bots',
				title: 'Bots',
				translate: 'BOTS',
				type: 'collapse',
				auth: 'admin',
				icon: 'forum',
				children: [
					{
						id: 'bots',
						title: 'Bots',
						translate: 'BOTS',
						type: 'item',
						auth: 'admin',
						url: '/admin/bots',
						exact: true
					},
					{
						id: 'bot-permission',
						title: 'Bot Permission',
						translate: 'BOTPERMISSION',
						type: 'item',
						auth: 'admin',
						url: '/admin/bots/bot-permission',
						exact: true
					}
				]
			},
			{
				id: 'post',
				title: 'POST',
				translate: 'POST',
				type: 'collapse',
				auth: 'admin',
				icon: 'post_add',
				children: [
					{
						id: 'post-categories',
						title: 'Post Categories',
						translate: 'POSTCATEGORIES',
						type: 'item',
						auth: 'admin',
						url: '/admin/post/post-categories',
						exact: true
					},
					{
						id: 'post-subcategories',
						title: 'Post Sub Categories',
						translate: 'POSTSUBCATEGORIES',
						type: 'item',
						auth: 'admin',
						url: '/admin/post/post-subcategories',
						exact: true
					},
					{
						id: 'user-post-report',
						title: 'User Post Report',
						translate: 'USERPOSTREPORT',
						type: 'item',
						auth: 'admin',
						url: '/admin/post/user-post-report',
						exact: true
					}
				]
			},
			{
				id: 'settings',
				title: 'Settings',
				translate: 'SETTINGS',
				type: 'item',
				auth: 'admin',
				icon: 'settings',
				url: '/admin/settings'
			},
			{
				id: 'marketplace',
				title: 'MARKETPLACE',
				translate: 'MARKETPLACE',
				type: 'collapse',
				auth: 'admin',
				icon: 'shopping_cart',
				children: [
					{
						id: 'bot-categories',
						title: 'Bot Categories',
						translate: 'BOTCATEGORIES',
						type: 'item',
						auth: 'admin',
						url: '/admin/market-place/bot-categories',
						exact: true
					},
					// {
					// 	id: 'bot-summary',
					// 	title: 'Bot Summary',
					// 	translate: 'BOTSUMMARY',
					// 	type: 'item',
					// 	auth: 'admin',
					// 	url: '/admin/market-place/bot-summary/364',
					// 	exact: true
					// },
					{
						id: 'marketplace-categories',
						title: 'Marketplace Categories',
						translate: 'MARKETPLACECATEGORIES',
						type: 'item',
						auth: 'admin',
						url: '/admin/market-place/marketplace-categories',
						exact: true
					}
				]
			},
			// {
			// 	id: 'bugs',
			// 	title: 'Bug Report',
			// 	translate: 'BUGREPORT',
			// 	type: 'item',
			// 	auth: 'admin',
			// 	icon: 'bug_report',
			// 	url: '/admin/bug-report'
			// },
			// {
			// 	id: 'contctus',
			// 	title: 'Contact Us',
			// 	translate: 'CONTACTUS',
			// 	type: 'item',
			// 	auth: 'admin',
			// 	icon: 'contact_page',
			// 	url: '/admin/contact-us'
			// },
			{
				id: 'brokerage',
				title: 'Brokerage',
				translate: 'BROKERAGE',
				type: 'item',
				auth: 'admin',
				icon: 'support_agent',
				url: '/admin/brokerage'
			},
			{
				id: 'attachment-type',
				title: 'Attachment Types',
				translate: 'ATTACHMENTTYPE',
				type: 'item',
				auth: 'admin',
				icon: 'attachment',
				url: '/admin/attachment-type'
			},
			// {
			// 	id: 'alert-type',
			// 	title: 'Alert Types',
			// 	translate: 'ALERTTYPE',
			// 	type: 'item',
			// 	auth: 'admin',
			// 	icon: 'notifications',
			// 	url: '/admin/alert-type'
			// },
			{
				id: 'feedback',
				title: 'Feedback',
				translate: 'FEEDBACK',
				type: 'item',
				auth: 'admin',
				icon: 'contact_page',
				url: '/admin/feedback'
			},
			{
				id: 'activities',
				title: 'ACTIVITIES_ADMIN',
				translate: 'ACTIVITIES_ADMIN',
				type: 'collapse',
				auth: 'item',
				icon: 'local_activity',
				children: [
					{
						id: 'activity-scope',
						title: 'ACTIVITIES_SCOPE',
						translate: 'ACTIVITIES_SCOPE',
						type: 'item',
						auth: 'admin',
						url: '/admin/activity/activity-scope',
						exact: true
					},
					{
						id: 'activity-type',
						title: 'ACTIVITIES_TYPE',
						translate: 'ACTIVITIES_TYPE',
						type: 'item',
						auth: 'admin',
						url: '/admin/activity/activity-type',
						exact: true
					}
				]
			},
			{
				id: 'notification-type',
				title: 'Notification Type',
				translate: 'NOTIFICATIONTYPE',
				type: 'item',
				auth: 'admin',
				icon: 'notifications',
				url: '/admin/notification-type'
			},
			{
				id: 'app-users',
				title: 'App Users',
				translate: 'APPUSERS',
				type: 'item',
				auth: 'admin',
				icon: 'person',
				url: '/admin/app-users'
			},
			{
				id: 'policy',
				title: 'POLICY',
				translate: 'POLICY',
				type: 'collapse',
				auth: 'item',
				icon: 'policy',
				children: [
					{
						id: 'policy-type',
						title: 'POLICY_TYPE',
						translate: 'POLICY_TYPE',
						type: 'item',
						auth: 'admin',
						url: '/admin/policy/policy-type',
						exact: true
					},
					{
						id: 'privacy-policy',
						title: 'PRIVACY_POLICY',
						translate: 'PRIVACY_POLICY',
						type: 'item',
						auth: 'admin',
						url: '/admin/policy/privacy-policy',
						exact: true
					}
				]
			},
			{
				id: 'application-user',
				title: 'Application User',
				translate: 'Dashboard',
				type: 'item',
				auth: 'app_user',
				icon: 'person',
				url: '/application-user/dashboard'
			},
			{
				id: 'portfolio',
				title: 'PORTFOLIO',
				translate: 'PORTFOLIO',
				type: 'collapse',
				auth: 'app_user',
				icon: 'contacts',
				children: [
					{
						id: 'watch-list',
						title: 'Watch List',
						translate: 'WATCHLIST',
						type: 'item',
						auth: 'app_user',
						url: '/application-user/portfolio/watch-list',
						exact: true
					},
					{
						id: 'portfolio-list',
						title: 'Portfolio List',
						translate: 'PORTFOLIOLIST',
						type: 'item',
						auth: 'app_user',
						url: '/application-user/portfolio/portfolio-list',
						exact: true
					},
					{
						id: 'alerts',
						title: 'Alerts',
						translate: 'ALERTS',
						type: 'item',
						auth: 'app_user',
						url: '/application-user/portfolio/alerts',
						exact: true
					}
				]
			},
			{
				id: 'market',
				title: 'Market',
				translate: 'MARKET',
				type: 'item',
				auth: 'app_user',
				icon: 'store',
				url: '/application-user/market'
			},
			{
				id: 'live-post',
				title: 'Post',
				translate: 'LIVEPOST',
				type: 'item',
				auth: 'app_user',
				icon: 'photo_library',
				url: '/application-user/post'
			},
			{
				id: 'marketplace',
				title: 'Marketplace',
				translate: 'MARKETPLACE',
				type: 'item',
				auth: 'app_user',
				icon: 'storefront',
				url: '/application-user/marketplace'
			},
			{
				id: 'latest-news',
				title: 'Latest News',
				translate: 'LATESTNEWS',
				type: 'item',
				auth: 'app_user',
				icon: 'receipt_long',
				url: '/application-user/latest-news'
			},
			{
				id: 'events',
				title: 'Events',
				translate: 'EVENTS',
				type: 'item',
				auth: 'app_user',
				icon: 'event_note',
				url: '/application-user/events'
			},
			{
				id: 'app_activities',
				title: 'Activities',
				translate: 'ACTIVITIES',
				type: 'item',
				auth: 'app_user',
				icon: 'sort',
				url: '/application-user/activities'
			}
		]
	}
];

export default navigationConfig;
