import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Query from 'app/main/query/user';
import Icon from '@material-ui/core/Icon';
import _ from '@lodash';
import { closeAboutDialog, getAboutUsAndContactUsDetails } from 'app/auth/store/userSlice';

function AboutDialog() {
	const dispatch = useDispatch();

	const aboutData = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.getAboutUsAndContactUsDetailsData?.data?.getAboutUsAndContactUsDetails?.data
			? userMenuReducer.user.getAboutUsAndContactUsDetailsData.data.getAboutUsAndContactUsDetails.data
			: {}
	);

	const appDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.aboutDialog);

	useEffect(() => {
		const payload = {
			query: Query.getAboutUsAndContactUsDetails,
			variables: { id: 2 }
		};
		dispatch(getAboutUsAndContactUsDetails(payload));

		return () => {};
	}, [dispatch]);

	function closeComposeDialog() {
		dispatch(closeAboutDialog());
	}

	function createMarkup(desc) {
		return { __html: desc };
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				style={{ margin: '0 auto' }}
				maxWidth="xs"
			>
				<AppBar position="static" elevation={0}>
					<Toolbar className="flex w-full justify-between">
						<Typography variant="subtitle1" color="inherit">
							About
						</Typography>
						<Button
							className="float-right bg-transparent rounded p-0 w-auto min-w-0"
							variant="contained"
							color="primary"
							type="button"
							onClick={() => {
								dispatch(closeAboutDialog());
							}}
						>
							<Icon>close</Icon>
						</Button>
					</Toolbar>
				</AppBar>
				<DialogContent
					classes={{ root: 'p-16' }}
					className="about-content"
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					<span dangerouslySetInnerHTML={createMarkup(aboutData?.description)} />
				</DialogContent>
			</Dialog>
		</>
	);
}

export default AboutDialog;
