import ApplicationDashboardConfig from './application-dashboard/ApplicationDashboardConfig';
import portfolioConfig from './portfolio/portfolioConfig';
import userprofileConfig from './user-profile/UserProfileConfig';
import ActivitiesConfig from './activities/ActivitiesConfig';
import MarketConfig from './market/MarketConfig';
import MarketplaceConfig from './marketplace/MarketplaceConfig';
import LatestNewsConfig from './latest-news/LatestNewsConfig';
import PostConfig from './post/PostConfig';
import EventsConfig from './events/EventsConfig';

const appDashboardConfigs = [
	ApplicationDashboardConfig,
	portfolioConfig,
	ActivitiesConfig,
	userprofileConfig,
	MarketConfig,
	MarketplaceConfig,
	LatestNewsConfig,
	PostConfig,
	EventsConfig
];

export default appDashboardConfigs;
