import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Link, Element } from 'react-scroll';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	layoutRoot: {}
});

function SimpleFullWidthSample() {
	const dispatch = useDispatch();
	const classes = useStyles();

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			content={
				<div className="p-24">
					<div className="justify-center mx-auto md:mt-20 agreements">
						<div className="md:grid md:grid-cols-3 gap-28">
							<section className="mb-20 sidebar md:fixed z-20 pt-24 md:pt-72 ">
								<div className="widget">
									<Typography
										component={motion.span}
										initial={{ x: -20 }}
										animate={{ x: 0, transition: { delay: 0.2 } }}
										delay={300}
										className="text-16 md:text-24 pl-36 mb-36 block font-semibold"
									>
										Agreements
									</Typography>
									<nav className="navbar navbar-default navbar-fixed-top" id="sidebar-nav">
										<ul className="list-unstyled text-reset">
											<li className="pl-36 relative mb-12">
												<Link
													to="acceptable"
													className="acceptable text-18 leading-6 text-black-700 font-medium"
													spy
													smooth
													duration={500}
													activeClass="active"
												>
													1. Acceptable Use Policy
												</Link>
											</li>
											<li className="pl-36 relative mb-12">
												<Link
													to="cookie-policy"
													className="cookie-policy text-18 leading-6 text-black-700 font-medium"
													spy
													smooth
													duration={500}
													activeClass="active"
												>
													2. Cookie Policy
												</Link>
											</li>
											<li className="pl-36 relative mb-12">
												<Link
													to="privacy-policy"
													className="privacy-policy text-18 leading-6 text-black-700 font-medium"
													spy
													smooth
													duration={500}
													activeClass="active"
												>
													3. Privacy Policy
												</Link>
											</li>
											<li className="pl-36 relative mb-12">
												<Link
													to="terms-of-use"
													className="terms-of-use text-18 leading-6 text-black-700 font-medium"
													spy
													smooth
													duration={500}
													activeClass="active"
												>
													4. Terms of Use
												</Link>
											</li>
										</ul>
									</nav>
								</div>
							</section>

							<section className="col-span-4 text-14 leading-6 text-gray-700 font-medium agree-content border-l-1  md:p-20 md:pt-0">
								<Element name="acceptable" className="element pt-60">
									<div className="card">
										<div className="card-body p-10">
											<h2 className="mb-12 font-semibold text-18 md:text-24 text-amber-500">
												1. Woyce LLC Acceptable Use Policy
											</h2>
											<p className="mb-8">
												This acceptable use policy covers the products, services, and
												technologies (collectively referred to as the “Products”) provided by
												Woyce LLC under any ongoing agreement. It’s designed to protect us, our
												customers, and the general Internet community from unethical,
												irresponsible, and illegal activity.
											</p>
											<p className="mb-8">
												Woyce LLC customers found engaging in activities prohibited by this
												acceptable use policy can be liable for service suspension and account
												termination. In extreme cases, we may be legally obliged to report such
												customers to the relevant authorities.
											</p>
											<p className="mb-8">This policy was last reviewed on 23 December 2021.</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Fair use
											</h3>
											<p className="mb-8">
												We provide our facilities with the assumption your use will be “business
												as usual”, as per our offer schedule. If your use is considered to be
												excessive, then additional fees may be charged, or capacity may be
												restricted.
											</p>
											<p className="mb-8">
												We are opposed to all forms of abuse, discrimination, rights
												infringement, and/or any action that harms or disadvantages any group,
												individual, or resource. We expect our customers and, where applicable,
												their users (“end-users”) to likewise engage our Products with similar
												intent.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Customer accountability
											</h3>
											<p className="mb-8">
												We regard our customers as being responsible for their own actions as
												well as for the actions of anyone using our Products with the customer’s
												permission. This responsibility also applies to anyone using our
												Products on an unauthorized basis as a result of the customer’s failure
												to put in place reasonable security measures.
											</p>
											<p className="mb-8">
												By accepting Products from us, our customers agree to ensure adherence
												to this policy on behalf of anyone using the Products as their end
												users. Complaints regarding the actions of customers or their end-users
												will be forwarded to the nominated contact for the account in question.
											</p>
											<p className="mb-8">
												If a customer — or their end-user or anyone using our Products as a
												result of the customer — violates our acceptable use policy, we reserve
												the right to terminate any Products associated with the offending
												account or the account itself or take any remedial or preventative
												action we deem appropriate, without notice. To the extent permitted by
												law, no credit will be available for interruptions of service resulting
												from any violation of our acceptable use policy.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Prohibited activity
											</h3>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Copyright infringement and access to unauthorized material
											</h4>
											<p className="mb-8">
												Our Products must not be used to transmit, distribute or store any
												material in violation of any applicable law. This includes but isn’t
												limited to:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>
														any material protected by copyright, trademark, trade secret, or
														other intellectual property right used without proper
														authorization, and
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>
														any material that is obscene, defamatory, constitutes an illegal
														threat or violates export control laws.
													</span>
												</li>
											</ul>
											<p className="mb-8">
												The customer is solely responsible for all material they input, upload,
												disseminate, transmit, create or publish through or on our Products, and
												for obtaining legal permission to use any works included in such
												material.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												SPAM and unauthorized message activity
											</h3>
											<p className="mb-8">
												Our Products must not be used for the purpose of sending unsolicited
												bulk or commercial messages in violation of the laws and regulations
												applicable to your jurisdiction (“spam”). This includes but isn’t
												limited to sending spam, soliciting customers from spam sent from other
												service providers, and collecting replies to spam sent from other
												service providers.
											</p>
											<p className="mb-8">
												Our Products must not be used for the purpose of running unconfirmed
												mailing lists or telephone number lists (“messaging lists”). This
												includes but isn’t limited to subscribing email addresses or telephone
												numbers to any messaging list without the permission of the email
												address or telephone number owner, and storing any email addresses or
												telephone numbers subscribed in this way. All messaging lists run on or
												hosted by our Products must be “confirmed opt-in”. Verification of the
												address or telephone number owner’s express permission must be available
												for the lifespan of the messaging list.
											</p>
											<p className="mb-8">
												We prohibit the use of email lists, telephone number lists or databases
												purchased from third parties intended for spam or unconfirmed messaging
												list purposes on our Products.
											</p>
											<p className="mb-8">
												This spam and unauthorized message activity policy applies to messages
												sent using our Products, or to messages sent from any network by the
												customer or any person on the customer’s behalf, that directly or
												indirectly refer the recipient to a site hosted via our Products.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Unethical, exploitative, and malicious activity
											</h3>
											<p className="mb-8">
												Our Products must not be used for the purpose of advertising,
												transmitting, or otherwise making available any software, program,
												product, or service designed to violate this acceptable use policy, or
												the acceptable use policy of other service providers. This includes but
												isn’t limited to facilitating the means to send spam and the initiation
												of network sniffing, pinging, packet spoofing, flooding, mail-bombing,
												and denial-of-service attacks.
											</p>
											<p className="mb-8">
												Our Products must not be used to access any account or electronic
												resource where the group or individual attempting to gain access does
												not own or is not authorized to access the resource (e.g. “hacking”,
												“cracking”, “phreaking”, etc.).
											</p>
											<p className="mb-8">
												Our Products must not be used for the purpose of intentionally or
												recklessly introducing viruses or malicious code into our Products and
												systems.
											</p>
											<p className="mb-8">
												Our Products must not be used for purposely engaging in activities
												designed to harass another group or individual. Our definition of
												harassment includes but is not limited to denial-of-service attacks,
												hate-speech, advocacy of racial or ethnic intolerance, and any activity
												intended to threaten, abuse, infringe upon the rights of, or
												discriminate against any group or individual.
											</p>
											<p className="mb-8">
												Other activities considered unethical, exploitative, and malicious
												include:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>
														Obtaining (or attempting to obtain) services from us with the
														intent to avoid payment;
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>
														Using our facilities to obtain (or attempt to obtain) services
														from another provider with the intent to avoid payment;
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														The unauthorized access, alteration, or destruction (or any
														attempt thereof) of any information about our customers or
														end-users, by any means or device;
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Using our facilities to interfere with the use of our facilities
														and network by other customers or authorized individuals;
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Publishing or transmitting any content of links that incite
														violence, depict a violent act, depict child pornography, or
														threaten anyone’s health and safety;
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Any act or omission in violation of consumer protection laws and
														regulations;
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Any violation of a person’s privacy.
													</span>
												</li>
											</ul>
											<p className="mb-8">
												Our Products may not be used by any person or entity, which is involved
												with or suspected of involvement in activities or causes relating to
												illegal gambling; terrorism; narcotics trafficking; arms trafficking or
												the proliferation, development, design, manufacture, production,
												stockpiling, or use of nuclear, chemical or biological weapons, weapons
												of mass destruction, or missiles; in each case including any affiliation
												with others whatsoever who support the above such activities or causes.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Unauthorized use of Woyce LLC property
											</h3>
											<p className="mb-8">
												We prohibit the impersonation of Woyce LLC, the representation of a
												significant business relationship with Woyce LLC, or ownership of any
												Woyce LLC property (including our Products and brand) for the purpose of
												fraudulently gaining service, custom, patronage, or user trust.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												About this policy
											</h3>
											<p className="mb-8">
												This policy outlines a non-exclusive list of activities and intent we
												deem unacceptable and incompatible with our brand.
											</p>
											<p className="mb-8">
												We reserve the right to modify this policy at any time by publishing the
												revised version on our website. The revised version will be effective
												from the earlier of:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>
														the date the customer uses our Products after we publish the
														revised version on our website; or
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>
														30 days after we publish the revised version on our website.
													</span>
												</li>
											</ul>
										</div>
									</div>
								</Element>
								<Element name="cookie-policy" className="element pt-60">
									<div className="card">
										<div className="card-body p-10">
											<h2 className="mb-12 font-semibold text-18 md:text-24 text-amber-500">
												2. Woyce LLC Cookie Policy
											</h2>
											<p className="mb-8">
												We use cookies to help improve your experience of our website at
												{process.env.REACT_APP_STOCKY_NETWORK_LINK}. This cookie policy is part
												of Woyce LLC's privacy policy. It covers the use of cookies between your
												device and our site.
											</p>
											<p className="mb-8">
												We also provide basic information on third-party services we may use,
												who may also use cookies as part of their service. This policy does not
												cover their cookies.
											</p>
											<p className="mb-8">
												If you don’t wish to accept cookies from us, you should instruct your
												browser to refuse cookies from &nbsp;
												<a href={process.env.REACT_APP_STOCKY_NETWORK_LINK}>stockly.network.</a>
												&nbsp; In such a case, we may be unable to provide you with some of your
												desired content and services.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												What is a cookie?
											</h3>
											<p className="mb-8">
												A cookie is a small piece of data that a website stores on your device
												when you visit. It typically contains information about the website
												itself, a unique identifier that allows the site to recognize your web
												browser when you return, additional data that serves the cookie’s
												purpose, and the lifespan of the cookie itself.
											</p>
											<p className="mb-8">
												Cookies are used to enable certain features (e.g. logging in), track
												site usage (e.g. analytics), store your user settings (e.g. time zone,
												notification preferences), and to personalize your content (e.g.
												advertising, language).
											</p>
											<p className="mb-8">
												Cookies set by the website you are visiting are usually referred to as
												first-party cookies. They typically only track your activity on that
												particular site.
											</p>
											<p className="mb-8">
												Cookies set by other sites and companies (i.e. third parties) are called
												third-party cookies They can be used to track you on other websites that
												use the same third-party service.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Types of cookies and how we use them
											</h3>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Essential cookies
											</h4>
											<p className="mb-8">
												Essential cookies are crucial to your experience of a website, enabling
												core features like user logins, account management, shopping carts, and
												payment processing.
											</p>
											<p className="mb-8">
												We use essential cookies to enable certain functions on our website.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Performance cookies
											</h4>
											<p className="mb-8">
												Performance cookies track how you use a website during your visit.
												Typically, this information is anonymous and aggregated, with
												information tracked across all site users. They help companies
												understand visitor usage patterns, identify and diagnose problems or
												errors their users may encounter, and make better strategic decisions in
												improving their audience’s overall website experience. These cookies may
												be set by the website you’re visiting (first-party) or by third-party
												services. They do not collect personal information about you.
											</p>
											<p className="mb-8">We use performance cookies on our site.</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Functionality cookies
											</h4>
											<p className="mb-8">
												Functionality cookies are used to collect information about your device
												and any settings you may configure on the website you’re visiting (like
												language and time zone settings). With this information, websites can
												provide you with customized, enhanced, or optimized content and
												services. These cookies may be set by the website you’re visiting
												(first-party) or by third-party services.
											</p>
											<p className="mb-8">
												We use functionality cookies for selected features on our site.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Targeting/advertising cookies
											</h4>
											<p className="mb-8">
												Targeting/advertising cookies help determine what promotional content is
												most relevant and appropriate to you and your interests. Websites may
												use them to deliver targeted advertising or limit the number of times
												you see an advertisement. This helps companies improve the effectiveness
												of their campaigns and the quality of content presented to you. These
												cookies may be set by the website you’re visiting (first-party) or by
												third-party services. Targeting/advertising cookies set by third-parties
												may be used to track you on other websites that use the same third-party
												service.
											</p>
											<p className="mb-8">We use targeting/advertising cookies on our site.</p>
										</div>
									</div>
								</Element>
								<Element name="privacy-policy" className="element pt-60">
									<div className="card">
										<div className="card-body p-10">
											<h2 className="mb-12 font-semibold text-18 md:text-24 text-amber-500">
												3. Woyce LLC Privacy Policy
											</h2>
											<p className="mb-8">
												Your privacy is important to us. It is Woyce LLC's policy to respect
												your privacy and comply with any applicable law and regulation regarding
												any personal information we may collect about you, including via our
												app, Stockly, and its associated services.
											</p>
											<p className="mb-8">
												Personal information is any information about you which can be used to
												identify you. This includes information about you as a person (such as
												name, address, and date of birth), your devices, payment details, and
												even information about how you use an app or online service.
											</p>
											<p>
												In the event our app contains links to third-party sites and services,
												please be aware that those sites and services have their own privacy
												policies. After following a link to any third-party content, you should
												read their posted privacy policy information about how they collect and
												use personal information. This Privacy Policy does not apply to any of
												your activities after you leave our app.
											</p>
											<p className="mb-8">This policy is effective as of 23 December 2021.</p>
											<p className="mb-8">Last updated: 23 December 2021</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Information We Collect
											</h3>
											<p className="mb-8">
												Information we collect falls into one of two categories: “voluntarily
												provided” information and “automatically collected” information.
											</p>
											<p className="mb-8">
												“Voluntarily provided” information refers to any information you
												knowingly and actively provide us when using our app and its associated
												services.
											</p>
											<p className="mb-8">
												“Automatically collected” information refers to any information
												automatically sent by your device in the course of accessing our app and
												its associated services.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Log Data
											</h3>
											<p className="mb-8">
												When you access our servers via our app, we may automatically log the
												standard data provided by your device. It may include your device's
												Internet Protocol (IP) address, your device type and version, your
												activity within the app, time and date, and other details about your
												usage.
											</p>
											<p className="mb-8">
												Additionally, when you encounter certain errors while using the app, we
												automatically collect data about the error and the circumstances
												surrounding its occurrence. This data may include technical details
												about your device, what you were trying to do when the error happened,
												and other technical information relating to the problem. You may or may
												not receive notice of such errors, even in the moment they occur, that
												they have occurred, or what the nature of the error is.
											</p>
											<p className="mb-8">
												Please be aware that while this information may not be personally
												identifying by itself, it may be possible to combine it with other data
												to personally identify individual persons.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Device Data
											</h3>
											<p className="mb-8">
												Our app may access and collect data via your device's in-built tools,
												such as:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>Your identity</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													<span>Location data</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Camera
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Microphone
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Calendar
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Contacts
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Phone/SMS
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Storage, photos and/or media
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Notifications
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Voice assistance
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Background data refresh
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Mobile data
													</span>
												</li>
												<li className="pl-36 relative mb-8">
													<span>
														<span className="absolute left-0 top-2">
															<Icon color="action">check_circle</Icon>
														</span>
														Device/app history
													</span>
												</li>
											</ul>
											<p className="mb-8">
												When you install the app or use your device’s tools within the app, we
												request permission to access this information. The specific data we
												collect can depend on the individual settings of your device and the
												permissions you grant when you install and use the app.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Personal Information
											</h3>
											<p className="mb-8">
												We may ask for personal information — for example, when you submit
												content to us or when you contact us — which may include one or more of
												the following:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Name
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Email
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Social media profiles
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Date of birth
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Phone/mobile number
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Home/mailing address
												</li>
											</ul>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												User-Generated Content
											</h4>
											<p className="mb-8">
												We consider &ldquo;user-generated content&rdquo; to be materials (text,
												image and/or video content) voluntarily supplied to us by our users for
												the purpose of publication on our platform, website or re-publishing on
												our social media channels. All user-generated content is associated with
												the account or email address used to submit the materials.
											</p>
											<p className="mb-8">
												Please be aware that any content you submit for the purpose of
												publication will be public after posting (and subsequent review or
												vetting process). Once published, it may be accessible to third parties
												not covered under this privacy policy.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Legitimate Reasons for Processing Your Personal Information
											</h4>
											<p className="mb-8">
												We only collect and use your personal information when we have a
												legitimate reason for doing so. In which instance, we only collect
												personal information that is reasonably necessary to provide our
												services to you.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Collection and Use of Information
											</h4>
											<p className="mb-8">
												We may collect personal information from you when you do any of the
												following on our website:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Register for an account
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Sign up to receive updates from us via email or social media
													channels
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Use a mobile device or web browser to access our content
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Contact us via email, social media, or on any similar technologies
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													When you mention us on social media
												</li>
											</ul>
											<p className="mb-8">
												We may collect, hold, use, and disclose information for the following
												purposes, and personal information will not be further processed in a
												manner that is incompatible with these purposes:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													to provide you with our app and platform's core features and
													services
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													to enable you to customize or personalize your experience of our
													website
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													to deliver products and/or services to you
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													to contact and communicate with you
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													for analytics, market research, and business development, including
													to operate and improve our app, associated applications, and
													associated social media platforms
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													for advertising and marketing, including to send you promotional
													information about our products and services and information about
													third parties that we consider may be of interest to you
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													to enable you to access and use our app, associated platforms, and
													associated social media channels
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													for internal record keeping and administrative purposes
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													to comply with our legal obligations and resolve any disputes that
													we may have
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													to attribute any content (e.g. posts and comments) you submit that
													we publish on our website
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													for security and fraud prevention, and to ensure that our sites and
													apps are safe, secure, and used in line with our terms of use
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													for technical assessment, including to operate and improve our app,
													associated applications, and associated social media platforms
												</li>
											</ul>
											<p className="mb-8">
												We may combine voluntarily provided and automatically collected personal
												information with general information or research data we receive from
												other trusted sources. For example, If you consent to us accessing your
												social media profiles, we may combine information sourced from those
												profiles with information received from you directly to provide you with
												an enhanced experience of our app and services.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Security of Your Personal Information
											</h4>
											<p className="mb-8">
												When we collect and process personal information, and while we retain
												this information, we will protect it within commercially acceptable
												means to prevent loss and theft, as well as unauthorized access,
												disclosure, copying, use, or modification.
											</p>
											<p className="mb-8">
												Although we will do our best to protect the personal information you
												provide to us, we advise that no method of electronic transmission or
												storage is 100% secure, and no one can guarantee absolute data security.
											</p>
											<p className="mb-8">
												You are responsible for selecting any password and its overall security
												strength, ensuring the security of your own information within the
												bounds of our services. For example, ensuring any passwords associated
												with accessing your personal information and accounts are secure and
												confidential.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												How Long We Keep Your Personal Information
											</h4>
											<p className="mb-8">
												We keep your personal information only for as long as we need to. This
												time period may depend on what we are using your information for, in
												accordance with this privacy policy. For example, if you have provided
												us with personal information as part of creating an account with us, we
												may retain this information for the duration your account exists on our
												system. If your personal information is no longer required for this
												purpose, we will delete it or make it anonymous by removing all details
												that identify you.
											</p>
											<p className="mb-8">
												However, if necessary, we may retain your personal information for our
												compliance with a legal, accounting, or reporting obligation or for
												archiving purposes in the public interest, scientific, or historical
												research purposes or statistical purposes.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Children’s Privacy
											</h3>
											<p className="mb-8">
												We do not aim any of our products or services directly at children under
												the age of 13, and we do not knowingly collect personal information
												about children under 13.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Disclosure of Personal Information to Third Parties
											</h3>
											<p className="mb-8">We may disclose personal information to:</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													a parent, subsidiary, or affiliate of our company
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													third-party service providers for the purpose of enabling them to
													provide their services, including (without limitation) IT service
													providers, data storage, hosting and server providers, ad networks,
													analytics, error loggers, debt collectors, maintenance or
													problem-solving providers, marketing or advertising providers,
													professional advisors, and payment systems operators
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													our employees, contractors, and/or related entities
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													our existing or potential agents or business partners
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													credit reporting agencies, courts, tribunals, and regulatory
													authorities, in the event you fail to pay for goods or services we
													have provided to you
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													courts, tribunals, regulatory authorities, and law enforcement
													officers, as required by law, in connection with any actual or
													prospective legal proceedings, or in order to establish, exercise,
													or defend our legal rights
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													third parties, including agents or sub-contractors, who assist us in
													providing information, products, services, or direct marketing to
													you
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													third parties to collect and process data
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													an entity that buys, or to which we transfer all or substantially
													all of our assets and business
												</li>
											</ul>
											<p className="mb-8">Third parties we currently use include:</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Google Analytics
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Stockly Tracker
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													MailChimp
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Stockly Email Service
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Google Adsense
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Stockly Partners Ads
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Paypal
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Stripe
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Square
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Google Payments
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Apple Pay
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Authorize.net
												</li>
											</ul>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												International Transfers of Personal Information
											</h3>
											<p className="mb-8">
												The personal information we collect is stored and/or processed in United
												States, and India, or where we or our partners, affiliates, and
												third-party providers maintain facilities.
											</p>
											<p className="mb-8">
												The countries to which we store, process, or transfer your personal
												information may not have the same data protection laws as the country in
												which you initially provided the information. If we transfer your
												personal information to third parties in other countries: (i) we will
												perform those transfers in accordance with the requirements of
												applicable law; and (ii) we will protect the transferred personal
												information in accordance with this privacy policy.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Your Rights and Controlling Your Personal Information
											</h3>
											<p className="mb-8">
												<strong>Your choice:</strong> By providing personal information to us,
												you understand we will collect, hold, use, and disclose your personal
												information in accordance with this privacy policy. You do not have to
												provide personal information to us, however, if you do not, it may
												affect your use of our app or the products and/or services offered on or
												through it.
											</p>
											<p className="mb-8">
												<strong>Information from third parties:</strong> If we receive personal
												information about you from a third party, we will protect it as set out
												in this privacy policy. If you are a third party providing personal
												information about somebody else, you represent and warrant that you have
												such person’s consent to provide the personal information to us.
											</p>
											<p className="mb-8">
												<strong>Marketing permission:</strong> If you have previously agreed to
												us using your personal information for direct marketing purposes, you
												may change your mind at any time by contacting us using the details
												below.
											</p>
											<p className="mb-8">
												<strong>Access:</strong> You may request details of the personal
												information that we hold about you.
											</p>
											<p className="mb-8">
												<strong>Correction:</strong> If you believe that any information we hold
												about you is inaccurate, out of date, incomplete, irrelevant, or
												misleading, please contact us using the details provided in this privacy
												policy. We will take reasonable steps to correct any information found
												to be inaccurate, incomplete, misleading, or out of date.
											</p>
											<p className="mb-8">
												<strong>Non-discrimination:</strong> We will not discriminate against
												you for exercising any of your rights over your personal information.
												Unless your personal information is required to provide you with a
												particular service or offer (for example serving particular content to
												your device), we will not deny you goods or services and/or charge you
												different prices or rates for goods or services, including through
												granting discounts or other benefits, or imposing penalties, or provide
												you with a different level or quality of goods or services.
											</p>
											<p className="mb-8">
												<strong>Downloading of Personal Information:</strong> We provide a means
												for you to download the personal information you have shared through our
												app. Please contact us for more information.
											</p>
											<p className="mb-8">
												<strong>Notification of data breaches:</strong> We will comply with laws
												applicable to us in respect of any data breach.
											</p>
											<p className="mb-8">
												<strong>Complaints:</strong> If you believe that we have breached a
												relevant data protection law and wish to make a complaint, please
												contact us using the details below and provide us with full details of
												the alleged breach. We will promptly investigate your complaint and
												respond to you, in writing, setting out the outcome of our investigation
												and the steps we will take to deal with your complaint. You also have
												the right to contact a regulatory body or data protection authority in
												relation to your complaint.
											</p>
											<p className="mb-8">
												<strong>Unsubscribe:</strong> To unsubscribe from our email database or
												opt-out of communications (including marketing communications), please
												contact us using the details provided in this privacy policy, or opt-out
												using the opt-out facilities provided in the communication. We may need
												to request specific information from you to help us confirm your
												identity.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Use of Cookies
											</h3>
											<p className="mb-8">
												Our privacy policy covers the use of cookies between your device and our
												servers. A cookie is a small piece of data that an app may store on your
												device, typically containing a unique identifier that allows the app
												servers to recognise your device when you use the app; information about
												your account, session and/or device; additional data that serves the
												purpose of the cookie, and any self-maintenance information about the
												cookie itself.
											</p>
											<p className="mb-8">
												We use cookies to give your device access to core features of our app,
												to track app usage and performance on your device, to tailor your
												experience of our app based on your preferences, and to serve
												advertising to your device. Any communication of cookie data between
												your device and our servers occurs within a secure environment.
											</p>
											<p className="mb-8">
												Please refer to our Cookie Policy for more information.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Business Transfers
											</h3>
											<p className="mb-8">
												If we or our assets are acquired, or in the unlikely event that we go
												out of business or enter bankruptcy, we would include data, including
												your personal information, among the assets transferred to any parties
												who acquire us. You acknowledge that such transfers may occur, and that
												any parties who acquire us may, to the extent permitted by applicable
												law, continue to use your personal information according to this policy,
												which they will be required to assume as it is the basis for any
												ownership or use rights we have over such information.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Limits of Our Policy
											</h3>
											<p className="mb-8">
												Our app may link to external sites that are not operated by us. Please
												be aware that we have no control over the content and policies of those
												sites, and cannot accept responsibility or liability for their
												respective privacy practices.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Changes to This Policy
											</h3>
											<p className="mb-8">
												At our discretion, we may change our privacy policy to reflect updates
												to our business processes, current acceptable practices, or legislative
												or regulatory changes. If we decide to change this privacy policy, we
												will post the changes here and on our website.
											</p>
											<p className="mb-8">
												If the changes are significant, or if required by applicable law, we
												will contact you (based on your selected preferences for communications
												from us) and all our registered users with the new details and links to
												the updated or changed policy.
											</p>
											<p className="mb-8">
												If required by law, we will get your permission or give you the
												opportunity to opt in to or opt out of, as applicable, any new uses of
												your personal information.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Additional Disclosures for Australian Privacy Act Compliance (AU)
											</h3>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												International Transfers of Personal Information
											</h4>
											<p className="mb-8">
												Where the disclosure of your personal information is solely subject to
												Australian privacy laws, you acknowledge that some third parties may not
												be regulated by the Privacy Act and the Australian Privacy Principles in
												the Privacy Act. You acknowledge that if any such third party engages in
												any act or practice that contravenes the Australian Privacy Principles,
												it would not be accountable under the Privacy Act, and you will not be
												able to seek redress under the Privacy Act.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Additional Disclosures for General Data Protection Regulation (GDPR)
												Compliance (EU)
											</h3>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Data Controller / Data Processor
											</h4>
											<p className="mb-8">
												The GDPR distinguishes between organisations that process personal
												information for their own purposes (known as &ldquo;data
												controllers&rdquo;) and organizations that process personal information
												on behalf of other organizations (known as &ldquo;data
												processors&rdquo;). We, Woyce LLC, located at the address provided in
												our Contact Us section, are a Data Controller with respect to the
												personal information you provide to us.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Legal Bases for Processing Your Personal Information
											</h4>
											<p className="mb-8">
												We will only collect and use your personal information when we have a
												legal right to do so. In which case, we will collect and use your
												personal information lawfully, fairly, and in a transparent manner. If
												we seek your consent to process your personal information, and you are
												under 16 years of age, we will seek your parent or legal guardian’s
												consent to process your personal information for that specific purpose.
											</p>
											<p className="mb-8">
												Our lawful bases depend on the services you use and how you use them.
												This means we only collect and use your information on the following
												grounds:
											</p>
											<h5>Consent From You</h5>
											<p className="mb-8">
												Where you give us consent to collect and use your personal information
												for a specific purpose. You may withdraw your consent at any time using
												the facilities we provide; however this will not affect any use of your
												information that has already taken place. You may consent to providing
												your email address for the purpose of receiving marketing emails from
												us. While you may unsubscribe at any time, we cannot recall any email we
												have already sent. If you have any further enquiries about how to
												withdraw your consent, please feel free to enquire using the details
												provided in the Contact Us section of this privacy policy.
											</p>
											<h5>Performance of a Contract or Transaction</h5>
											<p className="mb-8">
												Where you have entered into a contract or transaction with us, or in
												order to take preparatory steps prior to our entering into a contract or
												transaction with you. For example, we need technical information about
												your device in order to provide the essential features of our app.
											</p>
											<h5>Our Legitimate Interests</h5>
											<p className="mb-8">
												Where we assess it is necessary for our legitimate interests, such as
												for us to provide, operate, improve and communicate our services. For
												example, we collect technical information about your device in order to
												improve and personalize your experience of our app. We consider our
												legitimate interests to include research and development, understanding
												our audience, marketing and promoting our services, measures taken to
												operate our services efficiently, marketing analysis, and measures taken
												to protect our legal rights and interests.
											</p>
											<h5>Compliance with Law</h5>
											<p className="mb-8">
												In some cases, we may have a legal obligation to use or keep your
												personal information. Such cases may include (but are not limited to)
												court orders, criminal investigations, government requests, and
												regulatory obligations. If you have any further enquiries about how we
												retain personal information in order to comply with the law, please feel
												free to enquire using the details provided in the Contact Us section of
												this privacy policy.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												International Transfers Outside of the European Economic Area (EEA)
											</h4>
											<p className="mb-8">
												We will ensure that any transfer of personal information from countries
												in the European Economic Area (EEA) to countries outside the EEA will be
												protected by appropriate safeguards, for example by using standard data
												protection clauses approved by the European Commission, or the use of
												binding corporate rules or other legally accepted means.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Your Rights and Controlling Your Personal Information
											</h4>
											<p className="mb-8">
												<strong>Restrict:</strong> You have the right to request that we
												restrict the processing of your personal information if (i) you are
												concerned about the accuracy of your personal information; (ii) you
												believe your personal information has been unlawfully processed; (iii)
												you need us to maintain the personal information solely for the purpose
												of a legal claim; or (iv) we are in the process of considering your
												objection in relation to processing on the basis of legitimate
												interests.
											</p>
											<p className="mb-8">
												<strong>Objecting to processing:</strong> You have the right to object
												to processing of your personal information that is based on our
												legitimate interests or public interest. If this is done, we must
												provide compelling legitimate grounds for the processing which overrides
												your interests, rights, and freedoms, in order to proceed with the
												processing of your personal information.
											</p>
											<p className="mb-8">
												<strong>Data portability:</strong> You may have the right to request a
												copy of the personal information we hold about you. Where possible, we
												will provide this information in CSV format or other easily readable
												machine format. You may also have the right to request that we transfer
												this personal information to a third party.
											</p>
											<p className="mb-8">
												<strong>Deletion:</strong> You may have a right to request that we
												delete the personal information we hold about you at any time, and we
												will take reasonable steps to delete your personal information from our
												current records. If you ask us to delete your personal information, we
												will let you know how the deletion affects your use of our app, website
												or products and services. There may be exceptions to this right for
												specific legal reasons which, if applicable, we will set out for you in
												response to your request. If you terminate or delete your account, we
												will delete your personal information within 180 days of the deletion of
												your account. Please be aware that search engines and similar third
												parties may still retain copies of your personal information that has
												been made public at least once, like certain profile information and
												public comments, even after you have deleted the information from our
												services or deactivated your account.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Additional Disclosures for California Compliance (US)
											</h3>
											<p className="mb-8">
												Under California Civil Code Section 1798.83, if you live in California
												and your business relationship with us is mainly for personal, family,
												or household purposes, you may ask us about the information we release
												to other organizations for their marketing purposes.
											</p>
											<p className="mb-8">
												To make such a request, please contact us using the details provided in
												this privacy policy with &ldquo;Request for California privacy
												information&rdquo; in the subject line. You may make this type of
												request once every calendar year. We will email you a list of categories
												of personal information we revealed to other organisations for their
												marketing purposes in the last calendar year, along with their names and
												addresses. Not all personal information shared in this way is covered by
												Section 1798.83 of the California Civil Code.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Do Not Track
											</h4>
											<p className="mb-8">
												Some browsers have a &ldquo;Do Not Track&rdquo; feature that lets you
												tell websites that you do not want to have your online activities
												tracked. At this time, we do not respond to browser &ldquo;Do Not
												Track&rdquo; signals.
											</p>
											<p className="mb-8">
												We adhere to the standards outlined in this privacy policy, ensuring we
												collect and process personal information lawfully, fairly,
												transparently, and with legitimate, legal reasons for doing so.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Cookies and Pixels
											</h4>
											<p className="mb-8">
												At all times, you may decline cookies from our site if your browser
												permits. Most browsers allow you to activate settings on your browser to
												refuse the setting of all or some cookies. Accordingly, your ability to
												limit cookies is based only on your browser’s capabilities. Please refer
												to the Cookies section of this privacy policy for more information.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												CCPA-permitted financial incentives
											</h4>
											<p className="mb-8">
												In accordance with your right to non-discrimination, we may offer you
												certain financial incentives permitted by the CCPA that can result in
												different prices, rates, or quality levels for the goods or services we
												provide.
											</p>
											<p className="mb-8">
												Any CCPA-permitted financial incentive we offer will reasonably relate
												to the value of your personal information, and we will provide written
												terms that describe clearly the nature of such an offer. Participation
												in a financial incentive program requires your prior opt-in consent,
												which you may revoke at any time.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												California Notice of Collection
											</h4>
											<p className="mb-8">
												In the past 12 months, we have collected the following categories of
												personal information enumerated in the California Consumer Privacy Act:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Identifiers, such as name, email address, phone number account name,
													IP address, and an ID or number assigned to your account.
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Customer records, such as billing and shipping address, and credit
													or debit card data.
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Demographics, such as your age or gender. This category includes
													data that may qualify as protected classifications under other
													California or federal laws.
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Commercial information, such as products or services history and
													purchases.
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Internet activity, such as your interactions with our service.
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Audio or visual data, such as photos or videos you share with us or
													post on the service.
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													Inferences, such as information about your interests, preferences
													and favorites.
												</li>
											</ul>
											<p className="mb-8">
												For more information on information we collect, including the sources we
												receive information from, review the &ldquo;Information We
												Collect&rdquo; section. We collect and use these categories of personal
												information for the business purposes described in the &ldquo;Collection
												and Use of Information&rdquo; section, including to provide and manage
												our Service.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Right to Know and Delete
											</h4>
											<p className="mb-8">
												If you are a California resident, you have rights to delete your
												personal information we collected and know certain information about our
												data practices in the preceding 12 months. In particular, you have the
												right to request the following from us:
											</p>
											<ul className="icon-list bullet-bg bullet-soft-primary mb-8">
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													The categories of personal information we have collected about you;
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													The categories of sources from which the personal information was
													collected;
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													The categories of personal information about you we disclosed for a
													business purpose or sold;
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													The categories of third parties to whom the personal information was
													disclosed for a business purpose or sold;
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													The business or commercial purpose for collecting or selling the
													personal information; and
												</li>
												<li className="pl-36 relative mb-8">
													<span className="absolute left-0 top-2">
														<Icon color="action">check_circle</Icon>
													</span>
													The specific pieces of personal information we have collected about
													you.
												</li>
											</ul>
											<p className="mb-8">
												To exercise any of these rights, please contact us using the details
												provided in this privacy policy.
											</p>
											<h4 className="md:text-18 text-14 text-black font-semibold mb-12 mt-12">
												Shine the Light
											</h4>
											<p className="mb-8">
												If you are a California resident, in addition to the rights discussed
												above, you have the right to request information from us regarding the
												manner in which we share certain personal information as defined by
												California’s &ldquo;Shine the Light&rdquo; with third parties and
												affiliates for their own direct marketing purposes.
											</p>
											<p className="mb-8">
												To receive this information, send us a request using the contact details
												provided in this privacy policy. Requests must include &ldquo;California
												Privacy Rights Request&rdquo; in the first line of the description and
												include your name, street address, city, state, and ZIP code.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Contact Us
											</h3>
											<p className="mb-8">
												For any questions or concerns regarding your privacy, you may contact us
												using the following details:
											</p>
											<p className="mb-8">
												Privacy Officer
												<br />
												info@woyce.io
											</p>
										</div>
									</div>
								</Element>
								<Element name="terms-of-use" className="element pt-60">
									<div className="card">
										<div className="card-body p-10">
											<h2 className="mb-12 font-semibold text-18 md:text-24 text-amber-500">
												4. Woyce LLC Mobile App Terms of Service
											</h2>
											<p className="mb-8">
												These Terms of Service govern your use of Stockly, our website located
												at &nbsp;
												<a href={process.env.REACT_APP_STOCKY_NETWORK_LINK}>stockly.network.</a>
												&nbsp; ,and any related services provided by Woyce LLC.
											</p>
											<p className="mb-8">
												When you create an Stockly account or use Stockly, you agree to abide by
												these Terms of Service and to comply with all applicable laws and
												regulations. If you do not agree with these Terms of Service, you are
												prohibited from further using the app, accessing our website, or using
												any other services provided by Woyce LLC.
											</p>
											<p className="mb-8">
												If you access or download Stockly from (1) the Apple App Store, you
												agree to any Usage Rules set forth in the App Store Terms of Service;
												and/or (2) the Google Play Store, you agree to the Android, Google Inc.
												Terms and Conditions including the Google Apps Terms of Service.
											</p>
											<p className="mb-8">
												We, Woyce LLC, reserve the right to review and amend any of these Terms
												of Service at our sole discretion. Upon doing so, we will update this
												page and notify you through the app and/or the email address you
												provided when you created your account. Any changes to these Terms of
												Service will take effect immediately from the date of publication.
											</p>
											<p className="mb-8">
												These Terms of Service were last updated on 23 December 2021.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Limitations of Use
											</h3>
											<p className="mb-8">
												By using Stockly and our website, you warrant on behalf of yourself, any
												entity who you represent who has entered into these Terms of Service,
												and your users that you will not:
											</p>
											<ol className="list-decimal list-inside pl-20">
												<li className="relative mb-8">
													modify, copy, prepare derivative works of, decompile, or reverse
													engineer Stockly or any materials and software contained within
													Stockly or on our website;
												</li>
												<li className="relative mb-8">
													remove any copyright or other proprietary notations from Stockly or
													any materials and software contained within Stockly or on our
													website;
												</li>
												<li className="relative mb-8">
													transfer Stockly or any of its associated materials to another
													person or “mirror” the materials on any other server;
												</li>
												<li className="relative mb-8">
													knowingly or negligently use Stockly or any of its associated
													services in a way that abuses or disrupts our networks or any other
													service Woyce LLC provides;
												</li>
												<li className="relative mb-8">
													use Stockly or its associated services to transmit or publish any
													harassing, indecent, obscene, fraudulent, or unlawful material;
												</li>
												<li className="relative mb-8">
													use Stockly or its associated services in violation of any
													applicable laws or regulations;
												</li>
												<li className="relative mb-8">
													use Stockly to send unauthorized advertising or spam;
												</li>
												<li className="relative mb-8">
													harvest, collect, or gather user data without the user’s consent; or
												</li>
												<li className="relative mb-8">
													use Stockly or its associated services in such a way that may
													infringe the privacy, intellectual property rights, or other rights
													of third parties.
												</li>
											</ol>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Intellectual Property
											</h3>
											<p className="mb-8">
												The intellectual property in the materials in Stockly and on our website
												are owned by or licensed to Woyce LLC. You may download Stockly, to
												view, use, and display the application on your mobile device for your
												personal use only.
											</p>
											<p className="mb-8">
												This constitutes the grant of a license, not a transfer of title. This
												license shall automatically terminate if you violate any of these
												restrictions or these Terms of Service, and may be terminated by Woyce
												LLC at any time.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												User-Generated Content
											</h3>
											<p className="mb-8">
												You retain your intellectual property ownership rights over content you
												submit to us for publication within Stockly and/or on its corresponding
												website. We will never claim ownership of your content, but we do
												require a license from you in order to use it.
											</p>
											<p className="mb-8">
												When you use Stockly or its associated services to post, upload, share,
												or otherwise transmit content covered by intellectual property rights,
												you grant to us a non-exclusive, royalty-free, transferable,
												sub-licensable, worldwide license to use, distribute, modify, run, copy,
												publicly display, translate, or otherwise create derivative works of
												your content in a manner that is consistent with your privacy
												preferences and our Privacy Policy.
											</p>
											<p className="mb-8">
												The license you grant us can be terminated at any time by deleting your
												content or account. However, to the extent that we (or our partners)
												have used your content in connection with commercial or sponsored
												content, the license will continue until the relevant commercial or post
												has been discontinued by us.
											</p>
											<p className="mb-8">
												You give us permission to use your username and other identifying
												information associated with your account in a manner that is consistent
												with your privacy preferences, and our Privacy Policy.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Automatic Updates
											</h3>
											<p className="mb-8">
												You give us permission to download and install updates to Stockly on
												your device in accordance with your privacy preferences. This permission
												can be revoked at any time by deleting Stockly from your device.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Liability
											</h3>
											<p className="mb-8">
												Stockly and the materials in Stockly and on our website are provided on
												an 'as is' basis. To the extent permitted by law, Woyce LLC makes no
												warranties, expressed or implied, and hereby disclaims and negates all
												other warranties including, without limitation, implied warranties or
												conditions of merchantability, fitness for a particular purpose, or
												non-infringement of intellectual property, or other violation of rights.
											</p>
											<p className="mb-8">
												In no event shall Woyce LLC or its suppliers be liable for any
												consequential loss suffered or incurred by you or any third party
												arising from the use or inability to use Stockly, our website, or any
												other services provided by Woyce LLC or the materials in Stockly, even
												if Woyce LLC or an authorized representative has been notified, orally
												or in writing, of the possibility of such damage.
											</p>
											<p className="mb-8">
												In the context of this agreement, &ldquo;consequential loss&rdquo;
												includes any consequential loss, indirect loss, real or anticipated loss
												of profit, loss of benefit, loss of revenue, loss of business, loss of
												goodwill, loss of opportunity, loss of savings, loss of reputation, loss
												of use and/or loss or corruption of data, whether under statute,
												contract, equity, tort (including negligence), indemnity, or otherwise.
											</p>
											<p className="mb-8">
												Because some jurisdictions do not allow limitations on implied
												warranties, or limitations of liability for consequential or incidental
												damages, these limitations may not apply to you.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Accuracy of Materials
											</h3>
											<p className="mb-8">
												The materials appearing in Stockly or on our website are not
												comprehensive and are for general information purposes only. To the
												extent permitted by law, Woyce LLC does not warrant or make any
												representations concerning the accuracy, likely results, or reliability
												of the use of the materials in Stockly or on our website, or otherwise
												relating to such materials or on any resources linked to Stockly and our
												website.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Links
											</h3>
											<p className="mb-8">
												Woyce LLC has not reviewed all of the sites linked to Stockly or on its
												corresponding website and is not responsible for the contents of any
												such linked site. The inclusion of any link does not imply endorsement,
												approval, or control by Woyce LLC of the site. Use of any such linked
												website is at your own risk and we strongly advise you make your own
												investigations with respect to the suitability of those sites.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Notice regarding Apple
											</h3>
											<p className="mb-8">
												To the extent that you are using or accessing Stockly on an iOS device,
												you acknowledge and agree to the terms of this clause. You acknowledge
												that these Terms of Service are between you and Woyce LLC only, not with
												Apple Inc. (Apple), and Apple is not responsible for Stockly and any
												materials available in Stockly.
											</p>
											<p className="mb-8">
												Apple has no obligation to furnish you with any maintenance and support
												services with respect to Stockly.
											</p>
											<p className="mb-8">
												If Stockly fails to conform to any applicable warranty, you may notify
												Apple and Apple will refund the purchase price of the mobile application
												to you. To the maximum extent permitted by applicable law, Apple will
												have no other warranty obligation whatsoever with respect to Stockly and
												any other claims, losses, liabilities, damages, costs, or expenses
												attributable to any failure to conform to any warranty will be our
												responsibility.
											</p>
											<p className="mb-8">
												Apple is not responsible for addressing any claims by you or any third
												party relating to Stockly or your use of Stockly, including but not
												limited to (1) product liability claims; (2) any claim that our mobile
												application fails to conform to any applicable legal or regulatory
												requirement; and (3) claims arising under consumer protection or similar
												legislation.
											</p>
											<p className="mb-8">
												Apple is not responsible for the investigation, defence, settlement, and
												discharge of any third-party claim that our mobile application infringes
												that third party’s intellectual property rights.
											</p>
											<p className="mb-8">
												You agree to comply with any applicable third-party terms when using
												Stockly, including any Usage Rules set forth in the Apple App Store
												Agreement of Service.
											</p>
											<p className="mb-8">
												Apple and Apple’s subsidiaries are third-party beneficiaries of these
												Terms of Service, and upon your acceptance of these Terms of Service,
												Apple will have the right (and will be deemed to have accepted the
												right) to enforce these Terms of Service against you as a third-party
												beneficiary of these Terms of Service.
											</p>
											<p className="mb-8">
												You hereby represent and warrant that (1) you are not located in a
												country that is subject to a U.S. Government embargo, or that has been
												designated by the U.S. Government as a "terrorist supporting" country;
												and (2) you are not listed on any U.S. Government list of prohibited or
												restricted parties.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Right to Terminate
											</h3>
											<p className="mb-8">
												We may suspend or terminate your Stockly account and right to use
												Stockly and these Terms of Service immediately upon written notice to
												you for any breach of these Terms of Service.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Severance
											</h3>
											<p className="mb-8">
												Any term of these Terms of Service which is wholly or partially void or
												unenforceable is severed to the extent that it is void or unenforceable.
												The validity of the remainder of these Terms of Service is not affected.
											</p>
											<h3 className="md:text-20 text-16 text-black font-semibold mb-16 mt-16">
												Governing Law
											</h3>
											<p className="mb-8">
												These Terms of Service are governed by and construed in accordance with
												the laws of United States. You irrevocably submit to the exclusive
												jurisdiction of the courts in that State or location.
											</p>
										</div>
									</div>
								</Element>
							</section>
						</div>
					</div>
				</div>
			}
		/>
	);
}

export default SimpleFullWidthSample;
