import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import teamQuery from 'app/main/query/team';
import userService from 'app/services/userService';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import BotQuery from 'app/main/query/bot';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_OTHER_ENDPOINT
});

export const getTeams = createAsyncThunk('myteams/getTeams', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const createTeams = createAsyncThunk('myteams/createTeams', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	const formT = new FormData();
	formT.append('operations', JSON.stringify(data.payloadvalue));
	if (data.filedata) {
		formT.append('map', '{"nFile":["variables.image"]}');
		formT.append('nFile', data.filedata);
	} else {
		formT.append('map', '{}');
	}
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, formT);
		const result = await response.data;
		dispatch(closeNewTeamsDialog());
		const userData = userService.getUserData();
		const number = parseInt(userData.id, 10);
		const payload = {
			query: BotQuery.getTeamDetailsByTeamTypeId,
			variables: { userId: number, createdByMe: null }
		};
		setTimeout(() => {
			dispatch(getTeams(payload));
		}, 1000);

		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getTeamById = createAsyncThunk('myteams/getTeamById', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getTeamMembersById = createAsyncThunk(
	'myteams/getTeamMembersById',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const removeTeams = createAsyncThunk('myteams/removeTeams', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const removeTeamMemeber = createAsyncThunk('myteams/removeTeamMemeber', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		const payloadData = {
			query: teamQuery.getTeamMembers,
			variables: { teamId: data?.variables?.teamId }
		};
		dispatch(getTeamMembersById(payloadData));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

// export const getTeamMembers = createAsyncThunk('myteams/getTeamMembers', async (data, { getState, dispatch }) => {
// 	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
// 	try {
// 		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
// 		const result = await response.data;
// 		return result;
// 	} catch (error) {
// 		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
// 		return errMsg;
// 	}
// });

export const setMemberSearchName = createAsyncThunk(
	'myteams/setMemberSearchName',
	async (searchText, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...searchText });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const updateInviteRequestStatus = createAsyncThunk(
	'myteams/updateInviteRequestStatus',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getBotsByTeam = createAsyncThunk('myteams/getBotsByTeam', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const uploadBannerImage = createAsyncThunk('myteams/uploadBannerImage', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `${userService.getAccessToken() || null}`;
	// const axiosConfig = {
	// 	headers: {
	// 		'Content-Type': 'multipart/form-data'
	// 	}
	// };
	try {
		const response = await instance.post('/api/uploadTeamBanner', data);
		const result = await response.data;
		// const getFormObject = Object.fromEntries(data);
		dispatch(getTeamById(data.teamId));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const removeBanner = createAsyncThunk('myteams/removeBanner', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(`/api/removeTeamBanner`, { ...data });
		const result = await response.data;
		dispatch(getTeamById(data.teamId));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const updateTeams = createAsyncThunk('myteams/updateTeams', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	const formT = new FormData();
	formT.append('operations', JSON.stringify(data.payloadvalue));
	if (data.filedata) {
		formT.append('map', '{"nFile":["variables.image"]}');
		formT.append('nFile', data.filedata);
	} else {
		formT.append('map', '{}');
	}
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, formT);
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const inviteTeamMember = createAsyncThunk('myteams/inviteTeamMember', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		const payloadData = {
			query: teamQuery.getTeamMembers,
			variables: { teamId: data?.variables?.teamId }
		};
		dispatch(getTeamMembersById(payloadData));
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

const teamsSlice = createSlice({
	name: 'myteams',
	initialState: {
		teamsDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	},
	reducers: {
		openNewTeamsDialog: (state, action) => {
			state.teamsDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: null
			};
		},
		closeNewTeamsDialog: (state, action) => {
			state.teamsDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: null
			};
		},
		resetTeam: (state, action) => {
			state.createTeamData = {};
			state.removeTeamData = {};
			state.removeTeamMemberData = {};
			state.updateTeamData = {};
			state.inviteTeamMemberData = {};
			state.updateInviteRequestStatusData = {};
		}
	},
	extraReducers: {
		[getTeams.fulfilled]: (state, action) => ({
			...state,
			teamData: action.payload
		}),
		[createTeams.fulfilled]: (state, action) => ({
			...state,
			createTeamData: action.payload
		}),
		[getTeamById.fulfilled]: (state, action) => ({
			...state,
			getTeamByIdData: action.payload
		}),
		[getTeamMembersById.fulfilled]: (state, action) => ({
			...state,
			getTeamMembersByIdData: action.payload
		}),
		[removeTeams.fulfilled]: (state, action) => ({
			...state,
			removeTeamData: action.payload
		}),
		[removeTeamMemeber.fulfilled]: (state, action) => ({
			...state,
			removeTeamMemberData: action.payload
		}),
		[setMemberSearchName.fulfilled]: (state, action) => ({
			...state,
			searchMemberData: action.payload
		}),
		[getBotsByTeam.fulfilled]: (state, action) => ({
			...state,
			getBotsByTeamIdData: action.payload
		}),
		[uploadBannerImage.fulfilled]: (state, action) => ({
			...state,
			uploadBannerImageData: action.payload
		}),
		[updateTeams.fulfilled]: (state, action) => ({
			...state,
			updateTeamData: action.payload
		}),
		[inviteTeamMember.fulfilled]: (state, action) => ({
			...state,
			inviteTeamMemberData: action.payload
		}),
		[updateInviteRequestStatus.fulfilled]: (state, action) => ({
			...state,
			updateInviteRequestStatusData: action.payload
		})
	}
});

export const { openNewTeamsDialog, closeNewTeamsDialog, resetTeam } = teamsSlice.actions;

export default teamsSlice.reducer;
