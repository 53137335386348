import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import history from '@history';
import jwtDecode from 'jwt-decode';
import Query from 'app/main/query/user';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

class UserService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err?.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		// eslint-disable-next-line camelcase
		const accesToken = this.getAccessToken();
		const smlAccessToken = this.getSmlAccessToken();
		const smlConnect = this.getSmlConnect();
		const matrixToken = this.getMatrixToken();
		// eslint-disable-next-line camelcase
		if (!accesToken) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(accesToken)) {
			this.setSession(accesToken, smlAccessToken, smlConnect, matrixToken);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'Token is expired');
		}
	};

	// createUser = data => {
	// 	return new Promise((resolve, reject) => {
	// 		axios.post('/api/auth/register', data).then(response => {
	// 			if (response.data.user) {
	// 				this.setSession(response.data.access_token);
	// 				resolve(response.data.user);
	// 			} else {
	// 				reject(response.data.error);
	// 			}
	// 		});
	// 	});
	// };

	// signInWithEmailAndPassword = (email, password) => {
	// 	return new Promise((resolve, reject) => {
	// 		axios
	// 			.get('/api/auth', {
	// 				data: {
	// 					email,
	// 					password
	// 				}
	// 			})
	// 			.then(response => {
	// 				if (response.data.user) {
	// 					this.setSession(response.data.access_token);
	// 					resolve(response.data.user);
	// 				} else {
	// 					reject(response.data.error);
	// 				}
	// 			});
	// 	});
	// };

	signInWithToken = () => {
		const accessToken = window.localStorage.getItem('jwt_access_token');
		const payload = {
			query: Query.verifyToken,
			variables: { token: accessToken }
		};

		return new Promise((resolve, reject) => {
			axios
				.post(process.env.REACT_APP_API_ENDPOINT, { ...payload })
				.then(response => {
					const result = response.data;
					if (result && result.data && result.data.verifyToken && result.data.verifyToken) {
						this.setSession(result.data.verifyToken.data.token);
						const tokenData = {
							...result.data.verifyToken.data
						};
						resolve(tokenData);
					} else {
						this.logout();
						history.push('/login');
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					history.push('/login');
					reject(new Error('Failed to login with token.'));
				});
		});
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	// eslint-disable-next-line camelcase
	setSession = (access_token, smallCaseToken, smallCaseConnect, matrixToken) => {
		if (smallCaseToken) {
			localStorage.setItem('jwt_sml_access_token', smallCaseToken);
			localStorage.setItem('smlConnect', smallCaseConnect);
			localStorage.setItem('matrixToken', matrixToken);
		}
		// eslint-disable-next-line camelcase
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token);
			// eslint-disable-next-line camelcase
			axios.defaults.headers.common.Authorization = `${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			localStorage.removeItem('jwt_sml_access_token');
			localStorage.removeItem('userData');
			localStorage.removeItem('smlConnect');
			localStorage.removeItem('matrixToken');
			localStorage.removeItem('botType');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
	};

	// eslint-disable-next-line camelcase
	isAuthTokenValid = access_token => {
		// eslint-disable-next-line camelcase
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};

	getSmlAccessToken = () => {
		return window.localStorage.getItem('jwt_sml_access_token');
	};

	getMatrixToken = () => {
		return window.localStorage.getItem('matrixToken');
	};

	getSmlConnect = () => {
		return window.localStorage.getItem('smlConnect');
	};

	getUserData = () => {
		return JSON.parse(localStorage.getItem('userData'));
	};

	isAuth = () => {
		if (this.getAccessToken() && this.getUserData()) {
			return true;
		}
		return false;
	};
}

const instance = new UserService();

export default instance;
