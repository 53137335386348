const getStockDashboard = `
	query {
		getStockDashboard {
			status
			message
			data {
				response {
					type
					position
					list {
						id
						user_id
						i_value
						i_prev_close_value
						num_posts
						num_followers
						s_score
						t_prev_close_price
						t_price
						p_change
						prev_close_change
						update_date
					}
					data {
						current_balance
						previous_balance
						purchase_balance
						card_style {
							bg {
								color
							}
							title {
								color
							}
							balance {
								color
							}
							today_text {
								color
							}
							negative_change {
								color
							}
							positive_change {
								color
							}
						}
					}
				}
			}
		}
	}
`;

const searchStockByName = `
	query ($companyName: String!) {
		searchStockByName(companyName: $companyName) {
			status
			message
			data {
				id
				s2_exchange {
					id
					name
					s2_countries {
						name
						code
					}
				}
				company_name
				ticker_image
				ticker_id
				ticker_symbol
				s2_pc_stock_stats {
					close_price
					today_low
					today_high
					volume
					week_low
					week_high
					current_value
				}
				card_style {
					bg {
						color
					}
					positive_change {
						color
					}
					negative_change {
						color
					}
					t_symbol {
						color
					}
					t_name {
						color
					}
					c_price {
						color
					}
				}
			}
		}
	}
`;
const getSearchByType = `
	query {
		getSearchByType(searchText: "rel", userType: "stock", pageSize: 1, pageNumber: 0) {
			status
			message
			data {
				# stock
				id
				ticker_id
				ticker_symbol
				ticker_image
				company_name
				s2_exchange {
					name
				}
				card_style {
					bg {
						color
					}
					f_title {
						color
					}
					f_subtitle {
						color
					}
					p_change_minus {
						color
					}
					p_change_plus {
						color
					}
				}
			}
		}
	}
`;

const getStocksDetails = `
	query getStocksDetails($tickerId: String!) {
		getStocksDetails(tickerId: $tickerId) {
			message
			status
			data {
				response {
					ceo
					founded
					about
					employees
					headquarters
					company_name
					current_price
					prev_close
					ticker_img
					ticker_symbol
					after_hour_price
					prev_close_index
					other_index
					current_index
					tags
					stock_news {
						id
						img
						date
						title
						source
					}
				}
			}
		}
	}
`;

const getStockWatchlist = `
	query getStockWatchlist($pageNumber: Int!, $pageSize: Int!, $search: String!, $sortBy: String,$sortOrder: OrderDirection) {
		getStockWatchlist(pageNumber: $pageNumber, pageSize: $pageSize, search: $search, sortBy: $sortBy,sortOrder: $sortOrder) {
			status
			totalCount
			data {
				response {
					ticker_id
					exchange_symbol
					current_price
					id
					prev_close
					ticker_img
					ticker_name
					ticker_symbol
					card_style {
						bg {
							color
						}
						negative_change {
							color
						}
						positive_change {
							color
						}
						t_name {
							color
						}
						t_symbol {
							color
						}
					}
				}
			}
		}
	}
`;
const addStockWatchlist = `
	mutation addStockWatchlist($tickerIds: [Int!]) {
		addStockWatchlist(tickerIds: $tickerIds) {
			addCount
			message
			status
		}
	}
`;

const removeWatchlistStock = `
	mutation removeWatchlistStock($clearAll: Boolean,$tickerIds: [Int!]) {
		removeWatchlistStock(clearAll: $clearAll,tickerIds: $tickerIds) {
			message
			status
		}
	}
`;

const getIndustryBasedStockList = `
	query ($catId: Int!, $pageSize: Int!, $pageNumber: Int!) {
		getIndustryBasedStockList(catId: $catId, pageSize: $pageSize, pageNumber: $pageNumber) {
			data {
				response {
					id
					ticker_id
					ticker_img
					ticker_name
					ticker_price
					ticker_prev_close_price
					card_style {
						bg {
							color
						}
						t_name {
							color
						}
						t_price {
							color
						}
						t_symbol {
							color
						}
						t_change_plus {
							color
						}
						t_change_minus {
							color
						}
					}
				}
			}
		}
	}
`;

const getStockDetails = `query getStocksDetails($tickerId:String!) {
    getStocksDetails(tickerId: $tickerId) {
      message
      status
      data {
          response {
            ceo
            founded
            about
            employees
            headquarters
            company_name
            current_price
            prev_close
            ticker_img
            ticker_symbol
            after_hour_price
            prev_close_index
            other_index
            current_index
            tags
            candle_stick {
              all_time {
                date
                stick
              }
              five_year {
                date
                stick
              }
              one_day {
                date
                stick
              }
              one_month {
                date
                stick
              }
              one_week {
                date
                stick
              }
              three_month {
                date
                stick
              }
              one_year {
                date
                stick
              }
            }
            line_chart {
              all_time {
                date
                y
              }
              five_year {
                date
                y
              }
              one_day {
                date
                y
              }
              one_month {
                date
                y
              }
              one_week {
                date
                y
              }
              three_month {
                date
                y
              }
              one_year {
                date
                y
              }
            }
            index_line_chart {
              all_time {
                date
                y
              }
              five_year {
                date
                y
              }
              one_day {
                date
                y
              }
              one_month {
                date
                y
              }
              one_week {
                date
                y
              }
              three_month {
                date
                y
              }
              one_year {
                date
                y
              }
            }
            social{
              desc
              comments
              date
              full_name
              id
              img
              is_down_vote
              is_down_vote_given
              is_gain
              percentage_change
              user_name
              is_up_vote_given
              is_up_vote
              is_sold
            }
                 stock_news {
                    id
                    img
                    date
                    title
                    source
                  }
          }
        }
      }
  }
  
  `;
const getETFStocksDetails = `query  getETFStocksDetails($tickerId: String!){
    getETFStocksDetails(tickerId:$tickerId){
     message
  status
  data{
        stock_data{
          analyst_ratings{
            chart_data{
              name
              value
            }
            info
          }
          earnings{
            actual_eps
            actual_ernings{
              name
              value
            }
            expected_eps
            expected_ernings{
              name
              value
            }
            info
          }
          important_dates{
            id
            date
            title
          }
          statistics{
            avg_vol
            close
            div_yield
            info
            low
            market_cap
            year_low
            year_high
            open
            pe_ration
            volumn
          }
        }
      }
    
  }
    }
  `;
const stockData = {
	searchStockByName,
	getStockDashboard,
	getSearchByType,
	getStocksDetails,
	getStockWatchlist,
	addStockWatchlist,
	removeWatchlistStock,
	getIndustryBasedStockList,
	getStockDetails,
	getETFStocksDetails
};

export default stockData;
