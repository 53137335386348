import { lazy } from 'react';

const ChangePasswordConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/change-password/:userId',
			component: lazy(() => import('./ChangePassword'))
		}
	]
};

export default ChangePasswordConfig;
