const locale = {
	DASHBOARD: 'Gösterge Paneli',
	APPLICATIONS: 'Uygulamalar',
	MYBOTS: 'Botlarım',
	BOTSANALYTICS: 'Bot Analizi',
	TEAMS: 'Takımlar',
	AGREEMENTS: 'Anlaşmalar',
	USERS: 'Geliştirici Kullanıcılar',
	BOTS: 'Botlar',
	SETTINGS: 'Ayarlar',
	MARKETPLACE: 'Pazar yeri',
	BOTCATEGORIES: 'Bot Kategorileri',
	BOTSUMMARY: 'Bot Özeti',
	BUGREPORT: 'Hata raporu',
	CONTACTUS: 'Bize Ulaşın',
	FEEDBACK: 'Geri bildirim',
	NOTIFICATIONTYPE: 'Bildirim türü',
	APPUSERS: 'Uygulama kullanıcıları'
};

export default locale;
