import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import userService from 'app/services/userService';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_OTHER_ENDPOINT
});

export const getNotification = createAsyncThunk(
	'application-dashboard/getNotification',
	async (data, { getState, dispatch }) => {
		// instance.defaults.headers.common.Authorization = `${userService.getAccessToken() || null}`;
		try {
			const response = await axios.get('/api/getInfoNotification', { ...data });
			const result = await response.data.data.getAllNewsDetails;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getStockDashboard = createAsyncThunk(
	'application-dashboard/getStockDashboard',
	async (userId, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...userId });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getDashboardCount = createAsyncThunk(
	'application-dashboard/getDashboardCount',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const userFollowUnfollow = createAsyncThunk(
	'application-dashboard/userFollowUnfollow',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const addStockWatchlist = createAsyncThunk(
	'application-dashboard/addStockWatchlist',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const removeWatchlistStock = createAsyncThunk(
	'application-dashboard/removeWatchlistStock',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const addPostVote = createAsyncThunk('application-dashboard/addPostVote', async (data, { dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const addUserBookmarkPost = createAsyncThunk(
	'applicationUserProfile/addUserBookmarkPost',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getInfluencerList = createAsyncThunk(
	'application-dashboard/getInfluencerList',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getPerformanceList = createAsyncThunk(
	'application-dashboard/getPerformanceList',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getTopMoversList = createAsyncThunk(
	'application-dashboard/getTopMoversList',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

const applicationDashboardSlice = createSlice({
	name: 'application-dashboard',
	initialState: {
		marketMoversDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		influencerDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		performersDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	},
	reducers: {
		openMarketMoversDialog: (state, action) => {
			state.marketMoversDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeMarketMoversDialog: (state, action) => {
			state.marketMoversDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openinfluencerDialog: (state, action) => {
			state.influencerDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeinfluencerDialog: (state, action) => {
			state.influencerDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openperformersDialog: (state, action) => {
			state.performersDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeperformersDialog: (state, action) => {
			state.performersDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},

		resetDashboard: (state, action) => {
			state.notificationData = {};
			state.newsData = {};
			state.addPostVoteData = {};
			state.addStockWatchlistData = {};
			state.getInfluencerListData = {};
			state.addUserBookmarkPostData = {};
			state.removeWatchlistStockData = {};
			state.getTopMoversListData = {};
			state.getPerformanceListData = {};
		}
	},
	extraReducers: {
		[getNotification.fulfilled]: (state, action) => ({
			...state,
			notificationData: action.payload
		}),
		[getStockDashboard.fulfilled]: (state, action) => ({
			...state,
			getStockDashboardData: action.payload
		}),
		[getDashboardCount.fulfilled]: (state, action) => ({
			...state,
			getDashboardCountData: action.payload
		}),
		[addPostVote.fulfilled]: (state, action) => ({
			...state,
			addPostVoteData: action.payload
		}),
		[addStockWatchlist.fulfilled]: (state, action) => ({
			...state,
			addStockWatchlistData: action.payload
		}),
		[removeWatchlistStock.fulfilled]: (state, action) => ({
			...state,
			removeWatchlistStockData: action.payload
		}),
		[getInfluencerList.fulfilled]: (state, action) => ({
			...state,
			getInfluencerListData: action.payload
		}),
		[getPerformanceList.fulfilled]: (state, action) => ({
			...state,
			getPerformanceListData: action.payload
		}),
		[getTopMoversList.fulfilled]: (state, action) => ({
			...state,
			getTopMoversListData: action.payload
		}),
		[addUserBookmarkPost.fulfilled]: (state, action) => ({
			...state,
			addUserBookmarkPostData: action.payload
		})
	}
});

export const {
	resetDashboard,
	openMarketMoversDialog,
	closeMarketMoversDialog,
	openinfluencerDialog,
	closeinfluencerDialog,
	openperformersDialog,
	closeperformersDialog
} = applicationDashboardSlice.actions;

export default applicationDashboardSlice.reducer;
