import FuseUtils from '@fuse/utils/FuseUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { AsyncPaginate } from 'react-select-async-paginate';
import Toolbar from '@material-ui/core/Toolbar';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { useCallback, useEffect, useState } from 'react';
import history from '@history';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import withReducer from 'app/store/withReducer';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from '@lodash';
import * as yup from 'yup';
import teamQuery from 'app/main/query/team';
import Query from 'app/main/query/user';
import reducer from './store';
import { createTeams, closeNewTeamsDialog, setMemberSearchName } from './store/teamsSlice';
import { teamDefaultImage, resizeImage } from '../../../services/commonService';
import { closeTeamDialog } from '../mybots/store/myBotsSlice';

const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

const defaultValues = {
	name: '',
	description: '',
	shortDescription: ''
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	name: yup.string().required('Name is required'),
	description: yup.string().required('Description is required'),
	shortDescription: yup
		.string()
		.required('Short Description is required')
		.max(24, 'Short description is too long - should be 24 characters maximum.')
});

function TeamsDialog(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const createTmData = useSelector(({ teamsReducer }) => teamsReducer.myteams.createTeamData);
	const teamDialog = useSelector(({ teamsReducer }) => teamsReducer.myteams.teamsDialog);

	const addTeamMsg = useSelector(({ teamsReducer }) =>
		teamsReducer?.myteams?.createTeamData?.data?.addTeam ? teamsReducer.myteams.createTeamData.data.addTeam : {}
	);

	const memberData = useSelector(({ teamsReducer }) =>
		teamsReducer?.myteams?.searchMemberData?.data?.searchPeople?.data?.length > 0
			? teamsReducer.myteams.searchMemberData.data.searchPeople.data
			: []
	);

	const [loading, setLoading] = useState(false);
	const [imageUpload, setImageUpload] = useState();
	const [imagePreview, setImagePreview] = useState(teamDefaultImage);
	const [memberName, setMemberName] = useState([]);
	const [defaultValue] = useState([{ firstname: 'select Member *' }]);
	const [memberRequire, setMemberRequire] = useState('');
	const [memberId, setMemberId] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const { control, watch, reset, handleSubmit, formState, getValues } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { isValid, dirtyFields, errors } = formState;

	/**
	 * Initialize Dialog with Data
	 */
	const initDialog = useCallback(() => {
		/**
		 * Dialog type: 'edit'
		 */
		if (teamDialog.type === 'edit' && teamDialog.data) {
			reset({ ...teamDialog.data });
		}

		/**
		 * Dialog type: 'new'
		 */
		if (teamDialog.type === 'new') {
			reset({
				...defaultValues,
				...teamDialog.data
			});
		}
	}, [teamDialog.data, teamDialog.type, reset]);

	/**
	 * On Dialog Open
	 */
	useEffect(() => {
		if (teamDialog.open) {
			initDialog();
		}
	}, [teamDialog.open, initDialog]);

	useEffect(() => {
		// const payloadValue = {
		// 	query: Query.searchPeople,
		// 	variables: { pageSize: 10, pageNumber: 1, name: '' }
		// };
		// dispatch(setMemberSearchName(payloadValue));
	}, [dispatch]);

	useEffect(() => {
		setLoading(false);
		if (createTmData && createTmData.status === 'fail') {
			enqueueSnackbar(createTmData.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
		} else if (createTmData && createTmData.status === 'success') {
			enqueueSnackbar(createTmData.message, {
				variant: 'success',
				autoHideDuration: 3000
			});
			reset(defaultValues);
		}
	}, [createTmData, enqueueSnackbar, dispatch, reset]);

	useEffect(() => {
		if (addTeamMsg && addTeamMsg.status === 200) {
			enqueueSnackbar('Team Added Successfully', {
				variant: 'success',
				autoHideDuration: 3000
			});
			setTimeout(() => {
				closeSnackbar();
			}, 3000);
		}
		if (addTeamMsg && addTeamMsg.status === 400) {
			enqueueSnackbar(addTeamMsg.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
			setTimeout(() => {
				closeSnackbar();
			}, 3000);
		}
	}, [addTeamMsg, closeSnackbar, enqueueSnackbar]);

	/**
	 * Close Dialog
	 */
	function closeComposeDialog() {
		return dispatch(closeNewTeamsDialog());
	}
	function handleFileChange(e) {
		const file = e.target.files[0];
		if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
			enqueueSnackbar('Only valid .png, .jpg format files', {
				variant: 'error',
				autoHideDuration: 2000
			});
			return;
		}
		const reader = new FileReader();

		reader.readAsBinaryString(file);

		reader.onload = async () => {
			setImagePreview(`data:${file.type};base64,${btoa(reader.result)}`);
			setImageUpload(e.target.files[0]);
			const resizableImage = await resizeImage(file);
		};
	}

	/**
	 * Form Submit
	 */
	function onSubmit(data) {
		if (teamDialog.type === 'new') {
			setTimeout(() => {
				setLoading(true);
			}, 1000);

			// const formData = new FormData();
			// formData.append('name', data.name);
			const payload = {
				query: teamQuery.addTeam,
				variables: {
					image: null,
					teamName: data.name,
					description: data.description,
					shortDescription: data.shortDescription,
					memberId
				}
			};
			const dataObj = {
				filedata: imageUpload,
				payloadvalue: payload
			};
			setTimeout(() => {
				dispatch(createTeams(dataObj));
			}, 500);

			handleRemove();
			setTimeout(() => {
				dispatch(closeTeamDialog());
				if (props.prop === 'dashboard') {
					history.push('/teams');
				}
				setImagePreview(teamDefaultImage);
			}, 1000);
		}
	}

	/**
	 * Remove Event
	 */
	function handleRemove() {
		closeComposeDialog();
		reset(defaultValues);
		setImagePreview(teamDefaultImage);
	}

	function handleSelectMember(event) {
		const {
			target: { value }
		} = event;
		const tempMemberId = [];
		const tempMemberName = [];
		value.map(dataValue => {
			if (dataValue.id) {
				tempMemberId.push(dataValue.id);
				tempMemberName.push(dataValue);
			}
			return true;
		});
		setMemberId(tempMemberId);
		setMemberName(tempMemberName);
		if (tempMemberId.length === 0) {
			setMemberRequire('Member require for Create Team');
		} else {
			setMemberRequire('');
		}
	}
	const handleClearSearch = () => {
		setSearchValue('');
	};
	function noRefCheck(event) {
		if (event) {
			const teamMemsId = event.map(memsData => memsData.id);
			setMemberId(teamMemsId);
		} else {
			setMemberId([]);
		}
	}

	async function fakeAction(page, search) {
		const tempOptionsData = [];
		return new Promise((resolve, reject) => {
			const payload = {
				query: Query.searchPeople,
				variables: {
					pageNumber: !search ? page + 1 : 1,
					pageSize: 10,
					name: search
				}
			};
			dispatch(setMemberSearchName(payload)).then(response => {
				if (
					response &&
					response?.payload &&
					response?.payload.data &&
					response?.payload?.data?.searchPeople &&
					response?.payload?.data?.searchPeople?.data &&
					response?.payload?.data?.searchPeople?.data.length > 0
				) {
					const filteredOptionsArray = response.payload.data.searchPeople.data;
					filteredOptionsArray.forEach(record => {
						tempOptionsData.push({
							label: `${record.firstname} ${record.lastname} (${record.emailaddress || ''})`,
							id: record.id,
							value: record.id
						});
					});
					resolve(tempOptionsData);
				} else {
					resolve();
				}
			});
		});
	}

	const loadOptions = async (search, prevOptions, { page }) => {
		let optionsData = [];
		if (!search) {
			optionsData = await fakeAction(page, search);
		} else {
			optionsData = await fakeAction(page, search);
		}

		if (optionsData && optionsData.length) {
			const filteredOptions = optionsData;

			const hasMore = !search
				? filteredOptions.length + prevOptions.length >= prevOptions.length
				: filteredOptions.length >= 10;
			const slicedOptions = optionsData.slice(optionsData, prevOptions.length + 10);
			return {
				options: slicedOptions,
				hasMore,
				additional: {
					page: !search ? page + 1 : 1
				}
			};
		}
		return {
			options: [],
			hasMore: false,
			additional: {
				page: !search ? page + 1 : 1
			}
		};
	};

	const colourStyles = {
		menuList: styles => ({
			...styles,
			background: '#fff'
		}),
		option: (styles, { isFocused, isSelected }) => ({
			...styles,
			background: isFocused ? '#fff' : isSelected ? '#fff' : undefined,
			color: `#000`,
			zIndex: 1
		}),
		menu: base => ({
			...base,
			zIndex: 100
		})
	};

	return (
		<Dialog
			classes={{
				paper: 'm-24 add-post-category'
			}}
			{...teamDialog.props}
			disableBackdropClick
			onClose={closeComposeDialog}
			fullWidth
			maxWidth="xs"
		>
			<AppBar position="static" elevation={0}>
				<Toolbar className="flex w-full">
					<Typography variant="subtitle1" color="inherit">
						{teamDialog.type === 'new' ? 'CREATE A NEW TEAM' : 'EDIT TEAM'}
					</Typography>
				</Toolbar>
			</AppBar>
			<form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col md:overflow-hidden">
				<DialogContent classes={{ root: 'p-24 add-post-category-content' }}>
					<motion.div className="w-192 h-192 mb-16 mx-auto">
						<Paper
							className="flex flex-col relative items-center justify-center w-full h-full rounded-8 shadow hover:shadow-lg border-2"
							role="button"
						>
							<img className="w-2/4 block rounded max-h-96" name="image" src={imagePreview} alt="Apps" />
							<label htmlFor="button-file">
								<input
									accept="image/*"
									className="hidden"
									id="button-file"
									type="file"
									onChange={handleFileChange}
								/>
								<Button
									size="small"
									className="rounded mt-10"
									variant="outlined"
									color="primary"
									component="span"
								>
									Upload Image
								</Button>
							</label>
						</Paper>
					</motion.div>
					<div className="flex">
						<Controller
							control={control}
							name="name"
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-24"
									label="Team Name"
									id="name"
									error={!!errors.name}
									helperText={errors?.name?.message}
									variant="outlined"
									required
									fullWidth
								/>
							)}
						/>
					</div>
					<div className="flex">
						<Controller
							control={control}
							name="description"
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-24"
									label="Description"
									id="description"
									multiline
									rows={5}
									error={!!errors.description}
									helperText={errors?.description?.message}
									variant="outlined"
									required
									fullWidth
								/>
							)}
						/>
					</div>
					<div className="flex">
						<Controller
							control={control}
							name="shortDescription"
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-24"
									label="Short Description"
									multiline
									maxrows={2}
									id="shortDescription"
									error={!!errors.shortDescription}
									helperText={errors?.shortDescription?.message}
									variant="outlined"
									required
									fullWidth
								/>
							)}
						/>
					</div>
					<div className="block w-full relative">
						<AsyncPaginate
							loadOptions={loadOptions}
							isMulti
							IndicatorSeparator={null}
							isClearable={false}
							closeMenuOnSelect={false}
							styles={colourStyles}
							onChange={event => {
								noRefCheck(event);
							}}
							className="relative invite_member bg-white"
							additional={{
								page: 0
							}}
							onInputChange={inputValue => setSearchValue(inputValue)}
							inputValue={searchValue}
						/>
						{searchValue && (
							<Icon
								className="text-20 align-middle absolute right-40 top-1/2 transform -translate-y-1/2 text-black ml-2 cursor-pointer"
								onClick={handleClearSearch}
							>
								close
							</Icon>
						)}
						{/* <Controller
							control={control}
							name="selectmember"
							render={({ field }) => (
								<>
									<Select
										{...field}
										id="selectmember"
										variant="outlined"
										className="mb-24 w-full"
										multiple
										label="Select member"
										required
										value={memberName.length === 0 ? defaultValue : memberName}
										onChange={handleSelectMember}
										renderValue={selected => {
											const selectedData = [];
											selected.map(name => selectedData.push(name.firstname));
											return selectedData.join(', ');
										}}
									>
										<MenuItem disabled checked>
											<em> Select Member </em>
										</MenuItem>
										{memberData.map((cData, index) => (
											<MenuItem key={index} value={cData}>
												<Checkbox checked={memberName.indexOf(cData) > -1} />
												<ListItemText
													primary={`${cData.firstname} ${cData.lastname} (${cData.emailaddress})`}
												/>
											</MenuItem>
										))}
									</Select>
									{memberRequire && (
										<div className="relative -top-20" style={{ color: '#f44336' }}>
											{memberRequire}
										</div>
									)}
								</>
							)}
						/> */}
					</div>
				</DialogContent>
				<DialogActions className="p-4 pb-16">
					<Button
						className="float-right bg-indigo-500 rounded"
						variant="contained"
						color="primary"
						type="button"
						onClick={handleRemove}
					>
						Cancel
					</Button>
					<div className={clsx(classes.wrapper, 'px-16 float-right')}>
						<Button
							className="bg-indigo-500 rounded"
							variant="contained"
							color="primary"
							type="submit"
							disabled={_.isEmpty(dirtyFields) || !isValid || loading || memberId.length === 0}
						>
							Create
						</Button>
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
					</div>
				</DialogActions>
			</form>
		</Dialog>
	);
}

export default withReducer('teamsReducer', reducer)(TeamsDialog);
