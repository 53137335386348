import FusePageSimple from '@fuse/core/FusePageSimple';
import Avatar from 'react-avatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@material-ui/core/AppBar';
import _ from '@lodash';
import Card from '@material-ui/core/Card';
import FuseLoading from '@fuse/core/FuseLoading';
import { green } from '@material-ui/core/colors';
import CardContent from '@material-ui/core/CardContent';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import withReducer from 'app/store/withReducer';
import publicIp from 'react-public-ip';
import Query from 'app/main/query/user';
import userService from 'app/services/userService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@material-ui/core/InputBase';
import reducer from './store';
import { profileDefaultImage, resizeImage } from '../../../services/commonService';
import {
	getProfile,
	updateProfile,
	updateProfileImage,
	verifyUserEmail,
	uploadBannerImage,
	resetProfile,
	checkUsername,
	getDevicesLocation
} from './store/profileSlice';

const useStyles = makeStyles(theme => ({
	avatar: {
		border: `4px solid ${theme.palette.background.default}`
	},
	wrapper: {
		position: 'relative',
		display: 'inline-block'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape(
	{
		first_name: yup
			.string()
			.required('Firstname is required')
			.max(15, 'First Name is too Long - should be 15 Characters Maximum.')
			.matches(/^[a-zA-Z]+$/, '* Special characters, Space and Numbers are not allowed in Firstname '),
		last_name: yup
			.string()
			.required('Lastname is required')
			.max(15, 'Last Name is too Long - should be 15 Characters Maximum.')
			.matches(/^[a-zA-Z]+$/, '* Special characters, Space and Numbers not allowed in Lastname '),
		middle_name: yup
			.string()
			.notRequired()
			.when('middle_name', {
				is: value => value?.length,
				then: rule =>
					rule
						.max(15, 'MiddleName is too Long - should be 15 Characters Maximum.')
						.matches(/^[a-zA-Z ]+$/, '* Special characters, Space and Numbers not allowed in MiddleName ')
			}),
		username: yup
			.string()
			.required('UserName is required')
			.max(35, 'UserName is too Long - should be 35 Characters Maximum.'),
		country_code: yup.string().required('Country code is required'),
		bio: yup.string().notRequired().max(150, 'Bio is too Long - should be 150 Characters Maximum.'),
		phone_number: yup
			.string()
			.required('Phone number is required')
			.min(10, 'Phone number is too short - should be 10 digit minimum.'),
		email: yup.string().email('You must enter a valid email').required('Email is required')
	},
	[['middle_name', 'middle_name']]
);

const defaultValues = {
	first_name: '',
	last_name: '',
	middle_name: '',
	username: '',
	country_code: '',
	phone_number: '',
	email: '',
	bio: ''
};

function MyProfilePage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const tokenRef = useRef(null);
	const verifyEmailProData = useSelector(({ profileReducer }) =>
		profileReducer.profile.verifyUserEmailData &&
		profileReducer.profile.verifyUserEmailData &&
		profileReducer.profile.verifyUserEmailData.data
			? profileReducer.profile.verifyUserEmailData.data.verifyUserEmail
			: {}
	);
	const updateProData = useSelector(({ profileReducer }) =>
		profileReducer.profile &&
		profileReducer.profile &&
		profileReducer.profile.updateProfileData &&
		profileReducer.profile.updateProfileData.data &&
		profileReducer.profile.updateProfileData.data.updateProfile
			? profileReducer.profile.updateProfileData.data.updateProfile
			: {}
	);
	const uploadBannerImageData = useSelector(({ profileReducer }) => profileReducer.profile.uploadBannerImageData);

	const profileData = useSelector(({ profileReducer }) =>
		profileReducer.profile &&
		profileReducer.profile &&
		profileReducer.profile.profileData &&
		profileReducer.profile.profileData.data &&
		profileReducer.profile.profileData.data.getProfile
			? profileReducer.profile.profileData.data.getProfile.data
			: {}
	);

	const getDevicesLocationvalue = useSelector(({ profileReducer }) =>
		profileReducer?.profile?.getDevicesLocationData?.data?.getDevicesLocationData?.data?.country_code
			? profileReducer.profile.getDevicesLocationData.data.getDevicesLocationData.data.country_code
			: ''
	);

	const checkUserName = useSelector(({ profileReducer }) =>
		profileReducer?.profile?.checkUsernameData?.data?.checkUsername
			? profileReducer.profile.checkUsernameData.data.checkUsername
			: {}
	);

	const { control, setValue, formState, handleSubmit, setError, clearErrors } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [phoneValue, setPhoneValue] = useState(getDevicesLocationvalue.toLowerCase());
	const [ipAddress, setIpAddress] = useState();
	const [isVerifyLoading, setIsVerifyLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [imageUpload, setImageUpload] = useState();
	const [imagePreview, setImagePreview] = useState();
	const [bannerPreview, setBannerPreview] = useState('assets/images/profile/profile-bg.png');
	const [setThumbImage] = useState();
	const [bannerLoading, setBannerLoading] = useState(false);
	const [openDialogInvite, setOpenDialogInvite] = useState(false);
	const [isReadMore, setIsReadMore] = useState(true);
	const [inviteLink] = useState(process.env.REACT_APP_STOCKY_SHARE_LINK);
	const [disabled, setDisabled] = useState(false);
	const [verifyTimeout, setVerifyTimeout] = useState(localStorage.getItem('verifyTimeout'));
	const [remainingTime, setRemainingTime] = useState(null);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	useEffect(() => {
		let unmounted = false;
		const payload = {
			query: Query.getProfile,
			variables: { userId: null }
		};
		dispatch(getProfile(payload)).then(response => {
			getProfileload(response);
			if (!unmounted) {
				setLoading(false);
			}
		});

		return () => {
			unmounted = true;
			dispatch(resetProfile());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (updateProData && (updateProData.status === 'fail' || updateProData.status === 400)) {
			setUpdateLoading(false);
			enqueueSnackbar(updateProData.message, {
				variant: 'error',
				autoHideDuration: 2000
			});
			setIsEdit(true);
		} else if (updateProData && updateProData.data) {
			setUpdateLoading(false);
			enqueueSnackbar('Profile Updated Successfully', {
				variant: 'success',
				autoHideDuration: 2000
			});
			setIsEdit(false);
		}
	}, [updateProData, enqueueSnackbar]);

	useEffect(() => {
		setIsVerifyLoading(false);
		if (uploadBannerImageData && uploadBannerImageData.status === 'success') {
			setBannerLoading(false);
		}

		if (verifyEmailProData && verifyEmailProData.status === 400) {
			enqueueSnackbar(verifyEmailProData.message, {
				variant: 'error',
				autoHideDuration: 2000
			});
			setTimeout(() => {
				closeSnackbar();
			}, 3000);
		} else if (verifyEmailProData && verifyEmailProData.status === 200) {
			enqueueSnackbar(verifyEmailProData.message, {
				variant: 'success',
				autoHideDuration: 2000
			});
			setTimeout(() => {
				closeSnackbar();
			}, 3000);
		}
	}, [verifyEmailProData, enqueueSnackbar, dispatch, uploadBannerImageData, closeSnackbar]);

	useEffect(() => {
		(async () => {
			setIpAddress(await publicIp.v4());
		})();

		return () => {};
	}, [ipAddress]);

	useEffect(() => {
		if (ipAddress) {
			const payload = {
				query: Query.getDevicesLocationData,
				variables: { ip_address: ipAddress }
			};
			dispatch(getDevicesLocation(payload));
		}

		return () => {
			dispatch(resetProfile());
		};
	}, [dispatch, ipAddress]);

	useEffect(() => {
		if (getDevicesLocationvalue) {
			setPhoneValue(getDevicesLocationvalue.toLowerCase());
		} else {
			setPhoneValue();
		}
	}, [getDevicesLocationvalue]);

	useEffect(() => {
		if (checkUserName && checkUserName.available === false) {
			setError('username', {
				type: 'manual',
				message: 'username is already exists.'
			});
		}
	}, [checkUserName, setError]);
	useEffect(() => {
		if (verifyTimeout && new Date().getTime() < verifyTimeout) {
			setDisabled(true);
			const interval = setInterval(() => {
				const remaining = Math.max(0, verifyTimeout - new Date().getTime());
				setRemainingTime(remaining);
			}, 1000);

			const timer = setTimeout(() => {
				setDisabled(false);
				setVerifyTimeout(null);
				setRemainingTime(null);
				localStorage.removeItem('verifyTimeout');
				clearInterval(interval);
			}, verifyTimeout - new Date().getTime());

			return () => {
				clearInterval(interval);
				clearTimeout(timer);
			};
		}
		localStorage.removeItem('verifyTimeout');
		setVerifyTimeout(null);
		setRemainingTime(null);
		return () => {};
	}, [verifyTimeout]);

	const formatTime = time => {
		const minutes = Math.floor(time / 60000);
		const seconds = Math.floor((time % 60000) / 1000);
		return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	};

	const getProfileload = useCallback(
		response => {
			if (
				response &&
				response.payload &&
				response.payload.data &&
				response.payload.data.getProfile &&
				response.payload.data.getProfile.data
			) {
				const result = response.payload.data.getProfile.data;
				setValue('first_name', result?.firstname?.trim(), { shouldDirty: true, shouldValidate: true });
				setValue('last_name', result?.lastname?.trim(), { shouldDirty: true, shouldValidate: true });
				setValue('middle_name', result.middlename ? result.middlename : '', {
					shouldDirty: true,
					shouldValidate: true,
					notRequired: true
				});
				setValue('username', result.username, { shouldDirty: true, shouldValidate: true });
				setValue('organization_name', result.organization_name ? result.organization_name : '', {
					shouldDirty: true,
					shouldValidate: true,
					notRequired: true
				});

				setValue('bio', result.bio ? result.bio : '', { shouldDirty: true, shouldValidate: true });

				if (result?.cellphone && (result.cellphone?.country_code || result.cellphone?.phone_number)) {
					setValue('country_code', result.cellphone.country_code);
					setPhoneValue(result.cellphone.country_code + result.cellphone.phone_number);
				}
				setValue(
					'phone_number',
					result?.cellphone && result?.cellphone?.phone_number ? result?.cellphone?.phone_number : '',
					{
						shouldDirty: true,
						shouldValidate: true
					}
				);
				if (result.profilephoto) {
					setImagePreview(result.profilephoto);
				}

				if (result.banner_image) {
					setBannerPreview(result.banner_image);
				} else {
					setBannerPreview('assets/images/profile/profile-bg.png');
				}
				setValue('email', result.emailaddress, { shouldDirty: true, shouldValidate: true });
			}
		},
		[setValue]
	);

	function handleUserNameKeyPress(event) {
		if (event.target.value) {
			const payloadData = {
				query: Query.checkUsername,
				variables: { username: event.target.value, userId: profileData?.id }
			};
			dispatch(checkUsername(payloadData));
		}
	}

	if (loading) {
		return <FuseLoading />;
	}

	const container = {
		show: {
			transition: {
				staggerChildren: 0.05
			}
		}
	};

	const item = {
		hidden: { opacity: 0, y: 40 },
		show: { opacity: 1, y: 0 }
	};

	function handlePhoneChange(value, country) {
		const withoutCountryCode = value.slice(country?.dialCode?.length);
		const countryCode = country?.dialCode;
		if (!countryCode) {
			setError('country_code', {
				type: 'manual',
				message: 'Country code is required'
			});
		} else {
			clearErrors('country_code');
		}
		setPhoneValue(countryCode || '');
		setValue('country_code', countryCode || '');
		setValue('phone_number', withoutCountryCode, { shouldDirty: true, shouldValidate: true });
	}

	function onSubmit(data) {
		setUpdateLoading(true);
		// const formData = new FormData();
		// formData.append('first_name', data.firstname);
		// formData.append('email', data.emailaddress);
		// formData.append('last_name', data.lastname);
		// formData.append('middle_name', data.middlename);
		// formData.append('username', data.username);
		// formData.append('country_code', data.country_code);
		// formData.append('phone_number', data.phone_number);
		// formData.append('profilephoto', imageUpload);
		// formData.append('thumb_profilephoto', thumbImage);
		const payload = {
			query: Query.updateProfile,
			variables: {
				file: null,
				emailaddress: data.email,
				firstname: data?.first_name,
				middlename: data?.middle_name,
				username: data?.username,
				lastname: data?.last_name,
				country_code: parseInt(data?.country_code, 10),
				phone_number: data?.phone_number,
				organization_name: data?.organization_name,
				bio: data?.bio
			}
		};
		const data1 = {
			payloadvalue: payload,
			filedata: imageUpload
		};
		dispatch(updateProfile(data1));
	}

	function removeImage() {
		const payload = {
			query: Query.updateProfile,
			variables: { isRemoved: true }
		};
		setTimeout(() => {
			dispatch(updateProfileImage(payload));
			setImagePreview();
		}, 1000);
	}

	function onVerifyEmail() {
		setDisabled(true);
		const timeout = new Date().getTime() + 5 * 60 * 1000;
		localStorage.setItem('verifyTimeout', timeout);
		setVerifyTimeout(timeout);
		setIsVerifyLoading(true);
		const userToken = userService.getAccessToken();
		const payload = {
			query: Query.verifyUserEmail,
			variables: { token: userToken }
		};
		dispatch(verifyUserEmail(payload));
	}

	function cancelEvent() {
		setIsEdit(false);
		const payload = {
			query: Query.getProfile,
			variables: { userId: null }
		};
		dispatch(getProfile(payload)).then(response => {
			getProfileload(response);
		});
	}

	function copyToClipboardInvite(e) {
		document.execCommand('copy');
		tokenRef.current.select();
		e.target.focus();
	}

	function handleFileChange(e) {
		const file = e.target.files[0];
		if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
			enqueueSnackbar('Only valid .png, .jpg format files', {
				variant: 'error',
				autoHideDuration: 2000
			});
			return;
		}
		const reader = new FileReader();

		reader.readAsBinaryString(file);

		reader.onload = async () => {
			setImagePreview(`data:${file.type};base64,${btoa(reader.result)}`);
			setImageUpload(e.target.files[0]);
			const resizableImage = await resizeImage(file);
			// setThumbImage(resizableImage);
		};
	}

	function handleFileBannerChange(e) {
		const file = e.target.files[0];
		if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
			enqueueSnackbar('Only valid .png, .jpg format files', {
				variant: 'error',
				autoHideDuration: 2000
			});
			return;
		}
		const reader = new FileReader();
		setBannerLoading(true);
		reader.readAsBinaryString(file);

		reader.onload = () => {
			setBannerPreview(`data:${file.type};base64,${btoa(reader.result)}`);
			const formData = new FormData();
			formData.append('image', e.target.files[0]);
			dispatch(uploadBannerImage(formData));
		};
	}

	return (
		<FusePageSimple
			className="flex"
			// header={
			// 	<>
			// 		{/* <img className={clsx(classes.topBg, 'w-full mx-auto')} src={bannerPreview} alt="bannerImage" /> */}

			// 		<div className="absolute right-8 top-8">
			// 			<label htmlFor="banner-file" className="grid">
			// 				<input
			// 					accept="image/*"
			// 					className="hidden"
			// 					id="banner-file"
			// 					type="file"
			// 					onChange={handleFileBannerChange}
			// 				/>
			// 				{/* <Button
			// 					className="rounded bg-indigo-500"
			// 					variant="contained"
			// 					color="primary"
			// 					aria-label="BannerEdit"
			// 					disabled={bannerLoading}
			// 					component="span"
			// 				>
			// 					<Icon
			// 						className="text-20 text-white-700 font-black align-middle cursor-pointer"
			// 						color="action"
			// 					>
			// 						edit_note
			// 					</Icon>
			// 					{bannerLoading && <CircularProgress size={20} className={classes.buttonProgress} />}
			// 				</Button> */}
			// 			</label>
			// 		</div>
			// 	</>
			// }

			content={
				<div className="p-16 sm:p-24">
					<motion.div variants={container} initial="hidden" animate="show">
						<div className="md:flex max-w-2xl">
							<div className="w-1/4 mb-32 rounded-16 shadow oflow-auto bg-white items-center mx-96 h-full p-24 text-center">
								<motion.div>
									<Avatar
										src={imagePreview}
										name={
											profileData?.firstname
												? `${profileData?.firstname} ${profileData?.lastname}`
												: profileData.username
										}
										size="100px"
										alt="Apps"
										className="text-48"
										round
										maxInitials={2}
									/>
									{isEdit && (
										<div
											className={`${
												profileData?.profilephoto ? 'flex justify-between' : ''
											} mt-16`}
										>
											<label htmlFor="button-file" className="grid">
												<input
													accept="image/*"
													className="hidden"
													id="button-file"
													type="file"
													onChange={handleFileChange}
												/>
												<Button
													size="small"
													className="rounded mt-4"
													variant="outlined"
													color="primary"
													component="span"
												>
													Update Image
												</Button>
											</label>
											{profileData?.profilephoto && (
												<Button
													size="small"
													className="rounded mt-4"
													variant="outlined"
													color="primary"
													component="span"
													type="submit"
													onClick={removeImage}
												>
													Remove Image
												</Button>
											)}
										</div>
									)}
								</motion.div>

								<div className="text-center justify-center p-8">
									<motion.div
										initial={{ opacity: 0, x: -40 }}
										animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
									>
										<Typography
											className="text-24 md:text-20 font-semibold tracking-tight"
											variant="h4"
											color="inherit"
										>
											{profileData?.firstname}&nbsp;&nbsp;{profileData?.lastname}
										</Typography>
									</motion.div>
									<Typography className="mb-4 text-14 mt-5 text-grey">
										{profileData?.emailaddress}&nbsp;
									</Typography>
									{profileData?.s2_user_type.name !== 'admin' && (
										<div className="iems-center mt-16 ">
											<Button
												className="rounded bg-indigo-500"
												variant="contained"
												color="primary"
												aria-label="Invite"
												onClick={e => setOpenDialogInvite(true)}
											>
												Invite a Friend
											</Button>
										</div>
									)}
								</div>
							</div>

							<div className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
								<form onSubmit={handleSubmit(onSubmit)}>
									<Card
										component={motion.div}
										variants={item}
										className="verw-full mb-32 rounded-16 shadow oflow-auto overflow-visible"
									>
										<AppBar position="static" className="rounded-t-16" elevation={0}>
											<Toolbar className="px-8 bg-black-500 rounded-16">
												<Typography
													variant="subtitle1"
													color="inherit"
													className="flex-1 px-12 font-medium"
												>
													Personal Information
												</Typography>
												{!isEdit && (
													<div className="flex items-center justify-end -mx-4 mt-24 md:mt-0">
														<Button
															className="mx-8 rounded bg-indigo-500"
															variant="contained"
															color="primary"
															aria-label="Edit"
															onClick={e => setIsEdit(true)}
														>
															Edit
														</Button>
													</div>
												)}

												{isEdit && (
													<>
														<div className="flex items-center justify-end -mx-4 mt-24 md:mt-0">
															<Button
																className="mx-8 rounded bg-indigo-500"
																variant="contained"
																color="primary"
																type="button"
																onClick={cancelEvent}
															>
																Cancel
															</Button>
														</div>
														<div
															className={clsx(
																classes.wrapper,
																'flex items-center justify-end -mx-4 mt-24 md:mt-0'
															)}
														>
															<Button
																type="submit"
																className="mx-8 rounded bg-indigo-500 text-white"
																variant="contained"
																color="primary"
																disabled={
																	_.isEmpty(dirtyFields) || !isValid || updateLoading
																}
															>
																<span className="text-white">Update</span>
															</Button>
															{updateLoading && (
																<CircularProgress
																	size={24}
																	className={classes.buttonProgress}
																/>
															)}
														</div>
													</>
												)}
											</Toolbar>
										</AppBar>
										{!isEdit && (
											<CardContent>
												<div className="mb-24">
													<Typography className="font-semibold mb-4 text-15">
														Firstname
													</Typography>
													<Typography>{profileData?.firstname}</Typography>
												</div>
												<div className="mb-24">
													<Typography className="font-semibold mb-4 text-15">
														Middlename
													</Typography>
													<Typography>{profileData?.middlename}</Typography>
												</div>
												<div className="mb-24">
													<Typography className="font-semibold mb-4 text-15">
														Lastname
													</Typography>
													<Typography>{profileData?.lastname}</Typography>
												</div>
												<div className="mb-24">
													<Typography className="font-semibold mb-4 text-15">
														Username
													</Typography>
													<Typography>{profileData?.username}</Typography>
												</div>

												<div className="mb-24">
													<Typography className="font-semibold mb-4 text-15">
														Email
													</Typography>
													<Typography component="div">
														{profileData?.emailaddress}&nbsp;
														{profileData?.email_details?.isEmailVerified === true ? (
															<div className="text-green-700 inline-block font-black">
																<Icon
																	className="text-20 text-green-700 font-black align-middle"
																	color="action"
																>
																	done
																</Icon>
																&nbsp;Verified
															</div>
														) : (
															<div className={clsx(classes.wrapper)}>
																<Button
																	className="text-red-700 text-white mx-8 rounded"
																	disabled={disabled}
																	onClick={onVerifyEmail}
																>
																	<Icon
																		className="text-16 text-red-700"
																		color="action"
																	>
																		close
																	</Icon>
																	&nbsp;&nbsp;Verify Now
																</Button>
																{remainingTime !== null && (
																	<Typography
																		style={{ display: 'table-cell' }}
																	>{`Please wait ${formatTime(
																		remainingTime
																	)} before trying again.`}</Typography>
																)}
																{isVerifyLoading && (
																	<CircularProgress
																		size={24}
																		className={classes.buttonProgress}
																	/>
																)}
															</div>
														)}
													</Typography>
												</div>
												{profileData?.s2_user_type.name === 'bot_developer' && (
													<div className="mb-24">
														<Typography className="font-semibold mb-4 text-15">
															Organization Name
														</Typography>
														<Typography>
															{profileData?.organization_name
																? profileData?.organization_name
																: '-'}
														</Typography>
													</div>
												)}
												{profileData?.s2_user_type.name === 'app_user' && (
													<div className="mb-24">
														<Typography className="font-semibold mb-4 text-15">
															Bio
														</Typography>
														<Typography className="profile-bio" title={profileData?.bio}>
															{profileData?.bio ? (
																<>
																	{isReadMore
																		? profileData?.bio.slice(0, 150)
																		: profileData?.bio}
																	{profileData?.bio.length > 150 && (
																		<span
																			onClick={toggleReadMore}
																			aria-hidden="true"
																			className="read-or-hide font-semibold text-indigo-500 ml-6 cursor-pointer"
																		>
																			{isReadMore
																				? '...Read more'
																				: '...Show less'}
																		</span>
																	)}
																</>
															) : (
																'-'
															)}
														</Typography>
													</div>
												)}

												<div className="mb-24">
													<Typography className="font-semibold mb-4 text-15">
														Country Code
													</Typography>
													<Typography>{profileData?.cellphone?.country_code}</Typography>
												</div>
												<div className="mb-24">
													<Typography className="font-semibold mb-4 text-15">
														Phone Number
													</Typography>
													<Typography>{profileData?.cellphone?.phone_number}</Typography>
												</div>
											</CardContent>
										)}
										{isEdit && (
											<CardContent>
												<Controller
													name="first_name"
													control={control}
													render={({ field }) => (
														<TextField
															{...field}
															className="mb-16"
															label="Firstname"
															type="text"
															error={!!errors.first_name}
															helperText={errors?.first_name?.message}
															variant="outlined"
															required
															fullWidth
														/>
													)}
												/>
												<Controller
													name="middle_name"
													control={control}
													render={({ field }) => (
														<TextField
															{...field}
															className="mb-16"
															label="Middle name"
															type="text"
															error={!!errors.middle_name}
															helperText={errors?.middle_name?.message}
															variant="outlined"
															fullWidth
														/>
													)}
												/>
												<Controller
													name="last_name"
													control={control}
													render={({ field }) => (
														<TextField
															{...field}
															className="mb-16"
															label="Lastname"
															type="text"
															error={!!errors.last_name}
															helperText={errors?.last_name?.message}
															variant="outlined"
															required
															fullWidth
														/>
													)}
												/>

												<Controller
													name="username"
													control={control}
													render={({ field }) => (
														<TextField
															{...field}
															className="mb-16"
															label="Username"
															type="text"
															error={!!errors.username}
															helperText={errors?.username?.message}
															onKeyUp={handleUserNameKeyPress}
															variant="outlined"
															required
															fullWidth
														/>
													)}
												/>
												<Controller
													name="email"
													control={control}
													render={({ field }) => (
														<TextField
															disabled
															{...field}
															className="mb-16"
															label="Email"
															type="email"
															error={!!errors.email}
															helperText={errors?.email?.message}
															variant="outlined"
															required
															fullWidth
														/>
													)}
												/>
												{profileData?.s2_user_type.name === 'bot_developer' && (
													<Controller
														name="organization_name"
														control={control}
														render={({ field }) => (
															<TextField
																{...field}
																className="mb-16"
																type="text"
																label="Organization Name"
																error={!!errors.organization_name}
																helperText={errors?.organization_name?.message}
																variant="outlined"
																fullWidth
															/>
														)}
													/>
												)}
												{profileData?.s2_user_type.name === 'app_user' && (
													<Controller
														name="bio"
														control={control}
														render={({ field }) => (
															<TextField
																{...field}
																className="mb-16"
																label="bio"
																type="text"
																variant="outlined"
																multiline
																maxrows={4}
																error={!!errors.bio}
																helperText={errors?.bio?.message}
																fullWidth
															/>
														)}
													/>
												)}
												<div className="mb-16 relative">
													<PhoneInput
														placeholder="* Phone Number"
														inputStyle={{
															width: '100%',
															height: '50px'
														}}
														name="phone_number"
														country={
															profileData?.cellphone?.country_code
																? profileData.cellphone.country_code
																: phoneValue
														}
														value={
															profileData?.cellphone?.phone_number
																? profileData?.cellphone?.country_code +
																  profileData?.cellphone?.phone_number
																: phoneValue
														}
														required
														error={!!errors.phone_number}
														helperText={errors?.phone_number?.message}
														enableSearch="true"
														onChange={handlePhoneChange}
													/>
													<p className="text-red ml-12 pt-4 text-xs">
														{errors.phone_number?.message}
													</p>
												</div>
											</CardContent>
										)}
									</Card>
								</form>
							</div>
						</div>
					</motion.div>
					<Dialog
						open={openDialogInvite}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setOpenDialogInvite(false);
							}
						}}
						aria-labelledby="logout-app"
						aria-describedby="logout-app-description"
					>
						<DialogTitle id="alert-dialog-title">
							<div className="flex justify-between items-center">
								Invite Friends
								<Icon
									className="text-16 cursor-pointer"
									title="Close"
									onClick={() => {
										setOpenDialogInvite(false);
									}}
								>
									close
								</Icon>
							</div>
						</DialogTitle>
						<DialogContent className="pt-0">
							<DialogContentText id="alert-dialog-description" style={{ minWidth: '350px' }}>
								<span className="text-black">Your Invite Link:</span>
								<div
									className="max-w-lg flex items-center justify-center border rounded-md px-8 py-4 mt-8 cursor-pointer botdetailtoken"
									onClick={copyToClipboardInvite}
									aria-hidden="true"
								>
									<TextField
										inputRef={tokenRef}
										value={
											profileData?.s2_user_type.name === 'bot_developer'
												? `I've been using Stockly developer which helps me to create my own bots, which I can share with my friends and I can publish on the Stockly. It's very simple,  you can also develop the bots and publish on the Stockly. You can register as a  developer by clicking on this link: ${inviteLink}`
												: `I've been using Stockly app which helps me stay informed by connecting with my investor friends, verified experts and friendly robots.You should give it a try too. Download Stockly from play store and app store: https://www.stockly.network/r/12`
										}
										fullWidth
										multiline
										rows={5}
									/>
									<Icon className="text-16" title="copy Link">
										content_copy
									</Icon>
								</div>
							</DialogContentText>
						</DialogContent>
					</Dialog>
				</div>
			}
		/>
	);
}

export default withReducer('profileReducer', reducer)(MyProfilePage);
